// src/services/assistantApiService.js
import adminApiConfig from '../config/adminApiConfig';

const BASE_PATH = '/ai/api/v1/assistant';

const assistantApi = {
    sendMessage: async (message, username) => {
        try {
            // Format date to match Sat Jan 11 2025 07:00:00 GMT+0700 format
            const date = new Date();
            const currentDate = date.toString();
            console.log("currentDate= ", currentDate);

            const response = await fetch(BASE_PATH, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...adminApiConfig.headers
                },
                credentials: 'include',
                body: JSON.stringify({
                    messages: [{ role: 'user', content: message }],
                    username,
                    current_date: currentDate  // Add the date parameter
                })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.detail || `Request failed with status ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    }
};

export default assistantApi;