import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { ChevronDown, ChevronUp, GripVertical, ChevronLeft, ChevronRight } from 'lucide-react';
import { getStatusStyles, getAvailableStatuses } from './taskDisplayUtils';

const KanbanBoard = ({
    project,
    onTaskUpdate,
    isReadOnly = false
}) => {
    const columns = getAvailableStatuses();
    const [collapsedColumns, setCollapsedColumns] = useState({});

    // Toggle column collapse
    const toggleColumnCollapse = (columnId) => {
        setCollapsedColumns(prev => ({
            ...prev,
            [columnId]: !prev[columnId]
        }));
    };

    // Get tasks by status
    const getTasksByStatus = () => {
        const tasksByStatus = {};
        columns.forEach(column => {
            tasksByStatus[column.id] = project?.tasks?.filter(task => task.status === column.id) || [];
        });
        return tasksByStatus;
    };

    // Handle drag end
    const handleDragEnd = (result) => {
        if (!result.destination || isReadOnly) return;

        const { source, destination, draggableId } = result;

        if (source.droppableId === destination.droppableId) return;

        const updatedTasks = [...project.tasks];
        const taskIndex = updatedTasks.findIndex(t => t.id === draggableId);

        if (taskIndex !== -1) {
            updatedTasks[taskIndex] = {
                ...updatedTasks[taskIndex],
                status: destination.droppableId
            };
            onTaskUpdate(updatedTasks);
        }
    };

    // Task card component
    const TaskCard = ({ task, index }) => {
        const [showSubtasks, setShowSubtasks] = React.useState(false);
        const statusStyle = getStatusStyles(task.status);
        const hasSubtasks = task.subtasks && task.subtasks.length > 0;

        return (
            <Draggable draggableId={task.id} index={index} isDragDisabled={isReadOnly}>
                {(provided) => (
                    <Card
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="mb-2"
                        style={{
                            borderLeft: `4px solid ${statusStyle.color}`,
                            ...provided.draggableProps.style
                        }}
                    >
                        <Card.Body className="p-2">
                            <div className="d-flex align-items-center">
                                <div {...provided.dragHandleProps} className="me-2">
                                    <GripVertical size={16} />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>{task.title}</div>
                                        {hasSubtasks && (
                                            <Button
                                                variant="link"
                                                className="p-0 ms-2"
                                                onClick={() => setShowSubtasks(!showSubtasks)}
                                            >
                                                {showSubtasks ? (
                                                    <ChevronUp size={16} />
                                                ) : (
                                                    <ChevronDown size={16} />
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                    {task.dueDate && (
                                        <small className="text-muted">
                                            Due: {new Date(task.dueDate).toLocaleDateString()}
                                        </small>
                                    )}
                                </div>
                            </div>

                            {/* Subtasks */}
                            {showSubtasks && hasSubtasks && (
                                <div className="mt-2 ps-4">
                                    {task.subtasks.map((subtask, idx) => (
                                        <div
                                            key={subtask.id}
                                            className="p-2 mb-1 border-start"
                                            style={{
                                                backgroundColor: 'var(--bs-gray-100)',
                                                borderRadius: '4px',
                                                fontSize: '0.9em'
                                            }}
                                        >
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span>{subtask.title}</span>
                                                <Badge
                                                    bg={subtask.completed ? 'success' : 'secondary'}
                                                    className="ms-2"
                                                >
                                                    {subtask.completed ? 'Done' : 'Pending'}
                                                </Badge>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                )}
            </Draggable>
        );
    };

    const tasksByStatus = getTasksByStatus();

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Row className="g-3">
                {columns.map(column => (
                    <Col
                        key={column.id}
                        xs={12}
                        md={collapsedColumns[column.id] ? 1 : 6}
                        lg={collapsedColumns[column.id] ? 1 : 3}
                        className="transition-all duration-300"
                    >
                        <Card className="h-100">
                            <Card.Header
                                className="d-flex justify-content-between align-items-center"
                                style={{
                                    borderBottom: `2px solid ${column.color}`
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <Button
                                        variant="link"
                                        className="p-0 me-2"
                                        onClick={() => toggleColumnCollapse(column.id)}
                                    >
                                        {collapsedColumns[column.id] ? (
                                            <ChevronRight size={16} />
                                        ) : (
                                            <ChevronLeft size={16} />
                                        )}
                                    </Button>
                                    {!collapsedColumns[column.id] && (
                                        <h6 className="mb-0">{column.title}</h6>
                                    )}
                                </div>
                                <Badge
                                    bg="secondary"
                                    style={{
                                        backgroundColor: column.color
                                    }}
                                >
                                    {tasksByStatus[column.id]?.length || 0}
                                </Badge>
                            </Card.Header>
                            {!collapsedColumns[column.id] && (
                                <Card.Body className="p-2">
                                    <Droppable droppableId={column.id}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className="kanban-column"
                                                style={{ minHeight: '50px' }}
                                            >
                                                {tasksByStatus[column.id]?.map((task, index) => (
                                                    <TaskCard
                                                        key={task.id}
                                                        task={task}
                                                        index={index}
                                                    />
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </Card.Body>
                            )}
                        </Card>
                    </Col>
                ))}
            </Row>
        </DragDropContext>
    );
};

export default KanbanBoard;