// InviteApiService.ts
import adminApiConfig from '../config/adminApiConfig';

const InviteApiService = {
    inviteUser: async (username: string, projectName: string) => {
        try {
            // Create URL-encoded form data
            const params = new URLSearchParams();
            params.append('username', username);
            params.append('project_name', projectName);

            const response = await adminApiConfig.post('/api/users/invite-user', params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            return response.data;
        } catch (error) {
            console.error('Invitation error:', error);

            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        throw new Error(`User ${username} not found in the system`);
                    case 405:
                        // Log additional information for debugging
                        console.error('Request config:', error.response.config);
                        throw new Error('API endpoint not properly configured. Please contact support.');
                    case 500:
                        throw new Error('Failed to send invitation email. Please try again.');
                    default:
                        throw new Error(error.response.data?.detail || 'Failed to send invitation');
                }
            }

            if (error.request) {
                throw new Error('Unable to reach the server. Please check your connection.');
            }

            throw new Error('Error preparing the invitation request.');
        }
    }
};

export default InviteApiService;