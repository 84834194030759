// components/CompletedTasksViewer.jsx
import React, { useState } from 'react';
import { Form, Container, Spinner } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';
import AppFooter from "../../AppFooter";
import NEW_JournalApiService from '../../../services/NEW_JournalApiService';
import TaskList from './TaskList';
import CompletedTasksChart from './CompletedTasksChart';
import { formatDate, transformDataForChart } from './dateUtils';

const CompletedTasksViewer = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDateForm, setShowDateForm] = useState(false);
    const { user } = useAuth();

    const handleLastQuarter = () => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();

        // Calculate which quarter we're currently in (0-3)
        const currentQuarter = Math.floor(currentMonth / 3);

        // Calculate the last quarter
        const lastQuarter = currentQuarter === 0 ? 3 : currentQuarter - 1;
        const lastQuarterYear = currentQuarter === 0 ? currentYear - 1 : currentYear;

        // Calculate start and end dates for the last quarter
        const quarterStartMonth = lastQuarter * 3;
        const quarterStartDate = new Date(lastQuarterYear, quarterStartMonth, 1);
        const quarterEndDate = new Date(lastQuarterYear, quarterStartMonth + 3, 0);

        const newStartDate = formatDate(quarterStartDate);
        const newEndDate = formatDate(quarterEndDate);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        findCompletedTasks(newStartDate, newEndDate);
    };

    const findCompletedTasks = async (searchStartDate, searchEndDate) => {
        setLoading(true);
        setCompletedTasks([]);
        setChartData([]);

        const startDateToUse = searchStartDate || startDate;
        const endDateToUse = searchEndDate || endDate;

        try {
            let currentDate = startDateToUse;
            const allTasks = [];

            while (currentDate <= endDateToUse) {
                try {
                    const entry = await NEW_JournalApiService.getEntry(user.username, currentDate);

                    if (entry?.content) {
                        const content = typeof entry.content === 'string' ?
                            JSON.parse(entry.content) : entry.content;

                        const dayTasks = [];
                        const processNode = (node, context = '') => {
                            if (!node) return;

                            if (Array.isArray(node)) {
                                node.forEach(item => processNode(item, context));
                                return;
                            }

                            if (node.type === 'taskItem' && node.attrs?.checked) {
                                let taskText = node.content?.[0]?.content?.[0]?.text || 'Unnamed Task';
                                dayTasks.push({
                                    text: taskText,
                                    context: context,
                                    date: currentDate
                                });
                            }

                            if (node.type === 'detailsSummary' && node.attrs?.text) {
                                context = node.attrs.text;
                            }

                            if (node.content) {
                                processNode(node.content, context);
                            }
                        };

                        processNode(content);
                        allTasks.push(...dayTasks);
                        setCompletedTasks(prev => [...prev, ...dayTasks]);
                        const updatedChartData = transformDataForChart(allTasks, startDateToUse, currentDate);
                        setChartData(updatedChartData);
                    }
                } catch (error) {
                    console.error(`Error fetching data for ${currentDate}:`, error);
                }

                const nextDate = new Date(currentDate);
                nextDate.setDate(nextDate.getDate() + 1);
                currentDate = nextDate.toISOString().split('T')[0];
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePreviousWorkWeek = () => {
        const today = new Date();
        const friday = new Date(today);
        friday.setDate(today.getDate() - (today.getDay() + 2) % 7);  // Last Friday
        const monday = new Date(friday);
        monday.setDate(friday.getDate() - 4);  // Monday of the same week

        const newStartDate = formatDate(monday);
        const newEndDate = formatDate(friday);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        findCompletedTasks(newStartDate, newEndDate);
    };

    const handleLastSevenDays = () => {
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);

        const newStartDate = formatDate(sevenDaysAgo);
        const newEndDate = formatDate(today);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        findCompletedTasks(newStartDate, newEndDate);
    };

    const handleLastMonthToDate = () => {
        const today = new Date();
        const lastMonth = new Date(today);
        lastMonth.setMonth(today.getMonth() - 1);
        lastMonth.setDate(1);

        const newStartDate = formatDate(lastMonth);
        const newEndDate = formatDate(today);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        findCompletedTasks(newStartDate, newEndDate);
    };

    const handleBarClick = (data) => {
        if (data && data.activePayload && data.activePayload[0]) {
            const clickedDate = data.activePayload[0].payload.date;
            setSelectedDate(selectedDate === clickedDate ? null : clickedDate);
        }
    };

    const filteredTasks = selectedDate
        ? completedTasks.filter(task => task.date === selectedDate)
        : completedTasks;

    return (
        <div className="position-fixed top-0 bottom-0 start-0 end-0">
            <div className="h-100 d-flex flex-column">
                <Container className="flex-grow-1 py-3">
                    <div className="card h-100">
                        <div className="card-body d-flex flex-column h-100">
                            <div>
                                <h5 className="card-title mb-3">Completed Tasks</h5>

                                {/* All Buttons Section */}
                                <div className="mb-3">
                                    <div className="d-flex gap-2 flex-wrap">
                                        {/* Quick Search Buttons */}
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={handlePreviousWorkWeek}
                                            disabled={loading}
                                        >
                                            Previous Work Week
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={handleLastSevenDays}
                                            disabled={loading}
                                        >
                                            Last 7 Days
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={handleLastMonthToDate}
                                            disabled={loading}
                                        >
                                            Last Month to Date
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={handleLastQuarter}
                                            disabled={loading}
                                        >
                                            Last Quarter
                                        </button>

                                        {/* Custom Date Range Button */}
                                        <button
                                            className={`btn ${showDateForm ? 'btn-primary' : 'btn-outline-secondary'}`}
                                            onClick={() => setShowDateForm(!showDateForm)}
                                            disabled={loading}
                                        >
                                            Select Date Range
                                        </button>
                                    </div>
                                </div>

                                {/* Date Selection Form */}
                                {showDateForm && (
                                    <Form className="mb-4">
                                        <div className="d-flex gap-3">
                                            <Form.Group className="flex-grow-1">
                                                <Form.Label>Start Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="flex-grow-1">
                                                <Form.Label>End Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </Form.Group>
                                            <div className="d-flex align-items-end">
                                                <button
                                                    className="btn btn-outline-primary border-0"
                                                    onClick={() => findCompletedTasks()}
                                                    disabled={!startDate || !endDate || loading}
                                                >
                                                    {loading ? (
                                                        <Spinner animation="border" size="sm" />
                                                    ) : (
                                                        'Search'
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </div>

                            {/* Chart Section */}
                            {chartData.length > 0 && (
                                <CompletedTasksChart
                                    data={chartData}
                                    onBarClick={handleBarClick}
                                />
                            )}

                            {/* Tasks List */}
                            <TaskList
                                tasks={filteredTasks}
                                selectedDate={selectedDate}
                                onClearSelection={() => setSelectedDate(null)}
                                loading={loading}
                            />
                        </div>
                    </div>
                </Container>

                <footer className="bg-light">
                    <Container fluid className="ps-0 pe-0">
                        <AppFooter
                            showSidebarToggle={false}
                            showCalendar={false}
                            showSearch={false}
                            showJournal={true}
                            showAIChat={true}
                            showCompletedTasks={false}
                            showUserDropdown={true}
                        />
                    </Container>
                </footer>
            </div>
        </div>
    );
};

export default CompletedTasksViewer;