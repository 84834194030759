// TaskAIExtension.js
import { Extension } from '@tiptap/core';

export const TaskAIExtension = Extension.create({
    name: 'taskAI',

    addCommands() {
        return {
            createTasks: (aiResponse) => ({ editor, chain }) => {
                try {
                    // Get content from AI response
                    const content = aiResponse.message.content;

                    // Split into tasks, removing bullet points
                    const tasks = content
                        .split('\n')
                        .map(line => line.trim())
                        .filter(line => line)
                        .map(line => line.replace(/^[-*•]\s*/, ''));

                    if (!tasks.length) return false;

                    // Create the proper Tiptap JSON structure for a task list
                    const taskListContent = {
                        type: 'taskList',
                        content: tasks.map(task => ({
                            type: 'taskItem',
                            attrs: { checked: false },
                            content: [{
                                type: 'paragraph',
                                content: [{
                                    type: 'text',
                                    text: task
                                }]
                            }]
                        }))
                    };

                    // Insert the complete task list structure
                    return chain()
                        .insertContent(taskListContent)
                        .run();
                } catch (error) {
                    console.error('Error creating tasks:', error);
                    return false;
                }
            }
        };
    }
});

// Helper function to handle task creation with AI
export const createTaskCreationCommand = (editor) => {
    return async (promptText) => {
        try {
            // Request tasks from AI
            const enhancedPrompt = `Create a list of specific, actionable tasks. Format as simple lines with hyphens: ${promptText}`;
            const aiResponse = await editor.commands.summarizeSelection(enhancedPrompt);

            if (aiResponse && aiResponse.message) {
                // Log the structure we're creating
                console.log('Creating task list with structure:', {
                    type: 'taskList',
                    content: aiResponse.message.content
                        .split('\n')
                        .map(line => line.trim())
                        .filter(line => line)
                        .map(line => line.replace(/^[-*•]\s*/, ''))
                        .map(task => ({
                            type: 'taskItem',
                            attrs: { checked: false },
                            content: [{
                                type: 'paragraph',
                                content: [{ type: 'text', text: task }]
                            }]
                        }))
                });

                return editor.commands.createTasks(aiResponse);
            }

            return false;
        } catch (error) {
            console.error('Error creating tasks:', error);
            throw error;
        }
    };
};