import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

export const DeleteProjectModal = ({
    show,
    onHide,
    onDelete
}) => (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Delete Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure you want to delete this project? This action cannot be undone and will delete all tasks associated with this project.
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
                Cancel
            </Button>
            <Button variant="danger" onClick={onDelete}>
                Delete Project
            </Button>
        </Modal.Footer>
    </Modal>
);

export const DeleteTaskModal = ({
    show,
    onHide,
    onDelete
}) => (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure you want to delete this task?
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
                Cancel
            </Button>
            <Button variant="danger" onClick={onDelete}>
                Delete
            </Button>
        </Modal.Footer>
    </Modal>
);

export const NewProjectModal = ({
    show,
    onHide,
    projectName,
    onProjectNameChange,
    onCreateProject
}) => (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Create New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group>
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                    type="text"
                    value={projectName}
                    onChange={(e) => onProjectNameChange(e.target.value)}
                    placeholder="Enter project name..."
                />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
                Cancel
            </Button>
            <Button variant="primary" onClick={onCreateProject}>
                Create Project
            </Button>
        </Modal.Footer>
    </Modal>
);