import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AlertCircle, CreditCard, XCircle } from 'lucide-react';
import { Alert, Button, Form, Modal, Card, Spinner } from 'react-bootstrap';
import LoginApiService from '../../../services/LoginApiService';
import subscriptionApiService from '../../../services/subscriptionApiService';

const PayPalSubscriptionButtonAccount = ({
    TaskPlexPlanId,
    PayPalPlanId,
    planName,
    email,
    disabled,
    agreementAccepted,
    onAcceptAgreement,
    agreementContent
}) => {
    const [showAgreementModal, setShowAgreementModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const pollingInterval = useRef(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPayPalReady, setIsPayPalReady] = useState(false);
    const [isPayPalScriptLoaded, setIsPayPalScriptLoaded] = useState(false);
    const [preventButtonRender, setPreventButtonRender] = useState(false);
    const [upgradeFromTrial, setUpgradeFromTrial] = useState(true);

    // Reference to track component mount state
    const isMounted = useRef(true);
    // Reference to store the PayPal script element
    const paypalScriptRef = useRef(null);

    const checkSubscriptionStatus = useCallback(async (subscriptionId) => {
        try {
            const status = await LoginApiService.getSubscriptionStatus(subscriptionId);
            console.log("CHECKSUBSCRIPTIONSTATUS= ", status);

            if (!isMounted.current) return;

            if (status.status === 'active') {
                // Set trial cancelled if still valid
                try {
                    const response = await subscriptionApiService.cancelTrialSubscription();
                    console.log("Trial subscription cancelled successfully:", response);
                } catch (error) {
                    console.error('Error cancelling trial subscription:', error);
                    const errorMessage = error?.response?.data?.message || error.message || 'Failed to cancel subscription';
                    setError(errorMessage);
                    throw error;
                }
                // Clear the interval using the ref
                if (pollingInterval.current) {
                    clearInterval(pollingInterval.current);
                    pollingInterval.current = null;
                }
                setSubscriptionStatus('active');
                setShowSuccessModal(true);
                setPreventButtonRender(true);
            } else if (status.status === 'failed') {
                if (pollingInterval.current) {
                    clearInterval(pollingInterval.current);
                    pollingInterval.current = null;
                }
                setSubscriptionStatus('failed');
                setError('Your subscription payment has failed. Please try again or use a different payment method.');
                setPreventButtonRender(true);
            }
        } catch (error) {
            if (!isMounted.current) return;
            console.error('Error checking subscription status:', error);
            setError(error.message || 'Failed to verify subscription status');
            setSubscriptionStatus('error');
            setPreventButtonRender(true);
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
            }
        }
    }, []);

    const startPolling = useCallback((subscriptionId) => {
        if (!isMounted.current) return;

        setSubscriptionStatus('pending');
        setError(null);
        setPreventButtonRender(false);

        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
        }

        checkSubscriptionStatus(subscriptionId);

        pollingInterval.current = setInterval(() => {
            checkSubscriptionStatus(subscriptionId);
        }, 3000);

        setTimeout(() => {
            if (!isMounted.current) return;
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
            }
            setSubscriptionStatus('timeout');
            setError('Subscription verification timed out. Please contact support if payment was processed.');
            setPreventButtonRender(true);
        }, 120000);
    }, [checkSubscriptionStatus]);

    const renderPayPalButtons = useCallback(() => {
        if (preventButtonRender) {
            console.log('PayPal button rendering prevented due to active/error status');
            return;
        }

        if (!window.paypal || !isMounted.current) return;
        const container = document.getElementById('paypal-button-container');
        if (!container) return;
        container.innerHTML = '';

        window.paypal.Buttons({
            createSubscription: async (data, actions) => {
                try {
                    const response = await LoginApiService.createPayPalSubscription({
                        user_email: email,
                        TaskPlexPlanId,
                        PayPalPlanId,
                        Upgrade_from_trial: upgradeFromTrial
                    });

                    return response.subscriptionID;
                } catch (error) {
                    return null;
                }
            },
            onApprove: (data) => {
                if (!isMounted.current) return;
                console.log('Subscription created:', data.subscriptionID);
                console.log('ALL DATA RETURNED: ', data);
                setError(null);
                startPolling(data.subscriptionID);
            },
            onError: (err) => {
                if (!isMounted.current) return;
                console.error('PayPal error:', err);
                setError('An error occurred during payment processing. Please try again.');
                setSubscriptionStatus('failed');
                setPreventButtonRender(true);
            }
        }).render('#paypal-button-container')
            .catch(err => {
                if (!isMounted.current) return;
                console.error('PayPal render error:', err);
                setError('Failed to initialize PayPal button. Please refresh the page.');
                setPreventButtonRender(true);
            });
    }, [TaskPlexPlanId, PayPalPlanId, email, startPolling, preventButtonRender, upgradeFromTrial]);

    // Initialize PayPal script
    useEffect(() => {
        const loadPayPalScript = () => {
            console.log("loadPayPalScript");
            const script = document.createElement('script');
            script.src = "https://www.paypal.com/sdk/js?client-id=AajXJ2S_5gPozArLTUPPdJmb7fSbb74ylzIgufaZv1GzR3CQhMHbQvlQFfTctERyQVwF1OfGjgiKg7hN&vault=true&intent=subscription";
            script.async = true;
            paypalScriptRef.current = script;

            const handleLoad = () => {
                if (!isMounted.current) return;
                setIsPayPalScriptLoaded(true);
                setIsPayPalReady(true);
                setIsLoading(false);
            };

            script.addEventListener('load', handleLoad);
            script.addEventListener('error', () => {
                if (!isMounted.current) return;
                setError('Failed to load PayPal script. Please refresh the page or try again later.');
                setIsLoading(false);
                setPreventButtonRender(true);
            });

            document.body.appendChild(script);
        };

        loadPayPalScript();

        const timeoutId = setTimeout(() => {
            if (!window.paypal && isMounted.current) {
                setError('PayPal is taking longer than expected to load. Please refresh the page or try again later.');
                setIsLoading(false);
                setPreventButtonRender(true);
            }
        }, 20000);

        return () => {
            isMounted.current = false;
            if (paypalScriptRef.current && paypalScriptRef.current.parentNode) {
                paypalScriptRef.current.parentNode.removeChild(paypalScriptRef.current);
            }
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
            }
            clearTimeout(timeoutId);
        };
    }, []);

    // Separate useEffect for rendering buttons once script is loaded
    useEffect(() => {
        if (isPayPalScriptLoaded && window.paypal && !preventButtonRender) {
            renderPayPalButtons();
        }
    }, [isPayPalScriptLoaded, renderPayPalButtons, preventButtonRender]);

    // Re-render PayPal buttons when subscription status changes to failed
    useEffect(() => {
        if (subscriptionStatus === 'failed' && isPayPalReady && isMounted.current && !preventButtonRender) {
            renderPayPalButtons();
        }
    }, [subscriptionStatus, isPayPalReady, renderPayPalButtons, preventButtonRender]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    if (isLoading) {
        return (
            <Card>
                <Card.Body className="d-flex flex-column align-items-center justify-content-center py-5">
                    <Spinner animation="border" role="status" variant="primary" className="mb-3">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <div className="text-center text-muted">
                        <p className="mb-0">Loading payment system...</p>
                        <small>This may take a few moments</small>
                    </div>
                </Card.Body>
            </Card>
        );
    }

    const renderSubscriptionStatus = () => {
        switch (subscriptionStatus) {
            case 'pending':
                return (
                    <Alert variant="info" className="d-flex align-items-center gap-2">
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        Processing your subscription...
                    </Alert>
                );
            case 'failed':
                return (
                    <Alert variant="danger" className="d-flex align-items-center gap-2">
                        <XCircle size={20} />
                        <div>
                            Subscription payment failed. Please try again or use a different payment method.
                            <div className="mt-2">
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() => {
                                        setSubscriptionStatus(null);
                                        setError(null);
                                    }}
                                >
                                    Try Again
                                </Button>
                            </div>
                        </div>
                    </Alert>
                );
            case 'timeout':
                return (
                    <Alert variant="warning" className="d-flex align-items-center gap-2">
                        <AlertCircle size={20} />
                        <div>
                            Subscription verification timed out. If you completed the payment, please contact our support team.
                            Your subscription may still be processing.
                        </div>
                    </Alert>
                );
            default:
                return null;
        }
    };

    return (
        <Card>
            <Card.Header className={disabled ? "bg-secondary text-white" : "border border-success mb-3"}>
                <div className="d-flex justify-content-between align-items-start">
                    <div>
                        <h3 className="mb-0 pt-3">TaskPlex <sup>{planName}</sup></h3>
                        <p>monthly plan</p>
                        <span>Plan ID: {TaskPlexPlanId}</span>
                        <p><strong>Your email:</strong> {email}</p>
                        <p><sup>*</sup><small><em>All major credit and debit cards accepted</em></small></p>
                    </div>
                    <CreditCard size={24} className="mt-3" />
                </div>
            </Card.Header>

            <Card.Body>
                {error && !subscriptionStatus && (
                    <Alert variant="danger" className="d-flex align-items-center gap-2">
                        <AlertCircle size={20} />
                        <div>{error}</div>
                        {!isPayPalReady && (
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="ms-auto"
                                onClick={() => {
                                    setPreventButtonRender(false);
                                    window.location.reload();
                                }}
                            >
                                Refresh Page
                            </Button>
                        )}
                    </Alert>
                )}

                {renderSubscriptionStatus()}
                {!agreementAccepted && (
                    <>
                        <Alert variant="warning" className="d-flex align-items-center gap-2">
                            <AlertCircle size={20} />
                            <div>
                                Please accept the terms and conditions to continue
                            </div>
                        </Alert>
                        <div className="text-center mb-3">
                            <Form.Check
                                type="checkbox"
                                id="agreement-checkbox"
                                label={
                                    <span>
                                        I accept the{' '}
                                        <Button
                                            variant="link"
                                            className="p-0"
                                            onClick={() => setShowAgreementModal(true)}
                                        >
                                            subscription terms and conditions
                                        </Button>
                                    </span>
                                }
                                checked={agreementAccepted}
                                onChange={() => !agreementAccepted && setShowAgreementModal(true)}
                            />
                        </div>
                    </>
                )}

                <div className="paypal-button-wrapper">
                    <div
                        id="paypal-button-container"
                        style={{
                            opacity: (disabled || subscriptionStatus === 'pending') ? 0.5 : 1,
                            pointerEvents: (disabled || subscriptionStatus === 'pending' || preventButtonRender) ? 'none' : 'auto',
                            minHeight: '150px'
                        }}
                    />
                    {preventButtonRender && error && (
                        <div className="text-center mt-3">
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setPreventButtonRender(false);
                                    setError(null);
                                    setSubscriptionStatus(null);
                                    renderPayPalButtons();
                                }}
                            >
                                Try Again
                            </Button>
                        </div>
                    )}
                </div>
            </Card.Body>

            <Modal
                show={showAgreementModal}
                onHide={() => setShowAgreementModal(false)}
                size="lg"
                centered
                className="agreement-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Terms and Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {agreementContent.content_url ? (
                        <div className="ratio ratio-1x1">
                            <iframe
                                src={agreementContent.content_url}
                                title="Terms and Conditions"
                                className="w-100 h-100"
                                style={{ border: 'none' }}
                            />
                        </div>
                    ) : (
                        <p>Failed to load agreement content. Please try again.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAgreementModal(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            onAcceptAgreement();
                            setShowAgreementModal(false);
                        }}
                        disabled={!agreementContent.content_url}
                    >
                        Accept
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showSuccessModal}
                onHide={() => {
                    setShowSuccessModal(false);
                    window.location.href = '/login';
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="d-flex align-items-center gap-2">
                            <i className="bi bi-check-circle text-success"></i>
                            Subscription Activated!
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Thank you for subscribing to TaskPlex {planName}! Your subscription has been successfully activated.</p>
                    {/* <p>You will be redirected to the login page to access your account.</p> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShowSuccessModal(false);
                            window.location.href = '/login';
                        }}
                    >
                        Continue to Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );
};

export default PayPalSubscriptionButtonAccount;