import React from 'react';
import { Badge } from 'react-bootstrap';

/**
 * Collection of utility functions for task display and formatting
 */

// Core status configuration object that drives all status-related functionality
export const TASK_STATUS_CONFIG = {
    'pending': {
        color: 'var(--bs-secondary)',
        border: 'var(--bs-secondary)',
        title: 'Pending',
        displayOrder: 0
    },
    'in-progress': {
        color: 'var(--bs-primary)',
        border: 'var(--bs-primary)',
        title: 'In Progress',
        displayOrder: 1
    },
    'review': {
        color: 'var(--bs-secondary)',
        border: 'var(--bs-secondary)',
        title: 'Review',
        displayOrder: 2
    },
    'completed': {
        color: 'var(--bs-success)',
        border: 'var(--bs-success)',
        title: 'Completed',
        displayOrder: 3
    },
    'deployed': {
        color: 'var(--bs-secondary)',
        border: 'var(--bs-secondary)',
        title: 'Deployed',
        displayOrder: 4
    },
    'overdue': {
        color: 'var(--bs-danger)',
        border: 'var(--bs-danger)',
        title: 'Overdue',
        displayOrder: 5,
        isSpecialStatus: true
    }
};

// Priority configuration that drives priority-related functionality
export const PRIORITY_CONFIG = {
    high: { variant: 'danger', label: 'High', order: 1 },
    medium: { variant: 'warning', label: 'Medium', order: 2 },
    low: { variant: 'info', label: 'Low', order: 3 }
};

// Backward compatibility functions
export const getStatusStyles = (status) => {
    const config = TASK_STATUS_CONFIG[status] || TASK_STATUS_CONFIG['pending'];
    return {
        color: config.color,
        border: config.border
    };
};

export const TASK_STATUSES = Object.entries(TASK_STATUS_CONFIG)
    .map(([id, config]) => ({
        id,
        title: config.title,
        color: config.color,
        border: config.border
    }));

export const getAvailableStatuses = () => {
    return Object.entries(TASK_STATUS_CONFIG)
        .filter(([_, config]) => !config.isSpecialStatus)
        .map(([id, config]) => ({
            id,
            title: config.title
        }))
        .sort((a, b) => TASK_STATUS_CONFIG[a.id].displayOrder - TASK_STATUS_CONFIG[b.id].displayOrder);
};

export const getPriorityBadge = (priority) => {
    const config = PRIORITY_CONFIG[priority] || PRIORITY_CONFIG.low;
    return <Badge bg={config.variant}>{config.label}</Badge>;
};

export const formatDate = (dateString) => {
    if (!dateString) return '';

    try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '';

        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    } catch (error) {
        console.error('Error formatting date:', error);
        return '';
    }
};

const getDaysDifference = (endDate) => {
    if (!endDate) return null;
    try {
        const dueDate = new Date(endDate);
        const today = new Date();
        return Math.ceil((dueDate - today) / (1000 * 60 * 60 * 24));
    } catch (error) {
        console.error('Error calculating days difference:', error);
        return null;
    }
};

export const isDueWarning = (task) => {
    const daysUntilDue = getDaysDifference(task?.endDate);
    return daysUntilDue !== null && daysUntilDue <= 3 && daysUntilDue > 0;
};

export const isOverdue = (task) => {
    const daysUntilDue = getDaysDifference(task?.endDate);
    return daysUntilDue !== null && daysUntilDue < 0;
};

export const ClickableUrls = ({ text }) => {
    if (!text) return null;

    // Match both markdown links [text](url) and plain URLs
    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const urlRegex = /(https?:\/\/[^\s]+)(?!\))/g; // negative lookahead to avoid matching URLs in markdown links

    try {
        // First, process markdown-style links
        let processedText = text;
        let elements = [];
        let lastIndex = 0;

        // Handle markdown-style links
        const markdownMatches = [...text.matchAll(markdownLinkRegex)];
        markdownMatches.forEach((match, index) => {
            const [fullMatch, linkText, url] = match;
            const matchIndex = match.index;

            // Add text before the match
            if (matchIndex > lastIndex) {
                elements.push(
                    <span key={`text-${index}`}>
                        {processedText.slice(lastIndex, matchIndex)}
                    </span>
                );
            }

            // Add the link
            elements.push(
                <a
                    key={`markdown-link-${index}`}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800"
                >
                    {linkText}
                </a>
            );

            lastIndex = matchIndex + fullMatch.length;
        });

        // Add remaining text and process it for regular URLs
        const remainingText = processedText.slice(lastIndex);
        if (remainingText) {
            const parts = remainingText.split(urlRegex);
            const urlMatches = remainingText.match(urlRegex) || [];

            const processedParts = parts.map((part, i) => {
                if (urlMatches.includes(part)) {
                    return (
                        <a
                            key={`url-${i}`}
                            href={part}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800"
                        >
                            {part}
                        </a>
                    );
                }
                return <span key={`text-remaining-${i}`}>{part}</span>;
            });

            elements.push(...processedParts);
        }

        return <span>{elements}</span>;
    } catch (error) {
        console.error('Error processing clickable URLs:', error);
        return <span>{text}</span>;
    }
};

/**
 * Creates a markdown-style link by wrapping the selected text
 * @param {string} text - The current description text
 * @param {number} selectionStart - Start position of selection
 * @param {number} selectionEnd - End position of selection
 * @param {string} url - The URL to be inserted
 * @returns {Object} - New text and updated cursor position
 */
export const createMarkdownLink = (text, selectionStart, selectionEnd, url) => {
    const selectedText = text.substring(selectionStart, selectionEnd);
    const linkText = selectedText || 'Link text';
    const markdownLink = `[${linkText}](${url})`;

    const newText =
        text.substring(0, selectionStart) +
        markdownLink +
        text.substring(selectionEnd);

    // Calculate new cursor position (end of the inserted link)
    const newCursorPosition = selectionStart + markdownLink.length;

    return {
        text: newText,
        cursorPosition: newCursorPosition
    };
};

/**
 * Opens a dialog to insert a link at the current cursor position
 * @param {string} currentText - The current text in the textarea
 * @param {number} selectionStart - Current selection start position
 * @param {number} selectionEnd - Current selection end position
 * @param {function} onUpdate - Callback function to update the text
 */
export const handleInsertLink = async (currentText, selectionStart, selectionEnd, onUpdate) => {
    // You can replace this with your preferred UI for URL input
    const url = window.prompt('Enter URL:');
    if (!url) return;

    // Create the markdown link
    const { text, cursorPosition } = createMarkdownLink(
        currentText,
        selectionStart,
        selectionEnd,
        url
    );

    // Update the text
    onUpdate(text);

    return cursorPosition;
};

export const getProgressColor = (progress) => {
    if (progress >= 100) return 'success';
    if (progress >= 70) return 'info';
    if (progress >= 30) return 'warning';
    return 'danger';
};

// Simplified options generators using configs
export const getStatusOptions = () =>
    getAvailableStatuses().map(({ id, title }) => ({
        value: id,
        label: title
    }));

export const getPriorityOptions = () =>
    Object.entries(PRIORITY_CONFIG)
        .map(([value, { label, order }]) => ({
            value,
            label
        }))
        .sort((a, b) => PRIORITY_CONFIG[a.value].order - PRIORITY_CONFIG[b.value].order);

