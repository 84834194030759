import { useEffect, useRef } from 'react';
import { EditorContent } from '@tiptap/react';
import { useEditor } from './EditorContext';
import { useAuth } from '../../contexts/AuthContext';
import { useImageUpload } from './useImageUpload';
import { useImageDeletion } from './useImageDeletion';
import "../../assets/css/TiptapMini.css";

const TiptapContent = () => {
    const { user } = useAuth();
    const editor = useEditor();
    const recentlyAddedImages = useRef(new Set());
    const imageKeyToFilename = useRef(new Map());

    const { handlePaste, handleDrop, handleDragOver } = useImageUpload(
        editor,
        user,
        recentlyAddedImages,
        imageKeyToFilename
    );

    const handleImageDeletion = useImageDeletion(
        user,
        recentlyAddedImages,
        imageKeyToFilename
    );

    useEffect(() => {
        if (editor) {
            editor.on('transaction', handleImageDeletion);
        }
        return () => {
            if (editor) {
                editor.off('transaction', handleImageDeletion);
                recentlyAddedImages.current.clear();
                imageKeyToFilename.current.clear();
            }
        };
    }, [editor, handleImageDeletion]);

    useEffect(() => {
        if (editor) {
            const editorElement = editor.view.dom;
            editorElement.addEventListener('paste', handlePaste);
            editor.on('transaction', handleImageDeletion);

            return () => {
                editorElement.removeEventListener('paste', handlePaste);
                editor.off('transaction', handleImageDeletion);
                recentlyAddedImages.current.clear();
                imageKeyToFilename.current.clear();
            };
        }
    }, [editor, handlePaste, handleImageDeletion]);

    if (!editor) {
        return null;
    }

    return (
        <div onDrop={handleDrop} onDragOver={handleDragOver}>
            <EditorContent
                editor={editor}
                className="tiptap-editable-content"
                spellCheck="true"
            />
        </div>
    );
};

export default TiptapContent;