import journalApi from "../config/JournalApiConfig";

/**
 * ProjectApiService provides methods for interacting with the project management API endpoints.
 * It handles all CRUD operations for projects and maintains consistent error handling patterns.
 */
const ProjectApiService = {
    /**
     * Retrieves all projects for a specific user.
     * @param {string} username - The username of the project owner
     * @returns {Promise<Array>} Array of project objects
     */
    getAllProjects: async (username) => {
        try {
            const response = await journalApi.get(`/projects/${username}`);
            // Transform the API response to match the React component's expected structure
            return response.data.map(project => ({
                id: project.project_data.id,
                name: project.project_name,
                tasks: project.project_data.tasks || []
            }));
        } catch (error) {
            console.error('Error fetching projects:', error);
            throw error;
        }
    },

    /**
     * Creates a new project for a user.
     * @param {string} username - The username of the project owner
     * @param {Object} project - The project object containing name and tasks
     * @returns {Promise<Object>} The created project
     */
    createProject: async (username, project) => {
        try {
            const response = await journalApi.post(`/projects/${username}`, project);
            return {
                id: response.data.project_data.id,
                name: response.data.project_name,
                tasks: response.data.project_data.tasks || []
            };
        } catch (error) {
            console.error('Error creating project:', error);
            throw error;
        }
    },

    /**
     * Updates an existing project by its name.
     * @param {string} username - The username of the project owner
     * @param {string} projectName - The name of the project to update
     * @param {Object} project - The updated project object
     * @returns {Promise<Object>} The updated project
     */
    updateProject: async (username, projectName, project) => {
        try {
            const response = await journalApi.put(
                `/projects/${username}/${encodeURIComponent(projectName)}`,
                project
            );
            return {
                id: response.data.project_data.id,
                name: response.data.project_name,
                tasks: response.data.project_data.tasks || []
            };
        } catch (error) {
            console.error('Error updating project:', error);
            throw error;
        }
    },

    /**
     * Deletes a project by its name.
     * @param {string} username - The username of the project owner
     * @param {string} projectName - The name of the project to delete
     * @returns {Promise<void>}
     */
    deleteProject: async (username, projectName) => {
        try {
            await journalApi.delete(
                `/projects/${username}/${encodeURIComponent(projectName)}`
            );
        } catch (error) {
            console.error('Error deleting project:', error);
            throw error;
        }
    },

    // --------------------- SHARING -----------------------------

    /**
    * Shares a project with another user with specified permissions.
    * @param {string} ownerUsername - The username of the project owner
    * @param {string} projectName - The name of the project to share
    * @param {string} sharedWithUsername - The username to share the project with
    * @param {string} permissions - The permission level ('read' or 'write')
    * @returns {Promise<Object>} The sharing confirmation
    */
    shareProject: async (ownerUsername, projectName, sharedWithUsername, permissions) => {
        try {
            const response = await journalApi.post(
                `/projects/${ownerUsername}/${encodeURIComponent(projectName)}/share`,
                {
                    shared_with_username: sharedWithUsername,
                    permissions: permissions
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error sharing project:', error);
            throw error;
        }
    },

    /**
     * Retrieves all projects shared with the current user.
     * This method transforms the API response to match the same structure
     * as regular projects, but includes additional sharing metadata.
     * @param {string} username - The username of the current user
     * @returns {Promise<Array>} Array of shared project objects
     */
    getSharedProjects: async (username) => {
        try {
            const response = await journalApi.get(`/projects/${username}/shared-with-me`);
            return response.data.map(project => ({
                id: project.project_data.id,
                name: project.project_name,
                tasks: project.project_data.tasks || [],
                owner: project.owner_username,
                permission: project.permission,
                isShared: true  // Flag to identify shared projects in the UI
            }));
        } catch (error) {
            console.error('Error fetching shared projects:', error);
            throw error;
        }
    },

    /**
     * Gets the sharing information for a specific project or all projects.
     * @param {string} username - The username of the project owner
     * @param {string} [projectName] - Optional project name to get specific sharing info
     * @returns {Promise<Array>} Array of sharing details
     */
    getProjectSharingInfo: async (username, projectName = null) => {
        try {
            if (!projectName) {
                throw new Error('Project name is required for getting sharing info');
            }

            const endpoint = `/projects/${username}/sharing?project_name=${encodeURIComponent(projectName)}`;
            const response = await journalApi.get(endpoint);
            return response.data;
        } catch (error) {
            console.error('Error fetching project sharing information:', error);
            throw error;
        }
    },

    /**
     * Enhanced version of getAllProjects that includes both owned and shared projects.
     * @param {string} username - The username of the current user
     * @param {boolean} [includeShared=true] - Whether to include shared projects
     * @returns {Promise<Array>} Combined array of owned and shared projects
     */
    getAllProjectsEnhanced: async (username, includeShared = true) => {
        try {
            // Make both calls in parallel
            const [ownedProjects, sharedProjects] = await Promise.all([
                ProjectApiService.getAllProjects(username),
                includeShared ? ProjectApiService.getSharedProjects(username) : Promise.resolve([])
            ]);

            // Add isShared flag to owned projects explicitly
            const enhancedOwnedProjects = ownedProjects.map(project => ({
                ...project,
                isShared: false
            }));

            // Combine and sort projects
            return [...enhancedOwnedProjects, ...sharedProjects].sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
        } catch (error) {
            console.error('Error fetching all projects:', error);
            throw error;
        }
    },

    /**
     * Updates an existing project, handling both owned and shared projects.
     * @param {string} username - The current user's username
     * @param {string} projectName - The name of the project to update
     * @param {Object} project - The updated project object
     * @param {string} ownerUsername - The username of the project owner (if shared)
     * @returns {Promise<Object>} The updated project
     */
    updateProject: async (username, projectName, project, ownerUsername = null) => {
        try {
            // If it's a shared project, use the owner's username in the URL
            const targetUsername = ownerUsername || username;

            const response = await journalApi.put(
                `/projects/${targetUsername}/${encodeURIComponent(projectName)}`,
                {
                    ...project,
                    owner_username: ownerUsername  // Add this to help backend identify shared projects
                }
            );

            return {
                id: response.data.project_data.id,
                name: response.data.project_name,
                tasks: response.data.project_data.tasks || [],
                owner: response.data.owner_username,
                permission: response.data.permission,
                isShared: Boolean(ownerUsername)
            };
        } catch (error) {
            console.error('Error updating project:', error);
            throw error;
        }
    },
    /**
     * Removes sharing access for a specific user from a project.
     * @param {string} ownerUsername - The username of the project owner
     * @param {string} projectName - The name of the project to unshare
     * @param {string} sharedWithUsername - The username of the person to remove access from
     * @returns {Promise<Object>} Response message confirming the unshare operation
     * @throws {Error} If the unshare operation fails
     */
    unshareProject: async (ownerUsername, projectName, sharedWithUsername) => {
        try {
            // Using template literals to construct the URL following REST conventions
            const response = await journalApi.delete(
                `/projects/${ownerUsername}/${projectName}/share/${sharedWithUsername}`
            );

            // Return the response data directly since it's already in the correct format
            // The backend returns a message confirming the unshare operation
            return response.data;
        } catch (error) {
            // Log the error for debugging purposes while preserving the original stack trace
            console.error('Error unsharing project:', error);

            // Enhance error handling by providing more context about the operation
            // This helps with debugging and showing appropriate error messages to users
            const enhancedError = new Error(
                error.response?.data?.detail ||
                'Failed to unshare project. Please try again later.'
            );
            enhancedError.originalError = error;
            enhancedError.statusCode = error.response?.status;

            // Rethrow the enhanced error for handling by the calling component
            throw enhancedError;
        }
    },
};

export default ProjectApiService;