import { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Alert, Spinner, Badge } from 'react-bootstrap';
import aiModelApiService from '../../services/aiModelApiService';

const OpenRouterModelsMgt = () => {
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [error, setError] = useState(null);
    const [syncSuccess, setSyncSuccess] = useState(null);
    const [showFreeOnly, setShowFreeOnly] = useState(false);

    useEffect(() => {
        fetchModels();
    }, []);

    const fetchModels = async () => {
        try {
            setLoading(true);
            setError(null);
            const data = await aiModelApiService.listModels({
                activeOnly: false,
                provider: 'OpenRouter'
            });
            setModels(data);
        } catch (err) {
            console.error('Error fetching models:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSync = async () => {
        try {
            setSyncing(true);
            setError(null);
            setSyncSuccess(null);

            const result = await aiModelApiService.syncOpenRouterModels();
            setSyncSuccess(`Successfully synchronized ${result.count} models`);

            // Refresh the models list after sync
            await fetchModels();
        } catch (err) {
            setError(err.message);
        } finally {
            setSyncing(false);
        }
    };

    const formatPrice = (price) => {
        return typeof price === 'number' ? `$${price.toFixed(6)}` : 'N/A';
    };

    return (
        <Container fluid className="p-4">
            <Card>
                <Card.Header>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="mb-0">OpenRouter Models</h4>
                        <Button
                            variant="primary"
                            onClick={handleSync}
                            disabled={syncing || loading}
                        >
                            {syncing ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                    />
                                    Syncing...
                                </>
                            ) : 'Sync Models'}
                        </Button>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="freeModelsFilter"
                                checked={showFreeOnly}
                                onChange={(e) => setShowFreeOnly(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="freeModelsFilter">
                                Show Free Models Only
                            </label>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {error && (
                        <Alert variant="danger" className="mb-4">
                            {error}
                        </Alert>
                    )}

                    {syncSuccess && (
                        <Alert variant="success" className="mb-4">
                            {syncSuccess}
                        </Alert>
                    )}

                    {loading ? (
                        <div className="text-center p-4">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            <Row className="g-4">
                                {Array.isArray(models) && models
                                    .filter(model => !showFreeOnly || (
                                        (!model.input_price_per_1k_tokens_usd || model.input_price_per_1k_tokens_usd === 0) &&
                                        (!model.output_price_per_1k_tokens_usd || model.output_price_per_1k_tokens_usd === 0)
                                    ))
                                    .map((model) => (
                                        <Col key={model.id} xs={12} md={6} lg={4}>
                                            <Card
                                                className={`h-100 ${!model.is_active ? 'border-danger' : ''}`}
                                            >
                                                <Card.Body>
                                                    <Card.Title className="d-flex justify-content-between align-items-center">
                                                        <span className="text-truncate">{model.name}</span>
                                                        {!model.is_active && (
                                                            <Badge bg="danger">Inactive</Badge>
                                                        )}
                                                    </Card.Title>
                                                    {model.description && (
                                                        <Card.Text className="text-muted small">
                                                            {model.description}
                                                        </Card.Text>
                                                    )}
                                                    <div className="small">
                                                        <p className="mb-1">
                                                            <strong>Context Length:</strong>{' '}
                                                            {model.context_length?.toLocaleString() || 'N/A'}
                                                        </p>
                                                        <p className="mb-1">
                                                            <strong>Modality:</strong> {model.modality || 'N/A'}
                                                        </p>
                                                        <p className="mb-1">
                                                            <strong>Input Price:</strong>{' '}
                                                            {formatPrice(model.input_price_per_1k_tokens_usd)}/1k tokens
                                                        </p>
                                                        <p className="mb-1">
                                                            <strong>Output Price:</strong>{' '}
                                                            {formatPrice(model.output_price_per_1k_tokens_usd)}/1k tokens
                                                        </p>
                                                        {model.image_price_per_1k_tokens_usd > 0 && (
                                                            <p className="mb-1">
                                                                <strong>Image Price:</strong>{' '}
                                                                {formatPrice(model.image_price_per_1k_tokens_usd)}/1k tokens
                                                            </p>
                                                        )}
                                                        {model.deprecated_at && (
                                                            <p className="mb-1 text-danger">
                                                                <strong>Deprecated:</strong>{' '}
                                                                {new Date(model.deprecated_at).toLocaleDateString()}
                                                            </p>
                                                        )}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                            </Row>
                            {(!models || models.length === 0) && !loading && (
                                <div className="text-center text-muted">
                                    No models found. Click Sync to fetch models from OpenRouter.
                                </div>
                            )}
                        </>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default OpenRouterModelsMgt;