import React from 'react';
import { Info } from 'lucide-react';
import { Button } from 'react-bootstrap';

const WelcomeScreen = ({
    initialLoad = true,
    onShowRecommendation,
    customMessage = "Select a chat or create a new one to begin",
    welcomeTitle = "How can I help you?",
    buttonText = "Help choose model"
}) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
            {initialLoad ? (
                <div className="text-center text-muted">
                    <h1 className="mb-3">{welcomeTitle}</h1>
                    <Button
                        variant="outline-secondary"
                        className="d-flex align-items-center gap-2"
                        onClick={onShowRecommendation}
                    >
                        <Info size={18} />
                        {buttonText}
                    </Button>
                </div>
            ) : (
                <div className="text-center text-muted">
                    {customMessage}
                </div>
            )}
        </div>
    );
};

export default WelcomeScreen;