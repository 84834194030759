import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    ChevronDown, ChevronUp, Plus, Calendar, Users,
    GripVertical, AlertCircle, Trash2, FolderPlus, FileDown,
    Save, Share2
} from 'lucide-react';
import {
    Card, Form, Button, Badge, InputGroup, Container,
    Row, Col, ButtonGroup, Dropdown
} from 'react-bootstrap';
import ProjectApiService from '../../services/projectApiService';
import { DeleteProjectModal, DeleteTaskModal, NewProjectModal } from './ProjectModals';
import exportProjectToPDF from './exportProjectToPDF';
import AppFooter from '../AppFooter';
import useTaskManagement from './useTaskManagement';
import TaskCard from './TaskCard';
import KanbanBoard from './KanbanBoard';
import { formatDate } from './taskDisplayUtils';
import { ShareProjectModal } from './ProjectSharing';

const ProjectTimeline = () => {
    // Core state management
    const { user } = useAuth();
    const [projects, setProjects] = useState([]);
    const [activeProjectId, setActiveProjectId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [viewMode, setViewMode] = useState('timeline');

    // Project-level modal state
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNewProjectModal, setShowNewProjectModal] = useState(false);
    const [newProjectName, setNewProjectName] = useState('');
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null);
    const [showShareModal, setShowShareModal] = useState(false);

    // Sharing information state
    const [projectSharingInfo, setProjectSharingInfo] = useState({});

    // Initialize task management hook
    const {
        newTaskTitle,
        setNewTaskTitle,
        setTaskToDelete,
        addNewTask,
        updateTask,
        handleDelete,
        toggleSubtask,
        addSubtask,
        handleDragEnd
    } = useTaskManagement({
        projects,
        activeProjectId,
        setProjects,
        setHasUnsavedChanges
    });

    // Memoized active project
    const activeProject = React.useMemo(() => {
        return projects.find(p => p.id === activeProjectId) || null;
    }, [projects, activeProjectId]);

    // Auto-save effect
    useEffect(() => {
        if (!hasUnsavedChanges) return;
        const timer = setTimeout(saveProject, 1000);
        return () => clearTimeout(timer);
    }, [hasUnsavedChanges]);

    // Load sharing info for a project
    const loadProjectSharingInfo = async (projectName) => {
        console.log('loadProjectSharingInfo called for:', projectName);
        if (!user?.username || !projectName || projectSharingInfo[projectName]) {
            console.log('loadProjectSharingInfo early return. Conditions:', {
                noUser: !user?.username,
                noProjectName: !projectName,
                alreadyLoaded: projectSharingInfo[projectName]
            });
            return;
        }

        try {
            const info = await ProjectApiService.getProjectSharingInfo(user.username, projectName);
            setProjectSharingInfo(prev => ({
                ...prev,
                [projectName]: info
            }));
        } catch (error) {
            console.error('Failed to load sharing information:', error);
        }
    };

    // Initial data loading - combined project and sharing info fetch
    useEffect(() => {
        console.log('Initial data loading effect triggered');
        const loadInitialData = async () => {
            if (!user?.username) {
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            setError(null);
            try {
                const fetchedProjects = await ProjectApiService.getAllProjectsEnhanced(user.username);
                console.log('Fetched projects:', fetchedProjects);
                const ownedProjects = fetchedProjects.filter(p => !p.isShared);
                const sharedProjects = fetchedProjects.filter(p => p.isShared);

                // If we have projects, fetch sharing info for the first owned project
                if (ownedProjects.length > 0) {
                    const firstProject = ownedProjects[0];
                    console.log('Loading sharing info for first project:', firstProject.name);
                    const sharingInfo = await ProjectApiService.getProjectSharingInfo(user.username, firstProject.name);

                    // Set all state in one go
                    setProjects([...ownedProjects, ...sharedProjects]);
                    setActiveProjectId(firstProject.id);
                    setProjectSharingInfo({ [firstProject.name]: sharingInfo });
                } else if (sharedProjects.length > 0) {
                    setProjects([...ownedProjects, ...sharedProjects]);
                    setActiveProjectId(sharedProjects[0].id);
                } else {
                    setProjects([]);
                }
            } catch (error) {
                if (error.response?.status === 404) {
                    setProjects([]);
                } else {
                    setError('Failed to load projects. Please try again later.');
                }
            } finally {
                setIsLoading(false);
            }
        };

        loadInitialData();
    }, [user?.username]);

    // Handle project switch
    const handleProjectSwitch = async (projectId) => {
        console.log('handleProjectSwitch called for:', projectId);
        setActiveProjectId(projectId);
        const newActiveProject = projects.find(p => p.id === projectId);
        if (newActiveProject && !newActiveProject.isShared && !projectSharingInfo[newActiveProject.name]) {
            console.log('New active project:', newActiveProject);
            await loadProjectSharingInfo(newActiveProject.name);
        }
    };

    // Project saving functionality
    const saveProject = async () => {
        if (!user?.username || !activeProject) return;

        try {
            await ProjectApiService.updateProject(
                user.username,
                activeProject.name,
                activeProject,
                activeProject.isShared ? activeProject.owner : null
            );
            setHasUnsavedChanges(false);
            return true;
        } catch (error) {
            console.error('Failed to save project:', error);
            setError('Failed to save project. Please try again.');
            throw error;
        }
    };

    // Handle sharing updates
    const handleShareUpdate = async () => {
        if (!activeProject) return;

        // Reload sharing info for the active project
        await loadProjectSharingInfo(activeProject.name);

        // Refresh project list to update sharing status
        const fetchedProjects = await ProjectApiService.getAllProjectsEnhanced(user.username);
        const ownedProjects = fetchedProjects.filter(p => !p.isShared);
        const sharedProjects = fetchedProjects.filter(p => p.isShared);
        setProjects([...ownedProjects, ...sharedProjects]);
    };

    // Project management functions
    const addNewProject = async () => {
        if (!newProjectName.trim() || !user?.username) return;

        try {
            const newProject = {
                id: Date.now().toString(),
                name: newProjectName,
                tasks: []
            };

            const createdProject = await ProjectApiService.createProject(user.username, newProject);
            setProjects(prevProjects => [...prevProjects, createdProject]);
            setNewProjectName('');
            setShowNewProjectModal(false);
            handleProjectSwitch(createdProject.id);
        } catch (error) {
            console.error('Failed to create project:', error);
            setError('Failed to create project. Please try again.');
        }
    };

    const handleDeleteProject = async () => {
        if (!projectToDelete || !user?.username || projects.length <= 0) return;

        try {
            const projectToDeleteData = projects.find(p => p.id === projectToDelete);
            await ProjectApiService.deleteProject(user.username, projectToDeleteData.name);

            setProjects(prevProjects => {
                const updatedProjects = prevProjects.filter(p => p.id !== projectToDelete);
                if (activeProjectId === projectToDelete && updatedProjects.length > 0) {
                    handleProjectSwitch(updatedProjects[0].id);
                }
                return updatedProjects;
            });

            setShowDeleteProjectModal(false);
            setProjectToDelete(null);
        } catch (error) {
            console.error('Failed to delete project:', error);
            setError('Failed to delete project. Please try again.');
        }
    };

    // Render conditions
    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center min-vh-100">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (!user?.username) {
        return <div>Please log in to view projects</div>;
    }

    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                {error}
            </div>
        );
    }

    // Empty state
    if (projects.length === 0) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center min-vh-100">
                <h4>No projects yet</h4>
                <p>Create your first project to get started!</p>
                <Button variant="primary" onClick={() => setShowNewProjectModal(true)}>
                    <Plus size={20} /> Create Project
                </Button>
                <NewProjectModal
                    show={showNewProjectModal}
                    onHide={() => setShowNewProjectModal(false)}
                    projectName={newProjectName}
                    onProjectNameChange={setNewProjectName}
                    onCreateProject={addNewProject}
                />
            </div>
        );
    }

    // Main component render
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="flex-grow-1">
                <Container fluid>
                    {/* Project navigation */}
                    <Row className="mb-3 mt-2">
                        <Col>
                            <div className="d-flex align-items-center gap-3">
                                <Dropdown>
                                    <Dropdown.Toggle variant="outline-primary">
                                        {activeProject?.name || 'Select Project'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* Owned Projects Section */}
                                        <Dropdown.Header>My Projects</Dropdown.Header>
                                        {projects.filter(project => !project.isShared).map(project => (
                                            <Dropdown.Item
                                                key={project.id}
                                                onClick={() => handleProjectSwitch(project.id)}
                                                active={project.id === activeProjectId}
                                            >
                                                {project.name}
                                            </Dropdown.Item>
                                        ))}

                                        {/* Shared Projects Section */}
                                        {projects.some(project => project.isShared) && (
                                            <>
                                                <Dropdown.Divider />
                                                <Dropdown.Header>Shared With Me</Dropdown.Header>
                                                {projects.filter(project => project.isShared).map(project => (
                                                    <Dropdown.Item
                                                        key={project.id}
                                                        onClick={() => handleProjectSwitch(project.id)}
                                                        active={project.id === activeProjectId}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2">{project.name}</span>
                                                            <Badge bg={project.permission === 'write' ? 'success' : 'info'} className="ms-auto">
                                                                {project.permission === 'write' ? 'Editor' : 'Viewer'}
                                                            </Badge>
                                                        </div>
                                                        <small className="text-muted d-block">
                                                            Shared by: {project.owner}
                                                        </small>
                                                    </Dropdown.Item>
                                                ))}
                                            </>
                                        )}

                                        {/* New Project Option */}
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => setShowNewProjectModal(true)}>
                                            <FolderPlus className="me-2" size={16} />
                                            New Project
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => setShowShareModal(true)}
                                >
                                    <Share2 size={16} /> Sharing
                                </Button>

                                {!activeProject?.isShared && (
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => {
                                            setProjectToDelete(activeProjectId);
                                            setShowDeleteProjectModal(true);
                                        }}
                                    >
                                        <Trash2 size={16} /> Delete Project
                                    </Button>
                                )}

                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={() => exportProjectToPDF(activeProject)}
                                >
                                    <FileDown size={16} /> Export PDF
                                </Button>

                                {(!activeProject?.isShared || activeProject?.permission === 'write') && (
                                    <Button
                                        variant={hasUnsavedChanges ? 'outline-warning' : 'outline-success'}
                                        size="sm"
                                        onClick={saveProject}
                                        disabled={!hasUnsavedChanges}
                                        className={!hasUnsavedChanges ? 'border-0' : ''}
                                    >
                                        <Save size={16} className="me-1" />
                                        {hasUnsavedChanges ? 'Save Changes' : 'Saved'}
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>

                    {/* View Toggle */}
                    <Row className="mb-3">
                        <Col>
                            <ButtonGroup>
                                <Button
                                    variant={viewMode === 'timeline' ? 'primary' : 'outline-primary'}
                                    onClick={() => setViewMode('timeline')}
                                >
                                    Timeline View
                                </Button>
                                <Button
                                    variant={viewMode === 'kanban' ? 'primary' : 'outline-primary'}
                                    onClick={() => setViewMode('kanban')}
                                >
                                    Kanban Board
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    {/* Add new task input */}
                    {(!activeProject?.isShared || activeProject?.permission === 'write') && (
                        <Row className="mb-4">
                            <Col>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        value={newTaskTitle}
                                        onChange={(e) => setNewTaskTitle(e.target.value)}
                                        placeholder="Add new task..."
                                        onKeyDown={(e) => e.key === 'Enter' && addNewTask()}
                                    />
                                    <Button variant="primary" onClick={addNewTask}>
                                        <Plus size={20} /> Add Task
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    )}

                    {/* Conditional View Rendering */}
                    {viewMode === 'timeline' ? (
                        // Timeline View
                        <div style={{ position: 'relative' }}>
                            <div style={{
                                position: 'absolute',
                                left: '100px',
                                top: 0,
                                bottom: 0,
                                width: '2px',
                                backgroundColor: '#dee2e6'
                            }} />

                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="tasks">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {activeProject?.tasks?.map((task, index) => (
                                                <Draggable key={task.id} draggableId={task.id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            id={`task-${task.id}`}
                                                            style={{
                                                                marginBottom: '1rem',
                                                                marginLeft: '120px',
                                                                ...provided.draggableProps.style
                                                            }}
                                                        >
                                                            <div style={{
                                                                position: 'absolute',
                                                                left: '100px',
                                                                transform: 'translateX(-50%)',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '50%',
                                                                backgroundColor: 'white',
                                                                border: '4px solid var(--bs-primary)',
                                                                marginTop: '10px'
                                                            }} />

                                                            <TaskCard
                                                                task={task}
                                                                updateTask={updateTask}
                                                                onDeleteClick={(taskId) => {
                                                                    setTaskToDelete(taskId);
                                                                    setShowDeleteModal(true);
                                                                }}
                                                                toggleSubtask={toggleSubtask}
                                                                addSubtask={addSubtask}
                                                                allTasks={activeProject.tasks}
                                                                dragHandleProps={provided.dragHandleProps}
                                                                formatDate={formatDate}
                                                                project={activeProject}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    ) : (
                        // Kanban View
                        <KanbanBoard
                            project={activeProject}
                            onTaskUpdate={(updatedTasks) => {
                                setProjects(prev => prev.map(p =>
                                    p.id === activeProjectId
                                        ? { ...p, tasks: updatedTasks }
                                        : p
                                ));
                                setHasUnsavedChanges(true);
                            }}
                            isReadOnly={activeProject?.isShared && activeProject?.permission !== 'write'}
                        />
                    )}
                </Container>
            </div>

            {/* Footer */}
            <div className="mt-auto">
                <footer className="bg-light w-100">
                    <Container fluid className="ps-0 pe-0">
                        <AppFooter
                            showSidebarToggle={false}
                            showCalendar={false}
                            showSearch={false}
                            showJournal={true}
                            showAIChat={true}
                            showCompletedTasks={false}
                            showUserDropdown={true}
                        />
                    </Container>
                </footer>
            </div>

            {/* Modals */}
            <DeleteProjectModal
                show={showDeleteProjectModal}
                onHide={() => setShowDeleteProjectModal(false)}
                onDelete={handleDeleteProject}
            />

            <DeleteTaskModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onDelete={handleDelete}
            />

            <NewProjectModal
                show={showNewProjectModal}
                onHide={() => setShowNewProjectModal(false)}
                projectName={newProjectName}
                onProjectNameChange={setNewProjectName}
                onCreateProject={addNewProject}
            />

            <ShareProjectModal
                show={showShareModal}
                mountOnEnter
                unmountOnExit
                onHide={() => setShowShareModal(false)}
                project={activeProject}
                sharingInfo={activeProject ? projectSharingInfo[activeProject.name] || [] : []}
                onShareUpdate={handleShareUpdate}
            />
        </div>
    );
};

export default ProjectTimeline;