import React, { useState, useEffect } from 'react';
import { Nav, Button, Form, Dropdown, Modal } from 'react-bootstrap';
import { MessageSquare, Edit2, Trash2 } from 'lucide-react';

const ConversationItem = ({ conv, chat, onDelete, onRename }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [editTitle, setEditTitle] = useState(conv.title?.replaceAll('*', '') || '');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (!isEditing) {
            setEditTitle(conv.title?.replaceAll('*', '') || '');
        }
    }, [conv.title, isEditing]);

    const handleRename = () => {
        setIsEditing(true);
        setShowMenu(false);
    };

    const handleSaveRename = () => {
        // Remove any asterisks before saving
        const cleanTitle = editTitle.replaceAll('*', '');
        onRename(conv.id, cleanTitle);
        setIsEditing(false);
    };

    const handleDelete = () => {
        setShowConfirmDelete(true);
        setShowMenu(false);
    };

    const confirmDelete = async () => {
        await onDelete(conv.id);
        setShowConfirmDelete(false);
    };

    return (
        <Nav.Item key={`${conv.id}-${conv.updatedAt}`}>
            <Nav.Link
                active={chat.currentChat === conv.id}
                onClick={(e) => {
                    if (!isEditing) {
                        chat.setCurrentChat(conv.id);
                    }
                }}
                className="d-flex align-items-center py-2"
            >
                <MessageSquare size={16} className="me-2" />
                {isEditing ? (
                    <Form.Control
                        type="text"
                        value={editTitle}
                        onChange={(e) => {
                            // Remove asterisks from input
                            const cleanValue = e.target.value.replaceAll('*', '');
                            setEditTitle(cleanValue);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === ' ') {
                                e.preventDefault();
                                setEditTitle(prev => prev + ' ');
                            }
                            if (e.key === 'Enter') {
                                handleSaveRename();
                            } else if (e.key === 'Escape') {
                                setIsEditing(false);
                                setEditTitle(conv.title?.replaceAll('*', '') || '');
                            }
                        }}
                        onBlur={() => {
                            setIsEditing(false);
                            setEditTitle(conv.title?.replaceAll('*', '') || '');
                        }}
                        autoFocus
                        style={{ maxWidth: '150px' }}
                    />
                ) : (
                    <span style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {(conv.title?.replaceAll('*', '') || 'New Chat')}
                    </span>
                )}
                <Dropdown
                    show={showMenu}
                    onToggle={() => setShowMenu(!showMenu)}
                    className="ms-auto"
                >
                    <Dropdown.Toggle
                        variant="link"
                        className="text-muted p-0"
                        as="span"
                    />
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleRename} disabled={isEditing}>
                            <Edit2 size={16} className="me-2" /> Rename
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleDelete}>
                            <Trash2 size={16} className="me-2" /> Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Nav.Link>

            <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this conversation?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmDelete(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Nav.Item>
    );
};

export default ConversationItem;