import React, { useEffect, useState, useRef } from 'react';
import { Bot, X, CornerUpRight } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { useComment } from './CommentContext';
import { Container, Card, Alert } from 'react-bootstrap';

const AICommentsDisplay = ({ editor }) => {
    const [comments, setComments] = useState([]);
    const [activeCommentId, setActiveCommentId] = useState(null);
    const { highlightedCommentId, setHighlightedCommentId } = useComment();
    const commentRefs = useRef({});
    const commentsContainerRef = useRef(null);
    const [toolbarHeight, setToolbarHeight] = useState(0);

    // Setup storage setters only once
    useEffect(() => {
        if (editor && editor.storage.aiComment) {
            if (!editor.storage.aiComment.setActiveCommentId) {
                editor.storage.aiComment.setActiveCommentId = setActiveCommentId;
            }
            if (!editor.storage.aiComment.setHighlightedCommentId) {
                editor.storage.aiComment.setHighlightedCommentId = setHighlightedCommentId;
            }
        }
    }, [editor, setHighlightedCommentId]);

    // Monitor storage changes and handle scrolling
    useEffect(() => {
        if (!editor) return;

        const checkStorage = () => {
            const storedActiveId = editor.storage.aiComment?.activeCommentId;
            if (storedActiveId && storedActiveId !== activeCommentId) {
                setActiveCommentId(storedActiveId);
                setHighlightedCommentId(storedActiveId);

                // Scroll comment into view with a slight delay to ensure DOM update
                setTimeout(() => {
                    const commentElement = commentRefs.current[storedActiveId];
                    if (commentElement && commentsContainerRef.current) {
                        commentElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest'
                        });
                    }
                }, 100);
            }
        };

        editor.on('transaction', checkStorage);
        return () => editor.off('transaction', checkStorage);
    }, [editor, activeCommentId, highlightedCommentId, setHighlightedCommentId]);

    // Update comments list
    useEffect(() => {
        if (!editor) return;

        const updateComments = () => {
            try {
                let currentComments = editor.storage.aiComment?.comments || [];
                currentComments = [...currentComments].sort((a, b) => a.from - b.from);

                currentComments = currentComments.filter(comment => {
                    try {
                        const currentText = editor.state.doc.textBetween(comment.from, comment.to, ' ');
                        return currentText.length > 0;
                    } catch (error) {
                        return false;
                    }
                });

                setComments(currentComments);
            } catch (error) {
                console.error('Error in updateComments:', error);
            }
        };

        updateComments();
        editor.on('update', updateComments);
        editor.on('selectionUpdate', updateComments);
        editor.on('transaction', updateComments);

        return () => {
            editor.off('update', updateComments);
            editor.off('selectionUpdate', updateComments);
            editor.off('transaction', updateComments);
        };
    }, [editor]);

    const handleDelete = (commentId) => {
        if (editor.commands.removeAIComment) {
            editor.commands.removeAIComment(commentId);
            setActiveCommentId(null);
            setHighlightedCommentId(null);
        }
    };

    const handleCommentClick = (commentId) => {
        if (editor.commands.scrollToComment) {
            editor.commands.scrollToComment(commentId);
            setActiveCommentId(commentId);
            setHighlightedCommentId(commentId);

            // Scroll the comment card into view in the sidebar
            const commentElement = commentRefs.current[commentId];
            if (commentElement && commentsContainerRef.current) {
                commentElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }
        }
    };

    // Update toolbar height
    useEffect(() => {
        const updateToolbarHeight = () => {
            const toolbar = document.querySelector('.toolbar-container.pinned');
            setToolbarHeight(toolbar ? toolbar.offsetHeight : 0);
        };

        updateToolbarHeight();
        window.addEventListener('resize', updateToolbarHeight, { passive: true });
        window.addEventListener('touchstart', updateToolbarHeight, { passive: true });

        return () => {
            window.removeEventListener('resize', updateToolbarHeight);
            window.removeEventListener('touchstart', updateToolbarHeight);
        };
    }, []);

    const containerStyle = {
        position: 'sticky',
        top: toolbarHeight ? `${toolbarHeight + 16}px` : '1rem',
        maxHeight: toolbarHeight ?
            `calc(100vh - ${toolbarHeight}px - 2rem)` :
            'calc(100vh - 2rem)',
        overflowY: 'auto',
        zIndex: 40
    };

    if (!comments.length) {
        return (
            <Container ref={commentsContainerRef} style={containerStyle} className="ai-comments-container">
                <Alert variant="light" className="text-center">
                    No comments yet
                </Alert>
            </Container>
        );
    }

    return (
        <Container ref={commentsContainerRef} style={containerStyle} className="ai-comments-container">
            {comments.map(comment => (
                <Card
                    key={comment.id}
                    ref={el => commentRefs.current[comment.id] = el}
                    className={`mb-3 ${comment.id === highlightedCommentId ? 'bg-info bg-opacity-10' : ''}`}
                >
                    <Card.Body className="p-3">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="flex-grow-1">
                                <div className="d-flex align-items-center mb-2">
                                    <Bot size={16} className="text-primary me-2" />
                                    <span className="fw-medium small">AI Suggestion</span>
                                    <CornerUpRight
                                        size={16}
                                        className="ms-2"
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCommentClick(comment.id);
                                        }}
                                        title="Scroll to text"
                                    />
                                </div>
                                <ReactMarkdown className="small text-secondary">
                                    {comment.content}
                                </ReactMarkdown>
                                <div className="mt-2 small text-muted fst-italic">
                                    {new Date(comment.createdAt).toLocaleTimeString()}
                                </div>
                            </div>
                            <X
                                size={16}
                                className="ms-3"
                                style={{
                                    cursor: 'pointer',
                                    color: '#dc3545',
                                    minWidth: '16px',
                                    opacity: 1,
                                    zIndex: 10
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(comment.id);
                                }}
                                title="Delete comment"
                            />
                        </div>
                    </Card.Body>
                </Card>
            ))}
        </Container>
    );
};

export default AICommentsDisplay;