// src/services/mermaidApiService.js
import adminApiConfig from '../config/adminApiConfig';

const BASE_PATH = '/ai/api/v1/mermaid';

const mermaidApi = {
    generateDiagram: async (prompt, diagramType = 'flowchart') => {
        try {
            const response = await fetch(BASE_PATH, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...adminApiConfig.headers
                },
                credentials: 'include',
                body: JSON.stringify({
                    prompt,
                    diagram_type: diagramType
                })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.detail || `Request failed with status ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error generating diagram:', error);
            throw error;
        }
    },

    getDiagramTypes: async () => {
        try {
            const response = await fetch(`${BASE_PATH}/types`, {
                method: 'GET',
                headers: {
                    ...adminApiConfig.headers
                },
                credentials: 'include'
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.detail || `Request failed with status ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching diagram types:', error);
            throw error;
        }
    }
};

export default mermaidApi;