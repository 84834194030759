import { Extension } from '@tiptap/core';

const CustomKeymap = Extension.create({
    name: 'customKeymap',

    addKeyboardShortcuts() {
        return {
            // Undo/Redo
            'Mod-z': () => this.editor.commands.undo(),
            'Mod-y': () => this.editor.commands.redo(),
            'Mod-Shift-z': () => this.editor.commands.redo(),

            // Add any other custom keyboard shortcuts here
            // Example:
            // 'Mod-k': () => this.editor.commands.toggleLink(),
        }
    },
});

export default CustomKeymap;