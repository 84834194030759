import { useCallback } from 'react';
import { formatDate } from '../../utils/dateUtils';
import NEW_JournalApiService from '../../services/NEW_JournalApiService';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const getImageDimensions = (file) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            resolve({ width: img.naturalWidth, height: img.naturalHeight });
        };
        img.src = URL.createObjectURL(file);
    });
};

const convertToJpg = (file) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');

            if (file.type === 'image/webp') {
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
            }

            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob) => {
                if (blob) {
                    const originalExt = file.name.split('.').pop();
                    const jpgFile = new File([blob], file.name.replace(`.${originalExt}`, '.jpg'), {
                        type: 'image/jpeg',
                        lastModified: new Date().getTime()
                    });
                    resolve(jpgFile);
                } else {
                    reject(new Error('Conversion failed'));
                }
            }, 'image/jpeg', 0.9);
        };
        img.onerror = () => reject(new Error('Failed to load image'));
        img.src = URL.createObjectURL(file);
    });
};

export const useImageUpload = (editor, user, recentlyAddedImages, imageKeyToFilename) => {
    const processAndUploadImage = async (file) => {
        let tempUrl;
        const imageId = uuidv4();

        try {
            const { width, height } = await getImageDimensions(file);
            tempUrl = URL.createObjectURL(file);

            const date = formatDate(new Date());
            const username = user.username;

            editor.commands.focus();
            editor
                .chain()
                .setImage({
                    src: tempUrl,
                    'data-filename': file.name,
                    'data-id': imageId,
                    'data-username': username,
                    'data-date': date,
                    width,
                    height,
                    'data-just-uploaded': 'true',
                })
                .run();

            const uploadResponse = await NEW_JournalApiService.uploadImage(
                username,
                date,
                file
            );
            const imageKey = uploadResponse.key;

            const { doc } = editor.state;
            let imageNodePos = null;
            doc.descendants((node, pos) => {
                if (node.type.name === 'image' && node.attrs['data-id'] === imageId) {
                    imageNodePos = pos;
                    return false;
                }
                return true;
            });

            if (imageNodePos !== null) {
                const node = doc.nodeAt(imageNodePos);
                if (node) {
                    const transaction = editor.state.tr.setNodeMarkup(
                        imageNodePos,
                        undefined,
                        {
                            ...node.attrs,
                            src: imageKey,
                            'data-filename': file.name,
                            'data-id': imageId,
                            'data-username': username,
                            'data-date': date,
                            width,
                            height,
                            'data-just-uploaded': 'true',
                        }
                    );
                    editor.view.dispatch(transaction);

                    recentlyAddedImages.current.add(imageKey);
                    setTimeout(() => recentlyAddedImages.current.delete(imageKey), 5000);
                    imageKeyToFilename.current.set(imageKey, file.name);
                }
            }

            URL.revokeObjectURL(tempUrl);
        } catch (error) {
            console.error('Error handling image:', error);

            const { doc } = editor.state;
            let imageNodePos = null;
            doc.descendants((node, pos) => {
                if (node.type.name === 'image' && node.attrs['data-id'] === imageId) {
                    imageNodePos = pos;
                    return false;
                }
                return true;
            });

            if (imageNodePos !== null) {
                const node = doc.nodeAt(imageNodePos);
                if (node) {
                    const transaction = editor.state.tr.delete(
                        imageNodePos,
                        imageNodePos + node.nodeSize
                    );
                    editor.view.dispatch(transaction);
                }
            }

            if (tempUrl) {
                URL.revokeObjectURL(tempUrl);
            }

            toast.error(`Image upload failed: ${error.message}`, {
                autoClose: false,
                closeOnClick: true
            });
        }
    };

    const handlePaste = useCallback(async (event) => {
        const clipboardItems = event.clipboardData?.items;
        if (!clipboardItems || !editor || !user?.username) return;

        for (const item of clipboardItems) {
            if (item.type.startsWith('image/')) {
                event.preventDefault();
                const file = item.getAsFile();
                if (!file) continue;

                if (!['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(file.type)) {
                    toast.error(`${file.name} is not a supported image type. Only JPG, PNG, and WebP files are allowed.`);
                    continue;
                }

                if (file.type === 'image/png' || file.type === 'image/webp') {
                    const formatName = file.type === 'image/png' ? 'PNG' : 'WebP';
                    const shouldConvert = window.confirm(
                        `${file.name} is a ${formatName} file. Would you like to convert it to JPG before uploading?...`
                    );
                    if (shouldConvert) {
                        try {
                            const jpgFile = await convertToJpg(file);
                            await processAndUploadImage(jpgFile);
                        } catch (error) {
                            console.error('Conversion error:', error);
                            toast.error(`Failed to convert ${file.name} to JPG: ${error.message}`);
                        }
                    } else {
                        const shouldUploadOriginal = window.confirm(`Do you want to upload ${file.name} as ${formatName}?`);
                        if (shouldUploadOriginal) {
                            await processAndUploadImage(file);
                        }
                    }
                } else {
                    await processAndUploadImage(file);
                }
            }
        }
    }, [editor, user]);

    const handleDrop = async (event) => {
        event.preventDefault();

        const files = Array.from(event.dataTransfer.files).filter((file) =>
            file.type.startsWith('image/')
        );

        if (!editor || !user || !user.username) {
            console.error('Editor is not initialized or user is not authenticated');
            toast.error('Editor is not initialized or you are not authenticated.', {
                autoClose: false,
                closeOnClick: true
            });
            return;
        }

        for (const file of files) {
            if (!['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(file.type)) {
                toast.error(`${file.name} is not a supported image type. Only JPG, PNG, and WebP files are allowed.`);
                continue;
            }

            if (file.type === 'image/png' || file.type === 'image/webp') {
                const formatName = file.type === 'image/png' ? 'PNG' : 'WebP';
                const shouldConvert = window.confirm(`${file.name} is a ${formatName} file. Would you like to convert it to JPG before uploading`);

                if (shouldConvert) {
                    try {
                        const jpgFile = await convertToJpg(file);
                        await processAndUploadImage(jpgFile);
                        toast.success(`${file.name} converted to JPG and uploaded successfully`, {
                            autoClose: 2000,
                            closeOnClick: true
                        });
                    } catch (error) {
                        console.error('Conversion error:', error);
                        toast.error(`Failed to convert ${file.name} to JPG: ${error.message}`, {
                            autoClose: false,
                            closeOnClick: true
                        });
                    }
                } else {
                    const shouldUploadOriginal = window.confirm(`Do you want to upload ${file.name} as ${formatName}?`);
                    if (shouldUploadOriginal) {
                        await processAndUploadImage(file);
                    } else {
                        toast.info(`Upload of ${file.name} cancelled`, {
                            autoClose: 1000,
                            closeOnClick: true
                        });
                    }
                }
            } else {
                await processAndUploadImage(file);
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    return {
        handlePaste,
        handleDrop,
        handleDragOver
    };
};