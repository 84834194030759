// EditorToolbar.jsx
import { useState, useEffect } from 'react';
import { useEditor } from '../EditorContext';
import { toolbarConfig } from './toolbarConfig';
import ToolbarButton from './ToolbarButton';
import ToolbarDropdown from './ToolbarDropdown';
import ToolbarColorPicker from './ToolbarColorPicker';
import TableSubmenu from './TableSubmenu';
import AIToolbarMenu from '../Toolbar/AIToolbarMenu'

const EditorToolbar = () => {
    const editor = useEditor();
    const [currentColor, setCurrentColor] = useState('#000000');
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    return (
        <div className="tiptap-toolbar" role="toolbar" aria-label="Text formatting options">
            <div className="d-flex flex-wrap">
                {toolbarConfig.map((item) => {
                    if (item.type === 'button') {
                        if (item.label === 'Table') {
                            return <TableSubmenu key={item.label} item={item} editor={editor} isMobile={isMobile} />;
                        }
                        return <ToolbarButton key={item.label} item={item} editor={editor} />;
                    } else if (item.type === 'dropdown') {
                        return <ToolbarDropdown key={item.label} item={item} editor={editor} />;
                    } else if (item.type === 'colorPicker') {
                        return (
                            <ToolbarColorPicker
                                key={item.label}
                                editor={editor}
                                currentColor={currentColor}
                                setCurrentColor={setCurrentColor}
                            />
                        );
                    }
                    return null;
                })}
                <AIToolbarMenu />
            </div>
        </div>
    );
};

export default EditorToolbar;
