import React, { useState, useCallback, useRef, useEffect } from 'react';
import { BotMessageSquare, X, Loader, MicIcon, MicOff } from 'lucide-react';
import { Button, Form, Card, Container, Row, Col } from 'react-bootstrap';
import assistantApi from '../../../services/assistantApiService';
import { useAuth } from '../../../contexts/AuthContext';
import ReactMarkdown from 'react-markdown';

const Assistant = ({ handleDateClick, reloadContent }) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const messagesEndRef = useRef(null);
    const { user } = useAuth();

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSendMessage = async () => {
        if (!inputMessage.trim() || isLoading) return;

        try {
            setIsLoading(true);
            setMessages(prev => [...prev, {
                role: 'user',
                content: inputMessage.trim()
            }]);
            setInputMessage('');

            // Send via API service
            const data = await assistantApi.sendMessage(inputMessage.trim(), user.username);

            // Add assistant response to messages
            setMessages(prev => [...prev, {
                role: 'assistant',
                content: data.response
            }]);

            // Parse date and trigger both date change and content reload
            if (data.response.includes('Journal Entry for')) {
                const dateMatch = data.response.match(/Journal Entry for (\d{4}-\d{2}-\d{2})/);
                if (dateMatch && dateMatch[1]) {
                    const date = new Date(dateMatch[1]);
                    handleDateClick(date);  // Navigate to the date
                    await reloadContent(date);  // Force content reload
                }
            }

        } catch (error) {
            console.error(error);
            setMessages(prev => [...prev, {
                role: 'assistant',
                content: 'Sorry, I encountered an error.'
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    return (
        <Container fluid className="d-flex flex-column h-100 p-0">
            <div className="flex-grow-1 overflow-auto p-3">
                {messages.map((message, index) => (
                    <Row key={index} className="mb-3">
                        <Col
                            className={message.role === 'user' ? 'ms-auto' : ''}
                            xs="auto"
                            style={{ maxWidth: '80%' }}
                        >
                            <Card className={`border-0 ${message.role === 'user' ? 'bg-primary bg-opacity-10' : 'bg-light'
                                }`}>
                                <Card.Body className="p-2">
                                    <small className="fw-medium d-block mb-1">
                                        {message.role === 'user' ? 'You' : 'Assistant'}
                                    </small>
                                    <div className="markdown-content">
                                        <ReactMarkdown
                                            components={{
                                                h1: ({ node, ...props }) => <h1 style={{ fontSize: '1.2rem' }} {...props} />
                                            }}
                                        >
                                            {message.content}
                                        </ReactMarkdown>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                ))}
                <div ref={messagesEndRef} />

                {error && (
                    <Row className="justify-content-center mb-3">
                        <Col xs="auto">
                            <div className="text-danger bg-danger bg-opacity-10 p-2 rounded">
                                {error}
                            </div>
                        </Col>
                    </Row>
                )}
            </div>

            <div className="border-top p-3">
                <Row className="g-2">
                    <Col>
                        <Form.Control
                            as="textarea"
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            onKeyDown={handleKeyPress}
                            placeholder="Type your message..."
                            disabled={isLoading}
                            rows={1}
                        />
                    </Col>
                    <Col xs="auto">
                        <Button
                            variant="primary"
                            onClick={handleSendMessage}
                            disabled={isLoading || !inputMessage.trim()}
                            style={{ minWidth: '80px' }}
                        >
                            {isLoading ? (
                                <Loader className="spinner-border spinner-border-sm" size={16} />
                            ) : (
                                'Send'
                            )}
                        </Button>
                        <MicOff className='ms-2' />
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

const AssistantWrapper = ({ handleDateClick, reloadContent }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [cardHeight, setCardHeight] = useState(500);
    const [isDragging, setIsDragging] = useState(false);
    const [initialY, setInitialY] = useState(0);
    const [initialHeight, setInitialHeight] = useState(0);

    const handleMouseDown = (e) => {
        setInitialY(e.clientY);
        setInitialHeight(cardHeight);
        setIsDragging(true);
        e.preventDefault();
    };

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (isDragging) {
                const deltaY = initialY - e.clientY; // Reversed the calculation
                const newHeight = Math.max(300, Math.min(800, initialHeight + deltaY));
                setCardHeight(newHeight);
            }
        };

        const handleMouseUp = () => {
            setIsDragging(false);
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, initialHeight, initialY]);

    return (
        <>
            <Button
                variant="outline-secondary"
                className="d-flex align-items-center border-0"
                style={{ height: "20px" }}
                onClick={() => setIsOpen(!isOpen)}
                aria-label="AI Chat"
            >
                <BotMessageSquare />
                <span style={{ fontSize: "0.8rem" }}></span>
            </Button>

            {isOpen && (
                <div className="position-fixed bottom-0 end-0 mb-5 me-4">
                    <Card style={{ width: '72rem', height: `${cardHeight}px` }} className="shadow">
                        {/* Resize handle moved to top */}
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '4px',
                                cursor: 'ns-resize',
                                backgroundColor: 'transparent',
                                zIndex: 10 // Ensure handle is above other elements
                            }}
                            onMouseDown={handleMouseDown}
                            className="hover-visible"
                        />
                        <Card.Header className="d-flex justify-content-between align-items-center py-2">
                            <span className="fw-semibold">TaskPlexer</span>
                            <Button
                                variant="link"
                                className="p-0 text-body"
                                onClick={() => setIsOpen(false)}
                            >
                                <X size={16} />
                            </Button>
                        </Card.Header>
                        <Card.Body className="p-0" style={{ height: `calc(${cardHeight}px - 40px)` }}>
                            <Assistant
                                handleDateClick={handleDateClick}
                                reloadContent={reloadContent}
                            />
                        </Card.Body>
                    </Card>
                </div>
            )}
        </>
    );
};

export default AssistantWrapper;