import React, { useRef, useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import {
    ChevronDown, ChevronUp, Calendar,
    GripVertical, AlertCircle, Trash2,
    Link, Pencil, Check, X, Eye, Users
} from 'lucide-react';
import {
    Card, Form, Button, Badge,
    Row, Col, ProgressBar, InputGroup
} from 'react-bootstrap';
import {
    getStatusStyles,
    getPriorityBadge,
    formatDate,
    isDueWarning,
    isOverdue,
    ClickableUrls,
    getStatusOptions,
    getPriorityOptions,
    createMarkdownLink,
    handleInsertLink
} from './taskDisplayUtils';
import ProjectApiService from '../../services/projectApiService';
import IntegrationApiService from '../../services/IntegrationApiService';

const DescriptionDisplay = ({ text }) => (
    <div className="prose max-w-none p-3 bg-gray-50 rounded">
        <ClickableUrls text={text || 'No description provided.'} />
    </div>
);

const DescriptionEditor = ({ text, onSave, onCancel }) => {
    const descriptionRef = useRef(null);
    const [editedText, setEditedText] = useState(text);

    const handleLinkInsert = async () => {
        const textarea = descriptionRef.current;
        if (textarea) {
            const { selectionStart, selectionEnd } = textarea;
            const cursorPosition = await handleInsertLink(
                editedText,
                selectionStart,
                selectionEnd,
                setEditedText
            );
            textarea.focus();
            if (cursorPosition) {
                textarea.setSelectionRange(cursorPosition, cursorPosition);
            }
        }
    };

    return (
        <div>
            <Form.Control
                as="textarea"
                rows={3}
                value={editedText}
                onChange={(e) => setEditedText(e.target.value)}
                ref={descriptionRef}
            />
            <div className="mt-2 flex justify-between items-center">
                <Button
                    variant="outline-secondary"
                    size="sm"
                    className='mb-2'
                    onClick={handleLinkInsert}
                >
                    <Link size={16} className="mr-1" />
                    Add Link
                </Button>
                <div className="flex gap-2">
                    <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={onCancel}
                    >
                        <X size={16} className="mr-1" />
                        Cancel
                    </Button>
                    <Button
                        variant="outline-success"
                        size="sm"
                        className='ms-2'
                        onClick={() => onSave(editedText)}
                    >
                        <Check size={16} className="mr-1" />
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

const TaskCard = ({
    task,
    updateTask,
    onDeleteClick,
    toggleSubtask,
    addSubtask,
    allTasks,
    dragHandleProps,
    formatDate,
    project
}) => {
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [sharingInfo, setSharingInfo] = useState([]);
    const { user } = useAuth();

    // Load sharing info when component mounts
    useEffect(() => {
        const loadSharingInfo = async () => {
            try {
                const info = await ProjectApiService.getProjectSharingInfo(
                    project.owner || user.username,
                    project.name
                );
                setSharingInfo(info);
            } catch (error) {
                console.error('Failed to load sharing information:', error);
            }
        };

        if (project) {
            loadSharingInfo();
        }
    }, [project, user?.username]);

    const deleteSubtask = (taskId, subtaskId) => {
        const subtaskToDelete = task.subtasks.find(subtask => subtask.id === subtaskId);
        if (subtaskToDelete && subtaskToDelete.completed) {
            toggleSubtask(taskId, subtaskId);
        }
        const updatedSubtasks = task.subtasks.filter(subtask => subtask.id !== subtaskId);
        updateTask(taskId, { subtasks: updatedSubtasks });
    };

    return (
        <Card>
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="d-flex align-items-center">
                        <div {...dragHandleProps} className="me-2">
                            <GripVertical size={20} className="text-muted" />
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <Form.Control
                                value={task.title}
                                onChange={(e) => updateTask(task.id, { title: e.target.value })}
                                style={{ border: 'none', background: 'none', padding: 0 }}
                            />
                            {task.startDate && (
                                <span className="text-muted">
                                    <Calendar size={14} className="me-1" />
                                    {formatDate(task.startDate)}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        {isDueWarning(task) && (
                            <AlertCircle size={20} className="text-warning" />
                        )}
                        {isOverdue(task) && (
                            <AlertCircle size={20} className="text-danger" />
                        )}
                        {task.assignedTo && (
                            <Badge bg="info">
                                <Users size={14} className="me-1" />
                                {task.assignedTo}
                            </Badge>
                        )}
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => onDeleteClick(task.id)}
                        >
                            <Trash2 size={16} />
                        </Button>
                        <Button
                            variant="light"
                            size="sm"
                            onClick={() => updateTask(task.id, { expanded: !task.expanded })}
                        >
                            {task.expanded ? <ChevronUp /> : <ChevronDown />}
                        </Button>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-2 align-items-center">
                        <Badge bg={getStatusStyles(task.status).color.replace('var(--bs-', '').replace(')', '')}>
                            {task.status}
                        </Badge>
                        {getPriorityBadge(task.priority)}
                        {task.progress > 0 && (
                            <ProgressBar
                                now={task.progress}
                                label={`${task.progress}%`}
                                style={{ width: '100px', height: '10px' }}
                            />
                        )}
                    </div>
                </div>
            </Card.Header>

            {task.expanded && (
                <Card.Body>
                    <Form>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={task.startDate || ''}
                                        onChange={(e) => updateTask(task.id, { startDate: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={task.endDate || ''}
                                        onChange={(e) => updateTask(task.id, { endDate: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        value={task.status}
                                        onChange={(e) => updateTask(task.id, { status: e.target.value })}
                                    >
                                        {getStatusOptions().map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Priority</Form.Label>
                                    <Form.Select
                                        value={task.priority}
                                        onChange={(e) => updateTask(task.id, { priority: e.target.value })}
                                    >
                                        {getPriorityOptions().map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* New Assign To dropdown */}
                        <Row className="mb-3">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Assign To</Form.Label>
                                    <Form.Select
                                        value={task.assignedTo || ''}
                                        onChange={async (e) => {
                                            const targetUsername = e.target.value;
                                            // Update local task state
                                            updateTask(task.id, { assignedTo: targetUsername });

                                            // If someone is assigned, create a task assignment
                                            if (targetUsername) {
                                                try {
                                                    await IntegrationApiService.assignTask({
                                                        requesting_username: user.username,
                                                        target_username: targetUsername,
                                                        project_name: project.name,
                                                        task_content: task.title,
                                                        entry_date: new Date(),
                                                        assigned_date: task.endDate
                                                    });
                                                } catch (error) {
                                                    console.error('Failed to assign task:', error);
                                                }
                                            }
                                        }}
                                    >
                                        <option value="">Not assigned</option>
                                        <option value={user.username}>{user.username}</option>
                                        {sharingInfo.map((share) => (
                                            <option
                                                key={share.shared_with_username}
                                                value={share.shared_with_username}
                                            >
                                                {share.shared_with_username}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* Description section */}
                        <Form.Group className="mb-3">
                            <div className="flex justify-between items-center mb-2">
                                <Form.Label className="mb-0">Description</Form.Label>
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    className='ms-3 border-0'
                                    onClick={() => setIsEditingDescription(!isEditingDescription)}
                                >
                                    {isEditingDescription ? (
                                        <>
                                            <Eye size={16} className="mr-1" />
                                            View
                                        </>
                                    ) : (
                                        <>
                                            <Pencil size={16} className="mr-1" />
                                            Edit
                                        </>
                                    )}
                                </Button>
                            </div>
                            <div>
                                {isEditingDescription ? (
                                    <DescriptionEditor
                                        text={task.description}
                                        onSave={(newText) => {
                                            updateTask(task.id, { description: newText });
                                            setIsEditingDescription(false);
                                        }}
                                        onCancel={() => setIsEditingDescription(false)}
                                    />
                                ) : (
                                    <DescriptionDisplay text={task.description} />
                                )}
                            </div>
                        </Form.Group>

                        {/* Subtasks */}
                        <div className="mb-3">
                            <Form.Label>Subtasks</Form.Label>
                            {task.subtasks.map(subtask => (
                                <div key={subtask.id} className="d-flex align-items-center mb-2 gap-2">
                                    <Form.Check
                                        type="checkbox"
                                        checked={subtask.completed}
                                        onChange={() => toggleSubtask(task.id, subtask.id)}
                                        label={subtask.title}
                                    />
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        className="ms-auto p-1"
                                        onClick={() => deleteSubtask(task.id, subtask.id)}
                                    >
                                        <Trash2 size={14} />
                                    </Button>
                                </div>
                            ))}
                            <InputGroup size="sm">
                                <Form.Control
                                    placeholder="Add subtask..."
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            addSubtask(task.id, e.target.value);
                                            e.target.value = '';
                                        }
                                    }}
                                />
                            </InputGroup>
                        </div>

                        {/* Dependencies */}
                        <Form.Group className="mb-3">
                            <Form.Label>Dependencies</Form.Label>
                            <div className="mb-2">
                                {task.dependencies?.map(depId => {
                                    const dependencyTask = allTasks.find(t => t.id === depId);
                                    return dependencyTask ? (
                                        <Badge
                                            key={depId}
                                            bg="light"
                                            text="dark"
                                            className="me-2 mb-2"
                                            style={{ display: 'inline-flex', alignItems: 'center' }}
                                        >
                                            {dependencyTask.title}
                                            <Button
                                                variant="link"
                                                size="sm"
                                                className="p-0 ms-2"
                                                onClick={() => {
                                                    const newDependencies = task.dependencies.filter(id => id !== depId);
                                                    updateTask(task.id, { dependencies: newDependencies });
                                                }}
                                            >
                                                ×
                                            </Button>
                                        </Badge>
                                    ) : null;
                                })}
                            </div>
                            <Form.Select
                                value=""
                                onChange={(e) => {
                                    if (e.target.value) {
                                        const newDependencies = [...(task.dependencies || [])];
                                        if (!newDependencies.includes(e.target.value)) {
                                            newDependencies.push(e.target.value);
                                            updateTask(task.id, { dependencies: newDependencies });
                                        }
                                        e.target.value = '';
                                    }
                                }}
                            >
                                <option value="">Add dependency...</option>
                                {allTasks
                                    .filter(t => (
                                        t.id !== task.id &&
                                        !task.dependencies?.includes(t.id)
                                    ))
                                    .map(t => (
                                        <option key={t.id} value={t.id}>
                                            {t.title}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Card.Body>
            )}
        </Card>
    );
};

export default TaskCard;