// CommentContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';

const CommentContext = createContext();

export function CommentProvider({ children }) {
    const [highlightedCommentId, setHighlightedCommentId] = useState(null);

    // Log state changes
    useEffect(() => {
    }, [highlightedCommentId]);

    const value = {
        highlightedCommentId,
        setHighlightedCommentId: (id) => {
            setHighlightedCommentId(id);
        }
    };


    return (
        <CommentContext.Provider value={value}>
            {children}
        </CommentContext.Provider>
    );
}

export function useComment() {
    const context = useContext(CommentContext);
    if (context === undefined) {
        throw new Error('useComment must be used within a CommentProvider');
    }
    return context;
}