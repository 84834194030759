// LeftSidebar.jsx
import React, { useMemo, memo } from 'react';
import { Button, Nav, Badge } from 'react-bootstrap';
import SearchInput from './SearchInput';
import ConversationItem from './ConversationItem';

// Memoize the TagBadge component to prevent unnecessary re-renders
const TagBadge = memo(({ tag, onRemove, isFilter = false }) => (
    <Badge
        bg="primary"
        className="me-1 mb-1"
        style={{ cursor: isFilter ? 'pointer' : 'default' }}
    >
        {tag}
        {onRemove && (
            <Button
                variant="link"
                size="sm"
                className="text-white p-0 ms-1"
                onClick={() => onRemove(tag)}
            >
                ×
            </Button>
        )}
    </Badge>
));

TagBadge.displayName = 'TagBadge';

// Memoize the ConversationList component to prevent unnecessary re-renders
const ConversationList = memo(({
    sortedConversations,
    chat,
    onDelete,
    onRename
}) => (
    <Nav className="flex-column p-3">
        {sortedConversations.map(conv => (
            <ConversationItem
                key={conv.id}
                conv={conv}
                chat={chat}
                onDelete={onDelete}
                onRename={onRename}
            />
        ))}
    </Nav>
));

ConversationList.displayName = 'ConversationList';

const LeftSidebar = ({
    searchQuery,
    setSearchQuery,
    tags,
    filteredConversations,
    chat,
    handleDeleteConversation,
    handleRenameConversation
}) => {
    // Sort conversations by updatedAt in descending order (newest first)
    const sortedConversations = useMemo(() => {
        const conversations = filteredConversations || chat.conversations;
        return [...conversations].sort((a, b) => {
            const dateA = new Date(a.updatedAt);
            const dateB = new Date(b.updatedAt);
            return dateB - dateA;
        });
    }, [filteredConversations, chat.conversations]);

    return (
        <div className="d-flex flex-column h-100">
            {/* Fixed header section */}
            <div className="p-3" style={{ flex: '0 0 auto' }}>
                <SearchInput
                    value={searchQuery}
                    onChange={setSearchQuery}
                    onClear={() => setSearchQuery('')}
                    className="mb-3"
                />

                <div className="mb-3">
                    <small className="text-muted">Tags</small>
                    <div className="mt-2">
                        {tags.map(tag => (
                            <TagBadge
                                key={tag}
                                tag={tag}
                                isFilter={true}
                                onRemove={null}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/* Scrollable conversation list */}
            <div className="overflow-y-auto flex-grow-1">
                <ConversationList
                    sortedConversations={sortedConversations}
                    chat={chat}
                    onDelete={handleDeleteConversation}
                    onRename={handleRenameConversation}
                />
            </div>
        </div>
    );
};

export default LeftSidebar;