import React, { useState, useEffect } from 'react';
import aiApiService from '../../../services/aiApiService';

const TaskSummary = ({ tasks, isGenerating }) => {
    const [summary, setSummary] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const generateSummary = async () => {
            // Only generate summary when we have tasks and the main loading is complete
            if (tasks.length === 0 || isGenerating) return;

            setLoading(true);
            try {
                // Format tasks into a readable format for the AI
                const tasksText = tasks.map(task =>
                    `- ${task.text} (${task.context ? `Context: ${task.context}, ` : ''}Date: ${new Date(task.date).toLocaleDateString()})`
                ).join('\n');

                const prompt = `Here are my completed tasks:\n${tasksText}\n\nPlease provide a brief summary of my accomplishments and any patterns you notice in my completed tasks. Keep it to 2-3 sentences.`;

                const response = await aiApiService.generateChatResponse({
                    messages: [{ role: 'user', content: prompt }],
                    model_id: 49,  // Using the numeric model ID instead of string identifier
                    temperature: 0.7
                });

                setSummary(response.message.content);
            } catch (error) {
                console.error('Error generating summary:', error);
                setSummary('Unable to generate summary at this time.');
            } finally {
                setLoading(false);
            }
        };

        generateSummary();
    }, [tasks, isGenerating]); // Added isGenerating to dependencies

    if (!tasks.length) return null;

    return (
        <div className="mt-4 p-3 bg-light rounded">
            <h6 className="mb-2">Summary</h6>
            {loading || isGenerating ? (
                <div className="d-flex align-items-center text-muted">
                    <div className="spinner-border spinner-border-sm me-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    {isGenerating ? 'Waiting for all tasks to load...' : 'Generating summary...'}
                </div>
            ) : (
                <p className="mb-0">{summary}</p>
            )}
        </div>
    );
};

const TaskList = ({ tasks, selectedDate, onClearSelection, loading }) => {
    return (
        <div className="flex-grow-1 mt-4 position-relative">
            <div className="position-absolute top-0 bottom-0 start-0 end-0 overflow-auto">
                {selectedDate && (
                    <div className="d-flex justify-content-between align-items-center mb-3 sticky-top bg-white py-2">
                        <h6 className="mb-0">
                            Tasks for {new Date(selectedDate).toLocaleDateString()}
                        </h6>
                        <button
                            onClick={onClearSelection}
                            className="btn btn-outline-primary border-0 p-1"
                        >
                            Show all tasks
                        </button>
                    </div>
                )}

                {tasks.length > 0 ? (
                    <>
                        <TaskSummary tasks={tasks} isGenerating={loading} />
                        <div className="list-group mt-4">
                            {tasks.map((task, index) => (
                                <div
                                    key={index}
                                    className="list-group-item py-3"
                                >
                                    <div className="d-flex justify-content-between align-items-start">
                                        <div>
                                            <div className="fw-bold">{task.text}</div>
                                            {task.context && (
                                                <small className="text-muted d-block">
                                                    Context: {task.context}
                                                </small>
                                            )}
                                        </div>
                                        <small className="text-muted">
                                            {new Date(task.date).toLocaleDateString()}
                                        </small>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    !loading && (
                        <div className="text-center text-muted">
                            Select a date range and click Search to view completed tasks
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default TaskList;