// src/hooks/useChatHistory.jsx
import { useState, useCallback, useEffect, useRef } from 'react';

const useChatHistory = ({
  maxTokens = 4000,
  summaryThreshold = 0.8,
  aiApi = null,
  selectedModel = null,
  temperature = 0.1
} = {}) => {
  const [contextSummary, setContextSummary] = useState(null);
  const [conversations, setConversations] = useState([]);
  const dbRef = useRef(null);

  // Initialize IndexedDB
  useEffect(() => {
    if (dbRef.current) return;

    const openDB = () => {
      const request = window.indexedDB.open('chatHistoryDB', 1);

      request.onerror = (event) => {
        console.error('IndexedDB error:', event.target.error);
      };

      request.onsuccess = (event) => {
        dbRef.current = event.target.result;
        loadConversations();
      };

      request.onupgradeneeded = (event) => {
        const database = event.target.result;
        if (!database.objectStoreNames.contains('conversations')) {
          database.createObjectStore('conversations', { keyPath: 'id' });
        }
      };
    };

    openDB();
  }, []);

  const loadConversations = useCallback(() => {
    if (!dbRef.current) return;

    const transaction = dbRef.current.transaction('conversations', 'readonly');
    const store = transaction.objectStore('conversations');
    const request = store.getAll();

    request.onsuccess = (event) => {
      if (event.target.result) {
        setConversations(event.target.result);
      }
    };

    request.onerror = (event) => {
      console.error('Error loading conversations from IndexedDB:', event.target.error);
    };
  }, []);

  // Save conversations to IndexedDB
  const saveConversation = useCallback((conversation) => {
    if (!dbRef.current || !conversation) return;

    const transaction = dbRef.current.transaction('conversations', 'readwrite');
    const store = transaction.objectStore('conversations');

    // Update conversations state
    setConversations(prev => {
      const existingIndex = prev.findIndex(conv => conv.id === conversation.id);
      if (existingIndex >= 0) {
        const updated = [...prev];
        updated[existingIndex] = {
          ...conversation,
          updatedAt: new Date().toISOString()
        };
        return updated;
      }
      return [...prev, {
        ...conversation,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }];
    });

    // Save to IndexedDB
    store.put(conversation);

    transaction.oncomplete = () => {
    };

    transaction.onerror = (event) => {
      console.error('Error saving conversations to IndexedDB:', event.target.error);
    };
  }, []);

  const getConversation = useCallback((conversationId) => {
    return conversations.find(conv => conv.id === conversationId);
  }, [conversations]);

  const updateConversationTitle = useCallback((conversationId, newTitle) => {
    if (!conversationId || !newTitle?.trim()) return;

    const conversation = getConversation(conversationId);
    if (conversation) {
      saveConversation({
        ...conversation,
        title: newTitle.trim(),
        updatedAt: new Date().toISOString()
      });
    }
  }, [getConversation, saveConversation]);

  const deleteConversation = useCallback(async (conversationId) => {
    if (!dbRef.current || !conversationId) return;

    try {
      const transaction = dbRef.current.transaction('conversations', 'readwrite');
      const store = transaction.objectStore('conversations');

      await new Promise((resolve, reject) => {
        const request = store.delete(conversationId);
        request.onsuccess = () => {
          setConversations(prev => prev.filter(conv => conv.id !== conversationId));
          resolve();
        };
        request.onerror = () => reject(request.error);
      });
    } catch (error) {
      console.error('Error deleting conversation:', error);
      throw error;
    }
  }, []);

  const createBranch = useCallback((parentConversationId) => {
    if (!parentConversationId) return null;

    const parentConversation = getConversation(parentConversationId);
    if (!parentConversation) return null;

    const branchedConversation = {
      ...parentConversation,
      id: Date.now().toString(),
      threadId: crypto.randomUUID(),
      parentThreadId: parentConversation.threadId,
      title: `${parentConversation.title || 'New Chat'} (Branch)`,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    saveConversation(branchedConversation);
    return branchedConversation.id;
  }, [getConversation, saveConversation]);

  const searchConversations = useCallback((query) => {
    if (!query?.trim()) return conversations;

    const searchTerms = query.toLowerCase().trim().split(/\s+/);

    return conversations.filter(conversation => {
      // Search in title
      const titleMatch = conversation.title?.toLowerCase().includes(query.toLowerCase());
      if (titleMatch) return true;

      // Search in messages
      const messagesMatch = conversation.messages?.some(message => {
        const content = message.content?.toLowerCase() || '';
        return searchTerms.every(term => content.includes(term));
      });

      return messagesMatch;
    });
  }, [conversations]);

  return {
    conversations,
    contextSummary,
    saveConversation,
    deleteConversation,
    getConversation,
    updateConversationTitle,
    createBranch,
    searchConversations
  };
};

export default useChatHistory;