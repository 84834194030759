// extensions/MermaidSuggestion.js
import { Extension } from '@tiptap/core'
import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'
import Suggestion from '@tiptap/suggestion'
import React, { useState } from 'react'
import '../../../assets/css/memaid.css'
import mermaidApi from '../../../services/mermaidApiService'

import { Form, Button, Spinner } from 'react-bootstrap'

const MermaidForm = ({ diagramTypes, onSubmit, onCancel }) => {
    const [diagramType, setDiagramType] = useState(diagramTypes[0])
    const [prompt, setPrompt] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const response = await mermaidApi.generateDiagram(prompt, diagramType);
            onSubmit({
                content: response.mermaid_code,
                diagramType,
                prompt: prompt,
                lastUpdated: new Date().toISOString(),
            });
        } catch (error) {
            console.error('Failed to generate diagram:', error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Select
                    value={diagramType}
                    onChange={(e) => setDiagramType(e.target.value)}
                >
                    {diagramTypes.map((type) => (
                        <option key={type} value={type}>
                            {type}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    placeholder="Describe your diagram..."
                    style={{ resize: "both", minHeight: "80px", minWidth: "200px" }}
                />
            </Form.Group>
            <div className="d-flex gap-2 justify-content-end">
                <Button
                    variant="secondary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    disabled={isLoading || !prompt.trim()}
                >
                    {isLoading ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                            Generating...
                        </>
                    ) : (
                        'Generate'
                    )}
                </Button>
            </div>
        </Form>
    )
}

const MermaidCommandsList = ({ items, command }) => {
    const [showForm, setShowForm] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    const handleSelectItem = (item) => {
        setSelectedItem(item)
        setShowForm(true)
    }

    const handleSubmit = (props) => {
        command(props)
    }

    const handleCancel = () => {
        setShowForm(false)
        setSelectedItem(null)
    }

    if (showForm && selectedItem) {
        return (
            <MermaidForm
                diagramTypes={selectedItem.diagramTypes}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            />
        )
    }

    return (
        <div className="mermaid-suggestions">
            {items.map((item, index) => (
                <button
                    className="suggestion-item"
                    key={index}
                    onClick={() => handleSelectItem(item)}
                >
                    {item.title}
                </button>
            ))}
        </div>
    )
}

export const MermaidSuggestion = Extension.create({
    name: 'mermaidSuggestion',

    addOptions() {
        return {
            suggestion: {
                char: '/',
                command: ({ editor, range, props }) => {
                    editor
                        .chain()
                        .focus()
                        .deleteRange(range)
                        .insertMermaid({
                            content: props.content,
                            diagramType: props.diagramType,
                            prompt: props.prompt,
                            lastUpdated: props.lastUpdated
                        })
                        .run();
                },
            },
        };
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                ...this.options.suggestion,
                items: ({ query }) => {
                    if (query.toLowerCase().startsWith('mermaid')) {
                        return [{
                            title: 'Insert Mermaid Diagram',
                            diagramTypes: ['flowchart', 'sequence', 'gantt', 'class', 'state'],
                        }]
                    }
                    return []
                },
                render: () => {
                    let component
                    let popup

                    return {
                        onStart: (props) => {
                            component = new ReactRenderer(MermaidCommandsList, {
                                props,
                                editor: props.editor,
                            })

                            if (!props.clientRect) {
                                return
                            }

                            popup = tippy('body', {
                                getReferenceClientRect: () => props.clientRect(),
                                appendTo: () => document.body,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: 'manual',
                                placement: 'bottom-start',
                            })[0]
                        },

                        onUpdate(props) {
                            component?.updateProps(props)

                            if (!props.clientRect) {
                                return
                            }

                            popup?.setProps({
                                getReferenceClientRect: () => props.clientRect(),
                            })
                        },

                        onKeyDown(props) {
                            if (props.event.key === 'Escape') {
                                popup?.hide()
                                return true
                            }

                            return component?.ref?.onKeyDown(props)
                        },

                        onExit() {
                            popup?.destroy()
                            component?.destroy()
                        },
                    }
                },
            }),
        ]
    },
})

export default MermaidSuggestion