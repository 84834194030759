// src/services/commentsDB.js
const DB_NAME = 'tiptap-comments';
const STORE_NAME = 'comments';
const DB_VERSION = 1;

class CommentsDB {
    async initDB() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(DB_NAME, DB_VERSION);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => {
                this.db = request.result;
                resolve(this.db);
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(STORE_NAME)) {
                    db.createObjectStore(STORE_NAME, { keyPath: 'id' });
                }
            };
        });
    }

    async getAllComments() {
        await this.getDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(STORE_NAME, 'readonly');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.getAll();

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
    }

    async addComment(comment) {
        await this.getDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(STORE_NAME, 'readwrite');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.add(comment);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(comment);
        });
    }

    async deleteComment(commentId) {
        await this.getDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(STORE_NAME, 'readwrite');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.delete(commentId);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(commentId);
        });
    }

    async getDB() {
        if (!this.db) {
            await this.initDB();
        }
        return this.db;
    }
}

export const commentsDB = new CommentsDB();