import { useState } from 'react';

/**
 * Custom hook for managing tasks within a project timeline
 * Handles all task-related operations including creation, updates, deletion,
 * and specialized operations like subtask management and drag-and-drop
 * 
 * @param {Object} params - Hook parameters
 * @param {Array} params.projects - Array of all projects
 * @param {string} params.activeProjectId - ID of the currently active project
 * @param {Function} params.setProjects - Function to update projects state
 * @param {Function} params.setHasUnsavedChanges - Function to mark unsaved changes
 * @returns {Object} Task management functions and state
 */
const useTaskManagement = ({
    projects,
    activeProjectId,
    setProjects,
    setHasUnsavedChanges
}) => {
    // Local state for task management
    const [newTaskTitle, setNewTaskTitle] = useState('');
    const [taskToDelete, setTaskToDelete] = useState(null);

    /**
     * Creates a new task with default values
     * @returns {void}
     */
    const addNewTask = () => {
        if (!newTaskTitle.trim() || !activeProjectId) return;

        const newTask = {
            id: Date.now().toString(),
            title: newTaskTitle,
            status: 'pending',
            description: '',
            startDate: null,
            endDate: null,
            progress: 0,
            priority: 'medium',
            subtasks: [],
            dependencies: [],
            expanded: false
        };

        setProjects(prevProjects =>
            prevProjects.map(p =>
                p.id === activeProjectId
                    ? { ...p, tasks: [...p.tasks, newTask] }
                    : p
            )
        );
        setNewTaskTitle('');
        setHasUnsavedChanges(true);
    };

    /**
     * Updates a specific task with new values
     * @param {string} taskId - ID of the task to update
     * @param {Object} updates - Object containing the fields to update
     * @returns {void}
     */
    const updateTask = (taskId, updates) => {
        setProjects(prevProjects =>
            prevProjects.map(p =>
                p.id === activeProjectId
                    ? {
                        ...p,
                        tasks: p.tasks.map(task =>
                            task.id === taskId ? { ...task, ...updates } : task
                        )
                    }
                    : p
            )
        );
        // Ensure hasUnsavedChanges is set after the state update
        setTimeout(() => setHasUnsavedChanges(true), 0);
    };

    /**
     * Deletes a task from the current project
     * @returns {void}
     */
    const handleDelete = () => {
        if (!taskToDelete) return;

        setProjects(prevProjects =>
            prevProjects.map(p =>
                p.id === activeProjectId
                    ? { ...p, tasks: p.tasks.filter(task => task.id !== taskToDelete) }
                    : p
            )
        );
        setTaskToDelete(null);
        setHasUnsavedChanges(true);
    };

    /**
     * Toggles the completion status of a subtask
     * @param {string} taskId - ID of the parent task
     * @param {string} subtaskId - ID of the subtask to toggle
     * @returns {void}
     */
    const toggleSubtask = (taskId, subtaskId) => {
        setProjects(prevProjects =>
            prevProjects.map(p =>
                p.id === activeProjectId
                    ? {
                        ...p,
                        tasks: p.tasks.map(task => {
                            if (task.id === taskId) {
                                const updatedSubtasks = task.subtasks.map(subtask =>
                                    subtask.id === subtaskId
                                        ? { ...subtask, completed: !subtask.completed }
                                        : subtask
                                );
                                const progress = Math.round(
                                    (updatedSubtasks.filter(st => st.completed).length /
                                        updatedSubtasks.length) * 100
                                );
                                return { ...task, subtasks: updatedSubtasks, progress };
                            }
                            return task;
                        })
                    }
                    : p
            )
        );
        setHasUnsavedChanges(true);
    };

    /**
     * Adds a new subtask to a specific task
     * @param {string} taskId - ID of the parent task
     * @param {string} title - Title of the new subtask
     * @returns {void}
     */
    const addSubtask = (taskId, title) => {
        if (!title.trim()) return;

        setProjects(prevProjects =>
            prevProjects.map(p =>
                p.id === activeProjectId
                    ? {
                        ...p,
                        tasks: p.tasks.map(task => {
                            if (task.id === taskId) {
                                const newSubtask = {
                                    id: `s${Date.now()}`,
                                    title,
                                    completed: false
                                };
                                return { ...task, subtasks: [...task.subtasks, newSubtask] };
                            }
                            return task;
                        })
                    }
                    : p
            )
        );
        setHasUnsavedChanges(true);
    };

    /**
     * Handles the end of a drag operation for task reordering
     * @param {Object} result - The drag-and-drop result object
     * @returns {void}
     */
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const activeProject = projects.find(p => p.id === activeProjectId);
        if (!activeProject) return;

        const items = Array.from(activeProject.tasks);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setProjects(prevProjects =>
            prevProjects.map(p =>
                p.id === activeProjectId ? { ...p, tasks: items } : p
            )
        );
        setHasUnsavedChanges(true);
    };

    return {
        // State
        newTaskTitle,
        taskToDelete,
        // State setters
        setNewTaskTitle,
        setTaskToDelete,
        // Task operations
        addNewTask,
        updateTask,
        handleDelete,
        toggleSubtask,
        addSubtask,
        handleDragEnd
    };
};

export default useTaskManagement;