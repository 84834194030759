// src/contexts/AuthContext.jsx

import { createContext, useState, useContext, useCallback, useEffect, useMemo } from 'react';
import LoginApiService from '../services/LoginApiService';

const AuthContext = createContext(null);

const initialUserState = {
  email: null,
  username: null,
  id: null,
  is_admin: false,
  is_active: true,
  TaskPlexPlanId: null,  // Kept for backward compatibility
  paypalPlanId: null,    // Kept for backward compatibility
  paypalProductId: null, // Kept for backward compatibility
  planName: null,        // Kept for backward compatibility
  main_subscription: null,
  storage_subscription: null,
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(initialUserState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const checkAuth = useCallback(async () => {
    setLoading(true);
    try {
      const currentUser = await LoginApiService.getCurrentUser();
      setUser(currentUser);
      setError(null);
    } catch (error) {
      console.log('getCurrentUser error:', error);
      if (error.response && error.response.status === 401) {
        setUser(null);
      } else {
        console.error('Unexpected error during auth check:', error);
        setError('Failed to authenticate');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;

    const runCheckAuth = async () => {
      if (isMounted) {
        const skipCheckPaths = ['/', '/plans'];
        const currentPath = new URL(window.location.href).pathname;

        if (!skipCheckPaths.includes(currentPath)) {
          await checkAuth();
        } else {
          setLoading(false);
        }
      }
    };

    runCheckAuth();

    return () => {
      isMounted = false;
    };
  }, [checkAuth]);

  const login = useCallback(async (email, password) => {
    setLoading(true);
    try {
      const loginResponse = await LoginApiService.login(email, password);

      if (loginResponse.redirect) {
        window.location.href = loginResponse.redirect;
        return null;
      }

      setUser(loginResponse.user);
      setError(null);

      return loginResponse;
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed');
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await LoginApiService.logout();
      setUser(null);
      setError(null);
    } catch (error) {
      setError('Logout failed');
    } finally {
      setLoading(false);
    }
  }, []);

  const setVerifiedUser = useCallback((userData) => {
    setUser({
      email: userData.email,
      username: userData.username,
      id: userData.id,
      is_admin: userData.is_admin || false,
      is_active: userData.is_active || true,
      // Backward compatibility fields
      TaskPlexPlanId: userData.TaskPlexPlanId || null,
      paypalPlanId: userData.paypalPlanId || null,
      paypalProductId: userData.paypalProductId || null,
      planName: userData.planName || null,
      // New subscription fields
      main_subscription: userData.main_subscription || null,
      storage_subscription: userData.storage_subscription || null,
    });
    setError(null);
  }, []);

  // Subscription utility functions
  const isSubscriptionActive = useCallback(() => {
    return user?.main_subscription?.status === 'active' ||
      user?.main_subscription?.status === 'trial';
  }, [user?.main_subscription?.status]);

  const hasStorageSubscription = useCallback(() => {
    return user?.storage_subscription?.status === 'active';
  }, [user?.storage_subscription?.status]);

  const getSubscriptionEndDate = useCallback(() => {
    return user?.main_subscription?.end_date || null;
  }, [user?.main_subscription?.end_date]);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      loading,
      error,
      checkAuth,
      setVerifiedUser,
      // New subscription-related utilities
      isSubscriptionActive,
      hasStorageSubscription,
      getSubscriptionEndDate,
    }),
    [
      user,
      login,
      logout,
      loading,
      error,
      checkAuth,
      setVerifiedUser,
      isSubscriptionActive,
      hasStorageSubscription,
      getSubscriptionEndDate,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;