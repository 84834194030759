import React, { useState, useEffect, useRef } from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import { Button, Alert, Dropdown, Form, Spinner } from 'react-bootstrap'
import mermaid from 'mermaid'
import mermaidApi from '../../../services/mermaidApiService'

export const MermaidNode = ({ node, updateAttributes }) => {
    const [mode, setMode] = useState('view')
    const [error, setError] = useState(null)
    const [isRegenerating, setIsRegenerating] = useState(false)
    const previewRef = useRef(null)

    useEffect(() => {
        mermaid.initialize({
            startOnLoad: true,
            theme: node.attrs.theme || 'default',
            securityLevel: 'loose',
        })
    }, [])

    const handleExport = async (format) => {
        try {
            const svgElement = previewRef.current.querySelector('svg');
            if (!svgElement) return;

            // Get the SVG dimensions
            const viewBox = svgElement.viewBox.baseVal;
            const width = viewBox.width;
            const height = viewBox.height;

            if (format === 'svg') {
                // Clone the SVG to avoid modifying the displayed one
                const clonedSvg = svgElement.cloneNode(true);
                clonedSvg.setAttribute('width', width);
                clonedSvg.setAttribute('height', height);

                const svgData = new XMLSerializer().serializeToString(clonedSvg);
                const blob = new Blob([svgData], { type: 'image/svg+xml' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `diagram-${Date.now()}.svg`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else if (format === 'jpeg') {
                // Convert SVG to data URL
                const svgString = new XMLSerializer().serializeToString(svgElement);
                const base64SVG = btoa(unescape(encodeURIComponent(svgString)));
                const dataUrl = `data:image/svg+xml;base64,${base64SVG}`;

                // Create an image from the data URL
                const img = new Image();
                img.crossOrigin = 'anonymous';

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const scale = 2; // 2x for better quality
                    canvas.width = width * scale;
                    canvas.height = height * scale;

                    const ctx = canvas.getContext('2d');
                    ctx.scale(scale, scale);

                    // Fill white background
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, width, height);

                    // Draw the image
                    ctx.drawImage(img, 0, 0, width, height);

                    // Convert to JPEG data URL
                    const jpegDataUrl = canvas.toDataURL('image/jpeg', 0.9);

                    // Create download link
                    const link = document.createElement('a');
                    link.href = jpegDataUrl;
                    link.download = `diagram-${Date.now()}.jpg`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                };

                img.src = dataUrl;
            }
        } catch (error) {
            console.error('Export failed:', error);
            setError('Failed to export diagram');
        }
    };

    useEffect(() => {
        if ((mode === 'preview' || mode === 'view') && node.attrs.content) {
            try {
                if (previewRef.current) {
                    previewRef.current.innerHTML = node.attrs.content
                    mermaid.init(undefined, previewRef.current)
                }
                setError(null)
            } catch (err) {
                setError('Failed to render diagram. Please check your syntax.')
                console.error('Mermaid render error:', err)
            }
        }
    }, [mode, node.attrs.content])

    const handleContentChange = (event) => {
        updateAttributes({
            content: event.target.value,
        })
    }

    const handlePromptChange = (event) => {
        updateAttributes({
            prompt: event.target.value,
        })
    }

    const handleDiagramTypeChange = (event) => {
        updateAttributes({
            diagramType: event.target.value,
        })
    }

    const handleRegenerate = async () => {
        try {
            setIsRegenerating(true)
            setError(null)
            const response = await mermaidApi.generateDiagram(node.attrs.prompt, node.attrs.diagramType)
            updateAttributes({
                content: response.mermaid_code,
                lastUpdated: new Date().toISOString(),
            })
            setMode('view')
        } catch (err) {
            setError('Failed to regenerate diagram')
            console.error('Regeneration error:', err)
        } finally {
            setIsRegenerating(false)
        }
    }

    return (
        <NodeViewWrapper className="mermaid-node">
            <div className="mermaid-container p-3 border rounded">
                {error && (
                    <Alert variant="danger" className="mb-3">
                        {error}
                    </Alert>
                )}

                {mode === 'edit' && (
                    <div className="mermaid-edit-container">
                        <Form.Group className="mb-3">
                            <Form.Label>Diagram Type</Form.Label>
                            <Form.Select
                                value={node.attrs.diagramType}
                                onChange={handleDiagramTypeChange}
                                className="mb-3"
                            >
                                {['flowchart', 'sequence', 'gantt', 'class', 'state'].map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </Form.Select>

                            <Form.Label>Prompt</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                value={node.attrs.prompt}
                                onChange={handlePromptChange}
                                placeholder="Describe your diagram..."
                                className="mb-3"
                            />

                            <Form.Label>Mermaid Code</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                value={node.attrs.content}
                                onChange={handleContentChange}
                                className="mb-3"
                            />
                        </Form.Group>

                        <div className="d-flex gap-2 justify-content-end">
                            <Button
                                variant="secondary"
                                onClick={() => setMode('preview')}
                            >
                                Preview
                            </Button>
                            <Button
                                variant="info"
                                onClick={handleRegenerate}
                                disabled={isRegenerating || !node.attrs.prompt}
                            >
                                {isRegenerating ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        Regenerating...
                                    </>
                                ) : (
                                    'Regenerate'
                                )}
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => setMode('view')}
                            >
                                Done
                            </Button>
                        </div>
                    </div>
                )}

                {(mode === 'preview' || mode === 'view') && (
                    <div className="mermaid mb-3" ref={previewRef}>
                        {node.attrs.content}
                    </div>
                )}

                {mode === 'preview' && (
                    <div className="d-flex gap-2 justify-content-end">
                        <Button
                            variant="secondary"
                            onClick={() => setMode('edit')}
                        >
                            Back to Edit
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => setMode('view')}
                        >
                            Done
                        </Button>
                    </div>
                )}

                {mode === 'view' && (
                    <div className="d-flex gap-2 justify-content-end">
                        <Button
                            variant="secondary"
                            onClick={() => setMode('edit')}
                        >
                            Edit
                        </Button>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary">
                                Export
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleExport('svg')}>
                                    Export as SVG
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleExport('jpeg')}>
                                    Export as JPEG
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}

                {node.attrs.lastUpdated && (
                    <div className="text-muted mt-2 small">
                        Last updated: {new Date(node.attrs.lastUpdated).toLocaleString()}
                    </div>
                )}
            </div>
        </NodeViewWrapper>
    )
}