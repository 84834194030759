import React, { useRef, useState, useEffect, memo } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { Upload, Send } from 'lucide-react';

const EnhancedChatInput = memo(({ onSendMessage, loading, selectedModel }) => {
    const [message, setMessage] = useState('');
    const messageInputRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (messageInputRef.current) {
            messageInputRef.current.style.height = 'auto';
            messageInputRef.current.style.height = `${messageInputRef.current.scrollHeight}px`;
        }
    }, [message]);

    const handleSendMessage = async (attachmentData = null) => {
        if (!message.trim() && !attachmentData) return;

        await onSendMessage(
            attachmentData ? attachmentData.message : message,
            attachmentData ? { attachment: attachmentData.attachment } : undefined
        );

        setMessage('');
        if (messageInputRef.current) {
            messageInputRef.current.style.height = 'auto';
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const attachmentData = {
                    message: `Uploaded file: ${file.name}`,
                    attachment: e.target?.result
                };
                handleSendMessage(attachmentData);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="border-top bg-white mt-auto">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSendMessage();
                }}
                className="p-2 p-sm-3"
            >
                <div className="d-flex gap-2">
                    <Form.Group controlId="file-upload">
                        <Form.Label className="mb-0">
                            <Button
                                variant="outline-secondary"
                                className="d-flex align-items-center p-2"
                                disabled={!selectedModel}
                            >
                                <Upload size={20} />
                            </Button>
                        </Form.Label>
                        <Form.Control
                            type="file"
                            onChange={handleFileUpload}
                            className="d-none"
                            ref={fileInputRef}
                        />
                    </Form.Group>

                    <Form.Control
                        as="textarea"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault(); // Prevent new line
                                if (!loading && message.trim() && selectedModel) {
                                    handleSendMessage();
                                }
                            }
                        }}
                        placeholder={selectedModel ? "Type your message..." : "Select a model first"}
                        rows={1}
                        style={{
                            resize: 'none',
                            maxHeight: '120px',
                            WebkitAppearance: 'none',
                            borderRadius: '4px',
                            overflow: 'hidden'
                        }}
                        className="py-2"
                        ref={messageInputRef}
                        disabled={!selectedModel}
                    />

                    <Button
                        variant="primary"
                        onClick={() => handleSendMessage()}
                        disabled={loading || !message.trim() || !selectedModel}
                        className="px-3"
                    >
                        {loading ? (
                            <Spinner animation="border" size="sm" />
                        ) : (
                            <Send size={20} />
                        )}
                    </Button>
                </div>
            </Form>
        </div>
    );
});

EnhancedChatInput.displayName = 'EnhancedChatInput';

export default EnhancedChatInput;