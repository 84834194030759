// CodeCanvas.jsx
import React, { useState, useCallback } from 'react';
import { Col, Button } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Copy, X } from 'lucide-react';

const CodeCanvas = ({
    isVisible,
    content,
    onClose,
    onSidebarOpen
}) => {
    const [copyFeedback, setCopyFeedback] = useState(false);

    const cleanCodeForCopy = useCallback((content) => {
        if (typeof content !== 'string') return content;
        return content
            .replace(/^```[\w]*\n/, '')
            .replace(/```$/, '')
            .trim();
    }, []);

    const handleCopy = async () => {
        try {
            const cleanedCode = cleanCodeForCopy(content.code);
            await navigator.clipboard.writeText(cleanedCode);
            setCopyFeedback(true);
            setTimeout(() => {
                setCopyFeedback(false);
            }, 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    const handleClose = () => {
        onClose();
        onSidebarOpen();
    };

    if (!isVisible || !content) return null;

    return (
        <Col md={6} className="border-start h-100 overflow-hidden bg-light">
            <div className="d-flex justify-content-between align-items-center p-2 border-bottom">
                <h6 className="mb-0">Code View</h6>
                <div className="d-flex align-items-center gap-2">
                    <Button
                        variant="link"
                        className="p-0 text-dark"
                        onClick={handleCopy}
                    >
                        <Copy size={20} className="me-2" />
                        {copyFeedback ? "Copied!" : ""}
                    </Button>
                    <Button
                        variant="link"
                        className="p-0 text-dark"
                        onClick={handleClose}
                    >
                        <X size={20} />
                    </Button>
                </div>
            </div>
            <div className="p-3 h-100 overflow-auto">
                {content.context && (
                    <p className="text-muted mb-3">{content.context}</p>
                )}
                <ReactMarkdown
                    components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '');
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    style={oneDark}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                >
                                    {String(children).replace(/\n$/, '')}
                                </SyntaxHighlighter>
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            );
                        }
                    }}
                >
                    {content.code}
                </ReactMarkdown>
            </div>
        </Col>
    );
};

export default CodeCanvas;