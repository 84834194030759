// TiptapEditor.jsx
import { useState, useEffect, useCallback, useRef } from 'react';
import { MessageSquare, Loader2 } from 'lucide-react';
import { EditorProvider } from './EditorContext';
import EditorToolbar from './Toolbar/EditorToolbar';
import EditorContent from './EditorContent';
import { useTiptapConfig } from './useTiptapConfig';
import { useTheme } from '../ThemeProvider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleUp, faAngleDoubleDown, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import AICommentsDisplay from './extensions/AICommentsDisplay'
import { CommentProvider } from './extensions/CommentContext';

// New AI Loading Indicator component
const AILoadingIndicator = ({ isLoading, theme }) => {
    if (!isLoading) return null;

    const keyframes = `
        @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
        }
    `;

    const styles = {
        container: {
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            padding: '0.25rem 0.5rem',
            borderRadius: '0.375rem',
            fontSize: '0.875rem',
            backgroundColor: theme === 'dark' ? '#132E1A' : '#E7F6EA',
            color: theme === 'dark' ? '#86EFAC' : '#166534'
        },
        icon: {
            height: '1rem',
            width: '1rem',
            color: theme === 'dark' ? '#4ADE80' : '#16A34A',
            animation: 'spin 1s linear infinite'
        }
    };

    return (
        <>
            <style>{keyframes}</style>
            <div style={styles.container} className="ai-loading-indicator">
                <Loader2 style={styles.icon} />
                <span>AI Processing...</span>
            </div>
        </>
    );
};

const TiptapEditor = ({
    initialContent,
    onUpdate,
    onSave,
    selectedDate,
    lastSavedContent,
    hasUnsavedChanges,
    setHasUnsavedChanges
}) => {
    const { theme } = useTheme();
    const [editorKey, setEditorKey] = useState(selectedDate.toISOString());
    const prevDateRef = useRef(selectedDate);
    const editorRef = useRef(null);
    const isUpdatingRef = useRef(false);
    const [touchStartY, setTouchStartY] = useState(null);
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [isEditorReady, setIsEditorReady] = useState(false);
    const autoSaveTimeoutRef = useRef(null);
    const lastSavedContentRef = useRef(lastSavedContent);
    const [showComments, setShowComments] = useState(false);

    const isContentChanged = useCallback((newContent, oldContent) => {
        const result = JSON.stringify(newContent) !== JSON.stringify(oldContent);
        return result;
    }, []);

    const handleEditorUpdate = useCallback((newContent) => {
        if (!isUpdatingRef.current) {
            const contentChanged = isContentChanged(newContent, lastSavedContentRef.current);
            setHasUnsavedChanges(contentChanged);
            if (onUpdate) {
                onUpdate(newContent);
            }
            if (contentChanged) {
                if (autoSaveTimeoutRef.current) {
                    clearTimeout(autoSaveTimeoutRef.current);
                }
                autoSaveTimeoutRef.current = setTimeout(() => {
                    handleAutoSave(newContent);
                }, 5000);
            }
        }
    }, [onUpdate, isContentChanged, setHasUnsavedChanges]);

    const handleAutoSave = useCallback((content) => {
        if (isContentChanged(content, lastSavedContentRef.current)) {
            onSave(content).then(success => {
                if (success) {
                    lastSavedContentRef.current = content;
                    setHasUnsavedChanges(false);
                }
            });
        }
    }, [onSave, isContentChanged, setHasUnsavedChanges]);

    const {
        editor,
        isAILoading // Add AI loading state
    } = useTiptapConfig(initialContent, handleEditorUpdate, selectedDate);

    editorRef.current = editor;

    useEffect(() => {
        setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, []);

    useEffect(() => {
        if (selectedDate.toISOString() !== prevDateRef.current.toISOString()) {
            setEditorKey(selectedDate.toISOString());
            prevDateRef.current = selectedDate;
            lastSavedContentRef.current = initialContent;
            setHasUnsavedChanges(false);
        }
    }, [selectedDate, initialContent, setHasUnsavedChanges]);

    useEffect(() => {
        if (editor && isEditorReady && initialContent !== editor.getJSON()) {
            isUpdatingRef.current = true;
            requestAnimationFrame(() => {
                editor.commands.setContent(initialContent, false);
                lastSavedContentRef.current = initialContent;
                setHasUnsavedChanges(false);
                isUpdatingRef.current = false;
            });
        }
    }, [editor, initialContent, editorKey, isEditorReady, setHasUnsavedChanges]);

    useEffect(() => {
        lastSavedContentRef.current = lastSavedContent;
    }, [lastSavedContent]);

    const [isToolbarVisible, setIsToolbarVisible] = useState(true);
    const [isToolbarPinned, setIsToolbarPinned] = useState(false);

    const handleKeyDown = useCallback((event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 's') {
            event.preventDefault();
            handleAutoSave(editor.getJSON());
        }
    }, [handleAutoSave, editor]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const toggleToolbar = () => {
        setIsToolbarVisible(!isToolbarVisible);
    };

    const togglePin = () => {
        setIsToolbarPinned(!isToolbarPinned);
        setIsToolbarVisible(true);
    };

    const handleTouchStart = (e) => {
        setTouchStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        if (touchStartY === null) return;

        const touchEndY = e.touches[0].clientY;
        const diff = touchStartY - touchEndY;

        if (diff > 50) {
            setIsToolbarVisible(false);
        } else if (diff < -50) {
            setIsToolbarVisible(true);
        }
    };

    const handleTouchEnd = () => {
        setTouchStartY(null);
    };

    const CommentToggleButton = () => {
        const commentCount = editor?.storage?.aiComment?.comments?.length || 0;

        return (
            <button
                className="toolbar-comments-toggle relative"
                onClick={() => setShowComments(!showComments)}
                title={showComments ? "Hide comments" : "Show comments"}
                style={{ border: "none", boxShadow: "none" }}
            >
                <MessageSquare
                    size={16}
                    className={showComments ? 'text-blue-500' : 'text-gray-500'}
                />
                {commentCount > 0 && (
                    <span className="absolute -top-1 -right-1 bg-blue-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                        {commentCount}
                    </span>
                )}
            </button>
        );
    };

    if (!editor) {
        return null;
    }

    return (
        <EditorProvider value={editor} key={editorKey}>
            <div
                className={`tiptap-wrapper ${theme === 'dark' || theme === 'dark2' ? 'tiptap-dark' : ''} ${isTouchDevice ? 'touch-device' : ''}`}
                aria-label="Rich text editor"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className={`toolbar-container ${isToolbarPinned ? 'pinned' : ''}`}>
                    {isToolbarVisible && (
                        <>
                            <EditorToolbar />
                            <AILoadingIndicator isLoading={isAILoading} theme={theme} />
                        </>
                    )}
                    <div className="toolbar-controls flex items-center gap-2">
                        <button
                            className="toolbar-toggle touch-friendly"
                            onClick={toggleToolbar}
                            aria-label={isToolbarVisible ? "Hide toolbar" : "Show toolbar"}
                            disabled={isToolbarPinned}
                        >
                            <FontAwesomeIcon icon={isToolbarVisible ? faAngleDoubleUp : faAngleDoubleDown} />
                        </button>
                        <button
                            className={`toolbar-pin touch-friendly ${isToolbarPinned ? 'active' : 'slashed'}`}
                            onClick={togglePin}
                            aria-label={isToolbarPinned ? "Unpin toolbar" : "Pin toolbar"}
                        >
                            <FontAwesomeIcon icon={faThumbtack} />
                        </button>
                        <CommentToggleButton />
                    </div>
                </div>
                <div className={`editor-with-comments ${showComments ? 'show-comments' : 'hide-comments'}`}>
                    <div className="editor-main">
                        <EditorContent editor={editor} onSave={handleAutoSave} />
                    </div>
                    {editor && (
                        <CommentProvider>
                            <AICommentsDisplay editor={editor} />
                            {/* <AICommentsManager editor={editor} /> */}
                        </CommentProvider>
                    )}
                </div>
            </div>
        </EditorProvider>
    );
};

export default TiptapEditor;