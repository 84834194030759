import { jsPDF } from 'jspdf';
import { FileDown } from 'lucide-react';
import 'jspdf-autotable';

const exportProjectToPDF = (project) => {
    // Initialize PDF document
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(20);
    doc.text(project.name, 20, 20);

    // Add timestamp
    doc.setFontSize(10);
    doc.text(`Generated on ${new Date().toLocaleString()}`, 20, 30);

    // Prepare tasks data for the table
    const tableData = project.tasks.map(task => {
        const getSubtasksStatus = () => {
            const completed = task.subtasks.filter(st => st.completed).length;
            const total = task.subtasks.length;
            return total > 0 ? `${completed}/${total}` : 'N/A';
        };

        return [
            task.title,
            task.status,
            task.priority,
            task.startDate ? new Date(task.startDate).toLocaleDateString() : '-',
            task.endDate ? new Date(task.endDate).toLocaleDateString() : '-',
            `${task.progress}%`,
            getSubtasksStatus()
        ];
    });

    // Add tasks table
    doc.autoTable({
        startY: 40,
        head: [['Task', 'Status', 'Priority', 'Start Date', 'End Date', 'Progress', 'Subtasks']],
        body: tableData,
        theme: 'grid',
        headStyles: {
            fillColor: [41, 128, 185],
            textColor: 255,
            fontSize: 12
        },
        styles: {
            fontSize: 10,
            cellPadding: 5
        },
        columnStyles: {
            0: { cellWidth: 40 },
            1: { cellWidth: 25 },
            2: { cellWidth: 25 },
            3: { cellWidth: 25 },
            4: { cellWidth: 25 },
            5: { cellWidth: 20 },
            6: { cellWidth: 20 }
        }
    });

    // Add detailed task information
    let yPosition = doc.lastAutoTable.finalY + 20;

    project.tasks.forEach((task, index) => {
        // Check if we need a new page
        if (yPosition > 250) {
            doc.addPage();
            yPosition = 20;
        }

        doc.setFontSize(12);
        doc.setFont(undefined, 'bold');
        doc.text(`Task ${index + 1}: ${task.title}`, 20, yPosition);

        doc.setFont(undefined, 'normal');
        doc.setFontSize(10);
        yPosition += 10;

        if (task.description) {
            doc.text('Description:', 20, yPosition);
            yPosition += 5;
            const descriptionLines = doc.splitTextToSize(task.description, 170);
            doc.text(descriptionLines, 20, yPosition);
            yPosition += descriptionLines.length * 5 + 5;
        }

        if (task.subtasks.length > 0) {
            doc.text('Subtasks:', 20, yPosition);
            yPosition += 5;
            task.subtasks.forEach(subtask => {
                doc.text(`• ${subtask.title} - ${subtask.completed ? 'Completed' : 'Pending'}`, 25, yPosition);
                yPosition += 5;
            });
            yPosition += 5;
        }

        if (task.dependencies?.length > 0) {
            doc.text('Dependencies:', 20, yPosition);
            yPosition += 5;
            task.dependencies.forEach(depId => {
                const depTask = project.tasks.find(t => t.id === depId);
                if (depTask) {
                    doc.text(`• ${depTask.title}`, 25, yPosition);
                    yPosition += 5;
                }
            });
            yPosition += 5;
        }

        yPosition += 10;
    });

    // Save the PDF
    doc.save(`${project.name.replace(/\s+/g, '_')}_timeline.pdf`);
};

export default exportProjectToPDF;