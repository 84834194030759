import { useCallback } from 'react';
import NEW_JournalApiService from '../../services/NEW_JournalApiService';

export const useImageDeletion = (user, recentlyAddedImages, imageKeyToFilename) => {
    // Track images being deleted to prevent double-deletion
    const deletingImages = new Set();

    return useCallback(
        async ({ transaction }) => {
            if (!user) return;

            // Skip full document replacements
            const isFullDocReplacement = transaction.steps.some((step) => {
                return (
                    step.from === 0 &&
                    step.to >= transaction.before.content.size &&
                    step.slice.content.size >= transaction.before.content.size
                );
            });

            if (isFullDocReplacement) return;

            const oldDoc = transaction.before;
            const newDoc = transaction.doc;

            const oldImages = [];
            const newImages = [];

            // Collect old images
            oldDoc.descendants((node) => {
                if (node.type.name === 'image') {
                    const imageKey = node.attrs.src;
                    if (imageKey && !imageKey.startsWith('blob:')) {
                        oldImages.push({ imageKey, attrs: node.attrs });
                    }
                }
            });

            // Collect new images
            newDoc.descendants((node) => {
                if (node.type.name === 'image') {
                    const imageKey = node.attrs.src;
                    if (imageKey && !imageKey.startsWith('blob:')) {
                        newImages.push(imageKey);
                    }
                }
            });

            const deletedImages = oldImages.filter(
                ({ imageKey }) =>
                    !newImages.includes(imageKey) &&
                    !recentlyAddedImages.current.has(imageKey) &&
                    !deletingImages.has(imageKey)  // Check if already being deleted
            );

            for (const { imageKey, attrs } of deletedImages) {
                try {
                    // Mark image as being deleted
                    deletingImages.add(imageKey);

                    let filename = imageKeyToFilename.current.get(imageKey);
                    if (!filename) {
                        filename = attrs['data-filename'] || imageKey.split('/').pop();
                    }

                    let username = attrs['data-username'] || user.username;
                    let date = attrs['data-date'];

                    if (!date) {
                        const segments = imageKey.split('/');
                        const dateIndex = segments.indexOf(username) + 1;
                        date = segments[dateIndex];
                    }

                    await NEW_JournalApiService.deleteImage(username, date, filename);
                    imageKeyToFilename.current.delete(imageKey);

                } catch (error) {
                    console.error('Error deleting image:', error);
                } finally {
                    // Clear deletion tracking
                    deletingImages.delete(imageKey);
                }
            }
        },
        [user]
    );
};