import React, { useEffect, Suspense, lazy } from "react";
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { ThemeProvider } from './components/ThemeProvider';
import { SettingsProvider } from './contexts/SettingsContext';
import ErrorBoundary from './components/ErrorBoundary';
import ProtectedRoute from "./components/ProtectedRoute";
import { VersionProvider } from "./contexts/VersionProvider.jsx";

// Lazy load the journal page
const Journal = lazy(() => import("./routes/Journal.jsx"));

// Import other components normally since they're smaller
import Settings from "./routes/Settings.jsx";
import Admin from "./routes/Admin.jsx";
import Login from "./routes/Login.jsx";
import PublicLandingPage from "./routes/PublicLandingPage";
import CreateTrialUser from "./components/CreateTrialUser";
import CreateUser from "./components/CreateUser";
import UserAccount from "./components/UserAccountInfo/UserAccount";
import RequestPasswordResetForm from "./routes/RequestPasswordResetForm"
import ResetPasswordFrom from "./routes/ResetPasswordFrom"
import Support from "./routes/Support";
import Plans from "./routes/Plans";
import Payment from "./routes/Payment";
import PaymentAccount from "./routes/PaymentAccount";
import ChatInterface from "./components/AiChat/ChatInterface";
import CompletedTasksViewer from './components/calendar/CompletedTasksViewer/CompletedTasksViewer';
import ProjectTimeline from './components/TimeLine/TimeLine';

// Loading component for suspense fallback
const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 dark:border-white"></div>
  </div>
);

// Admin route component remains the same
const AdminRoute = ({ children }) => {
  const { user, loading, checkAuth } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  return children;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <PublicLandingPage />,
  },
  {
    path: "/journal",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<LoadingSpinner />}>
          <Journal />
        </Suspense>
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin",
    element: <AdminRoute><Admin /></AdminRoute>,
  },
  {
    path: "/settings",
    element: <ProtectedRoute><Settings /></ProtectedRoute>,
  },
  {
    path: "/account",
    element: <ProtectedRoute><UserAccount /></ProtectedRoute>,
  },
  {
    path: "/support",
    element: <ProtectedRoute><Support /></ProtectedRoute>,
  },
  {
    path: "/payment",
    element: <ProtectedRoute><Payment /></ProtectedRoute>,
  },
  {
    path: "/PaymentAccount",
    element: <ProtectedRoute><PaymentAccount /></ProtectedRoute>,
  },
  {
    path: "/ai_chat",
    element: <ProtectedRoute><ChatInterface /></ProtectedRoute>,
  },
  {
    path: "/CompletedTasksViewer",
    element: <ProtectedRoute><CompletedTasksViewer /></ProtectedRoute>,
  },
  {
    path: "/timeline",
    element: <ProtectedRoute><ProjectTimeline /></ProtectedRoute>,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/request-pw-reset",
    element: <RequestPasswordResetForm />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordFrom />,
  },
  {
    path: "/signup/trial",
    element: <CreateTrialUser />,
  },
  {
    path: "/signup",
    element: <CreateUser />,
  },
  {
    path: "/plans",
    element: <Plans />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

function ErrorFallback({ error }) {
  return (
    <div className="p-4">
      <h1 className="text-xl font-bold text-red-600">Something went wrong:</h1>
      <pre className="mt-2 p-2 bg-gray-100 rounded">{error.message}</pre>
    </div>
  )
}

const App = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ThemeProvider>
      <AuthProvider>
        <SettingsProvider>
          <VersionProvider>
            <RouterProvider router={router} />
          </VersionProvider>
        </SettingsProvider>
      </AuthProvider>
    </ThemeProvider>
  </ErrorBoundary>
);

export default App;