import React, { useState } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Clock } from 'lucide-react';

const VersionHistoryModal = ({
    show,
    onHide,
    message,
    onRestoreVersion
}) => {
    const [selectedVersion, setSelectedVersion] = useState(null);

    // Prepare versions array with current version and history
    const versions = [
        {
            content: message.content,
            timestamp: message.editedAt || message.timestamp,
            current: true,
            aiResponse: null // Current version's AI response would be handled separately
        },
        ...(message.editHistory || []).reverse()
    ];

    const handleRestore = () => {
        if (selectedVersion !== null) {
            onRestoreVersion(versions[selectedVersion].content);
            onHide();
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Prompt Version History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between mb-3">
                    <Button
                        variant="outline-secondary"
                        disabled={!selectedVersion}
                        onClick={() => setSelectedVersion(prev => prev > 0 ? prev - 1 : null)}
                    >
                        <ChevronLeft size={20} />
                        Previous
                    </Button>
                    <span className="text-muted">
                        {selectedVersion !== null
                            ? `Version ${versions.length - selectedVersion}`
                            : 'Current Version'}
                    </span>
                    <Button
                        variant="outline-secondary"
                        disabled={selectedVersion === null || selectedVersion === versions.length - 1}
                        onClick={() => setSelectedVersion(prev => prev < versions.length - 1 ? prev + 1 : prev)}
                    >
                        Next
                        <ChevronRight size={20} />
                    </Button>
                </div>

                <div className="border rounded p-3 mb-3 bg-light">
                    <h6 className="mb-3">Prompt:</h6>
                    <div className="prompt-content">
                        {selectedVersion !== null
                            ? versions[selectedVersion].content
                            : message.content}
                    </div>
                </div>

                {selectedVersion !== null && versions[selectedVersion].aiResponse && (
                    <div className="border rounded p-3 mb-3">
                        <h6 className="mb-3">AI Response:</h6>
                        <div className="ai-response">
                            {versions[selectedVersion].aiResponse.content}
                        </div>
                    </div>
                )}

                <ListGroup className="mt-4">
                    {versions.map((version, index) => (
                        <ListGroup.Item
                            key={index}
                            action
                            active={selectedVersion === index}
                            onClick={() => setSelectedVersion(index === selectedVersion ? null : index)}
                            className="d-flex justify-content-between align-items-center"
                        >
                            <div>
                                <span className="me-2">Version {versions.length - index}</span>
                                {version.current && <span className="text-muted">(Current)</span>}
                            </div>
                            <div className="text-muted">
                                <Clock size={14} className="me-1" />
                                {new Date(version.timestamp).toLocaleString()}
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                {selectedVersion !== null && selectedVersion !== 0 && (
                    <Button variant="primary" onClick={handleRestore}>
                        Restore This Version
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default VersionHistoryModal;