// src/hooks/useChatLogic.js
import { useState, useCallback, useEffect } from 'react';
import useChatHistory from './useChatHistory';
import aiApi from '../../services/aiApiService';

const useChatLogic = ({
    initialModel = null,
    initialTemperature = 0.1,
    initialSystemPrompt = '',
    initialUseInternet = false,
} = {}) => {
    // Core state
    const [currentChat, setCurrentChat] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorType, setErrorType] = useState(null);

    // Settings state
    const [selectedModel, setSelectedModel] = useState(initialModel);
    const [temperature, setTemperature] = useState(initialTemperature);
    const [systemPrompt, setSystemPrompt] = useState(initialSystemPrompt);
    const [useInternet, setUseInternet] = useState(initialUseInternet);

    // Initialize chat history management
    const {
        conversations,
        saveConversation,
        deleteConversation,
        getConversation,
        updateConversationTitle,
        createBranch,
        contextSummary,
        searchConversations
    } = useChatHistory({
        aiApi,
        selectedModel,
        temperature
    });

    const clearError = useCallback(() => {
        setError(null);
        setErrorType(null);
    }, []);

    const createNewChat = useCallback(() => {
        if (!selectedModel) return null;

        clearError();
        const newChat = {
            id: Date.now().toString(),
            threadId: crypto.randomUUID(),
            parentThreadId: null,
            branches: [],
            messages: [],
            model: selectedModel,
            settings: {
                temperature,
                systemPrompt,
                useInternet
            },
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
        };

        saveConversation(newChat);
        setCurrentChat(newChat.id);
        return newChat.id;
    }, [selectedModel, temperature, systemPrompt, useInternet, clearError, saveConversation]);

    // Update model when changed
    useEffect(() => {
        if (currentChat && selectedModel) {
            const conversation = getConversation(currentChat);
            if (conversation && conversation.model !== selectedModel) {
                saveConversation({
                    ...conversation,
                    model: selectedModel,
                    updatedAt: new Date().toISOString()
                });
            }
        }
    }, [selectedModel, currentChat, getConversation, saveConversation]);

    // Create new chat when model is selected but no chat exists
    useEffect(() => {
        if (selectedModel && !currentChat) {
            createNewChat();
        }
    }, [selectedModel, currentChat, createNewChat]);

    const setModelId = useCallback((modelId) => {
        const newModelId = typeof modelId === 'string' ? parseInt(modelId, 10) : modelId;
        setSelectedModel(newModelId);
    }, []);

    // useEffect(() => {
    //     if (selectedModel && !currentChat) {
    //         createNewChat();
    //     }
    // }, [selectedModel, currentChat, createNewChat]);

    const handleError = useCallback((error) => {
        const errorMessage = error.message || 'Failed to send message';
        let type = 'general';

        if (errorMessage.includes('Access denied') ||
            errorMessage.includes('subscription') ||
            errorMessage.includes('does not include AI')) {
            type = 'subscription';
        } else if (errorMessage.includes('quota')) {
            type = 'quota';
        }

        setError(errorMessage);
        setErrorType(type);

        if (type === 'general' && currentChat) {
            const conversation = getConversation(currentChat);
            if (conversation) {
                saveConversation({
                    ...conversation,
                    messages: [...conversation.messages, {
                        id: Date.now().toString(),
                        content: `Error: ${errorMessage}`,
                        role: 'system',
                        isError: true,
                        timestamp: new Date().toISOString()
                    }],
                    updatedAt: new Date().toISOString()
                });
            }
        }
    }, [currentChat, getConversation, saveConversation]);

    const sendMessage = useCallback(async (message) => {
        if (!message.trim() || !currentChat) return;

        setLoading(true);
        clearError();

        try {
            const conversation = getConversation(currentChat);
            if (!conversation) {
                throw new Error('Conversation not found');
            }

            // Handle normal message exchange first
            let contextualizedMessage = message;
            const lastAssistantMessage = conversation.messages
                .filter(msg => msg.role === 'assistant')
                .pop();

            if (lastAssistantMessage) {
                contextualizedMessage = `Previous response: "${lastAssistantMessage.content}"\n\nNew question: ${message}`;
            }

            const allMessages = [
                {
                    role: 'user',
                    content: contextualizedMessage
                }
            ];

            // Create messages but don't save them yet
            const userMessage = {
                id: Date.now().toString(),
                content: message,
                role: 'user',
                timestamp: new Date().toISOString()
            };

            const aiMessage = {
                id: (Date.now() + 1).toString(),
                content: '',
                role: 'assistant',
                timestamp: new Date().toISOString(),
                isStreaming: true
            };

            // Create streaming chat request
            const chatRequest = aiApi.createChatRequest(
                allMessages,
                selectedModel,
                {
                    temperature: conversation.settings.temperature,
                    streaming: true
                }
            );

            let streamedContent = '';
            let hasError = false;
            let isFirstChunk = true;

            // Handle streaming response
            await aiApi.generateChatResponse(chatRequest, (chunk) => {
                if (chunk === "[DONE]") return;

                try {
                    // New structure handling for model info and content
                    if (typeof chunk === 'object') {
                        if (chunk.type === 'model') {
                            // Store model info for later use
                            const modelInfo = chunk.data;
                            console.log('Using model:', modelInfo);
                            return;
                        } else if (chunk.type === 'content') {
                            // Process content chunk
                            const content = chunk.data;

                            // Check for error message
                            if (content.includes("Failed to communicate with the server")) {
                                hasError = true;
                                throw new Error(content);
                            }

                            streamedContent += content;

                            // Only save messages after we confirm we're getting valid response
                            if (isFirstChunk) {
                                // Add user message and initial AI message only after first valid chunk
                                saveConversation({
                                    ...conversation,
                                    messages: [...conversation.messages, userMessage],
                                    updatedAt: new Date().toISOString()
                                });
                                isFirstChunk = false;
                            }

                            // Update AI message with accumulated content
                            const updatedAiMessage = {
                                ...aiMessage,
                                content: streamedContent,
                                isStreaming: true
                            };

                            if (!hasError) {
                                saveConversation({
                                    ...conversation,
                                    model: selectedModel,
                                    messages: [...conversation.messages, userMessage, updatedAiMessage],
                                    updatedAt: new Date().toISOString()
                                });
                            }
                        }
                    } else {
                        // Legacy handling for string chunks (backwards compatibility)
                        if (chunk.includes("Failed to communicate with the server")) {
                            hasError = true;
                            throw new Error(chunk);
                        }

                        streamedContent += chunk;

                        if (isFirstChunk) {
                            saveConversation({
                                ...conversation,
                                messages: [...conversation.messages, userMessage],
                                updatedAt: new Date().toISOString()
                            });
                            isFirstChunk = false;
                        }

                        const updatedAiMessage = {
                            ...aiMessage,
                            content: streamedContent,
                            isStreaming: true
                        };

                        if (!hasError) {
                            saveConversation({
                                ...conversation,
                                model: selectedModel,
                                messages: [...conversation.messages, userMessage, updatedAiMessage],
                                updatedAt: new Date().toISOString()
                            });
                        }
                    }
                } catch (error) {
                    console.error('Error processing stream chunk:', error);
                    handleError(getErrorMessage());
                    hasError = true;
                    setLoading(false);
                    return;
                }
            });

            // Only proceed with final message save and title generation if no errors occurred
            if (!hasError) {
                const finalAiMessage = {
                    ...aiMessage,
                    content: streamedContent,
                    isStreaming: false
                };

                saveConversation({
                    ...conversation,
                    model: selectedModel,
                    messages: [...conversation.messages, userMessage, finalAiMessage],
                    updatedAt: new Date().toISOString()
                });

                // Title generation for new conversations
                if (conversation.messages.length === 0) {
                    const titlePrompt = [
                        {
                            content: 'You are a helpful writing assistant. Provide only the modified text without any explanations or additional context.',
                            role: 'system'
                        },
                        {
                            content: `Write a descriptive title ${message} ${streamedContent}`,
                            role: 'user'
                        }
                    ];

                    const titleRequest = aiApi.createChatRequest(
                        titlePrompt,
                        2,
                        {
                            temperature: 0.1,
                            maxTokens: 50,
                            forceFreeModel: true
                        }
                    );

                    try {
                        const titleResponse = await aiApi.generateChatResponse(titleRequest);
                        const generatedTitle = titleResponse.message.content.trim();

                        saveConversation({
                            ...conversation,
                            title: generatedTitle,
                            model: selectedModel,
                            messages: [...conversation.messages, userMessage, finalAiMessage],
                            updatedAt: new Date().toISOString()
                        });
                    } catch (titleError) {
                        console.error('Error generating title:', titleError);
                        saveConversation({
                            ...conversation,
                            title: message.length > 40 ? `${message.substring(0, 37)}...` : message,
                            model: selectedModel,
                            messages: [...conversation.messages, userMessage, finalAiMessage],
                            updatedAt: new Date().toISOString()
                        });
                    }
                }
            }

        } catch (err) {
            console.error('=== Debug: Error in sendMessage ===', err);
            handleError(getErrorMessage());

            // If this is a new conversation with no messages, delete it
            const conversation = getConversation(currentChat);
            if (conversation && conversation.messages.length === 0) {
                // Add a deleteConversation function to your chat logic
                deleteConversation(currentChat);
            }
        } finally {
            setLoading(false);
        }
    }, [currentChat, clearError, handleError, getConversation, saveConversation, selectedModel]);

    const updateMessage = useCallback(async (chatId, messageId, newContent) => {
        if (!chatId || !messageId || !newContent?.trim()) {
            return;
        }

        const conversation = getConversation(chatId);
        if (!conversation) {
            return;
        }

        try {
            // Find the index of the message being edited
            const messageIndex = conversation.messages.findIndex(msg => msg.id === messageId);
            if (messageIndex === -1) {
                return;
            }

            // Get the current message
            const currentMessage = conversation.messages[messageIndex];

            // Create edit history entry
            const editEntry = {
                content: currentMessage.content,
                timestamp: currentMessage.editedAt || currentMessage.timestamp,
                aiResponse: messageIndex + 1 < conversation.messages.length ?
                    conversation.messages[messageIndex + 1] : null
            };

            // Get or initialize edit history
            const editHistory = currentMessage.editHistory || [];

            // Get all messages up to and including the edited message
            let truncatedMessages = conversation.messages.slice(0, messageIndex + 1);

            // Update the edited message
            truncatedMessages[messageIndex] = {
                ...truncatedMessages[messageIndex],
                content: newContent.trim(),
                edited: true,
                editedAt: new Date().toISOString(),
                editHistory: [...editHistory, editEntry]
            };

            const updatedConversation = {
                ...conversation,
                messages: truncatedMessages,
                updatedAt: new Date().toISOString()
            };

            saveConversation(updatedConversation);

            // If this is a user message, we need to regenerate the AI response
            if (currentMessage.role === 'user') {
                // Clear any error states
                clearError();
                setLoading(true);

                try {
                    // Find the last assistant message before the edited message
                    const previousMessages = conversation.messages.slice(0, messageIndex);
                    const lastAssistantMessage = [...previousMessages]
                        .reverse()
                        .find(msg => msg.role === 'assistant');

                    // Create contextualized message similar to sendMessage function
                    let contextualizedMessage = newContent.trim();
                    if (lastAssistantMessage) {
                        contextualizedMessage = `Previous response: "${lastAssistantMessage.content}"\n\nNew question: ${newContent.trim()}`;
                    }

                    const chatRequest = aiApi.createChatRequest(
                        [{ role: 'user', content: contextualizedMessage }],
                        selectedModel,
                        conversation.settings.temperature
                    );

                    const response = await aiApi.generateChatResponse(chatRequest);
                    console.log("1 - response=", response);

                    const aiMessage = {
                        id: Date.now().toString(),
                        content: response.message.content,
                        role: 'assistant',
                        timestamp: new Date().toISOString()
                    };

                    // Add the AI response to the conversation
                    truncatedMessages = [...truncatedMessages, aiMessage];

                    // Save the final conversation with the new AI response
                    saveConversation({
                        ...updatedConversation,
                        messages: truncatedMessages,
                        updatedAt: new Date().toISOString()
                    });
                } catch (error) {
                    console.error('Error generating new response:', error);
                    handleError(error);
                } finally {
                    setLoading(false);
                }
            }
        } catch (error) {
            console.error('Error updating message:', error);
            handleError(error);
        }
    }, [getConversation, saveConversation, clearError, handleError, selectedModel, aiApi]);


    const updateSettings = useCallback((newSettings) => {
        if (!currentChat) return;

        const conversation = getConversation(currentChat);
        if (conversation) {
            saveConversation({
                ...conversation,
                settings: { ...conversation.settings, ...newSettings },
                updatedAt: new Date().toISOString()
            });
        }
    }, [currentChat, getConversation, saveConversation]);

    return {
        // State
        conversations,
        currentChat,
        loading,
        error,
        errorType,
        selectedModel,
        temperature,
        systemPrompt,
        useInternet,
        contextSummary,

        // Chat management
        setCurrentChat,
        createNewChat,
        updateConversationTitle,
        deleteConversation,
        createChatBranch: createBranch,

        // Message handling
        sendMessage,
        updateMessage,

        // Settings
        setSelectedModel: setModelId,
        setTemperature,
        setSystemPrompt,
        setUseInternet,
        updateSettings,

        // Search functionality
        searchConversations,

        // Error handling
        clearError,
        setError
    };
};

export default useChatLogic;