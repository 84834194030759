import journalApi from "../config/JournalApiConfig";
import { formatDate } from "../utils/dateUtils";

/**
 * Interface for task assignment request
 */
interface TaskAssignmentRequest {
    requesting_username: string;  // Username of person assigning the task
    target_username: string;     // Username of person receiving the task
    project_name: string;        // Name of the project
    task_content: string;        // Description of the task
    entry_date: Date;           // Date to add the task to their journal
}

/**
 * Interface for API response
 */
interface ApiResponse {
    message: string;
    error?: string;
}

/**
 * Service for handling integration between projects and journal entries
 */
const IntegrationApiService = {
    /**
     * Assigns a task to a user by adding it to their journal entry.
     * The assigned date is automatically set to today.
     * 
     * @param request - Task assignment details including requesting username
     * @returns Promise with success message or error
     * 
     * @throws Error if the API call fails
     * @throws Error if the user doesn't have permission
     * 
     * @example
     * ```typescript
     * const request = {
     *   requesting_username: "alice",
     *   target_username: "john",
     *   project_name: "Project X",
     *   task_content: "Review PR #123",
     *   entry_date: new Date()
     * };
     * 
     * try {
     *   const result = await IntegrationApiService.assignTask(request);
     *   console.log(result.message); // "Task added successfully"
     * } catch (error) {
     *   console.error(error);
     * }
     * ```
     */
    assignTask: async (request: TaskAssignmentRequest): Promise<ApiResponse> => {
        try {
            const { requesting_username, ...requestBody } = request;

            // Format dates for API and set assigned_date to today
            const formattedRequest = {
                ...requestBody,
                entry_date: formatDate(request.entry_date),
                assigned_date: formatDate(new Date())
            };

            // Make API call to append task
            const response = await journalApi.post<ApiResponse>(
                `/entries/${requesting_username}/append_task`,
                formattedRequest
            );

            return response.data;
        } catch (error: any) {
            // Handle specific error cases
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        throw new Error("Authentication required. Please log in again.");
                    case 403:
                        throw new Error("You don't have permission to assign tasks in this project.");
                    case 404:
                        throw new Error("Project or user not found.");
                    case 500:
                        throw new Error("Server error. Please try again later.");
                    default:
                        throw new Error(error.response.data?.detail || "Failed to assign task.");
                }
            }

            // Handle network or other errors
            throw new Error("Network error or service unavailable.");
        }
    },

    /**
     * Utility function to validate task assignment request
     * Can be used before making the API call
     * 
     * @param request - Task assignment request to validate
     * @returns true if valid, throws error if invalid
     */
    validateTaskAssignment: (request: TaskAssignmentRequest): boolean => {
        if (!request.requesting_username?.trim()) {
            throw new Error("Requesting username is required");
        }

        if (!request.target_username?.trim()) {
            throw new Error("Target username is required");
        }

        if (!request.project_name?.trim()) {
            throw new Error("Project name is required");
        }

        if (!request.task_content?.trim()) {
            throw new Error("Task content is required");
        }

        if (!request.entry_date) {
            throw new Error("Entry date is required");
        }

        return true;
    }
};

export default IntegrationApiService;