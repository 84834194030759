import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { formatDate } from "../../utils/dateUtils";
import { safeJsonParse } from "../../utils/functionUtils";

const SaveHandler = ({
    apiService,
    selectedDate,
    unsavedEntries,
    currentContent,
    setEntries,
    setUnsavedEntries,
    setCurrentContent,
    setError,
    setIsLoading,
    setSaveError
}) => {
    const { user } = useAuth();

    const preprocessContent = async (content) => {
        const processNode = async (node) => {
            if (node.type === 'image' && (!node.attrs.width || !node.attrs.height)) {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = () => {
                        node.attrs.width = img.naturalWidth;
                        node.attrs.height = img.naturalHeight;
                        resolve(node);
                    };
                    img.src = node.attrs.src;
                });
            }
            if (node.content) {
                node.content = await Promise.all(node.content.map(processNode));
            }
            return node;
        };

        return processNode(content);
    };

    const handleSave = async (contentToSave = null) => {
        if (!apiService) {
            console.log("API service not yet loaded");
            setSaveError("API service not available");
            return false;
        }

        setError(null);
        setSaveError(null);

        if (selectedDate && user && user.username) {
            const dateKey = formatDate(selectedDate);
            const content = contentToSave || unsavedEntries[dateKey] || currentContent;

            try {
                setIsLoading(true);

                // Move the heavy JSON processing into a separate tick
                const processedContentString = await new Promise((resolve) => {
                    setTimeout(async () => {
                        const processedContent = await preprocessContent(content);
                        const contentString = JSON.stringify(processedContent);
                        resolve(contentString);
                    }, 0);
                });

                const result = await apiService.addEntry(
                    user.username,
                    dateKey,
                    processedContentString
                );

                if (!result.success) {
                    if (result.status === 413) {
                        throw new Error("Entry content is too large. Try reduce TEXT. NOT images!");
                    }
                    throw new Error(result.error || "Failed to save entry");
                }

                setEntries((prevEntries) => ({
                    ...prevEntries,
                    [dateKey]: content,
                }));

                setUnsavedEntries((prevUnsaved) => {
                    const updatedUnsaved = { ...prevUnsaved };
                    delete updatedUnsaved[dateKey];
                    return updatedUnsaved;
                });

                setCurrentContent(content);
                return true;
            } catch (err) {
                console.error("Failed to save entry:", err);
                setError(err.message);
                setSaveError("Failed to save. Please try again.");
                return false;
            } finally {
                setIsLoading(false);
            }
        } else {
            setSaveError("Unable to save: missing date or user information");
        }
        return false;
    };

    return { handleSave };
};

export default SaveHandler;