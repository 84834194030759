// components/CompletedTasksChart.jsx
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        const date = new Date(data.date);
        return (
            <div className="bg-white p-2 border rounded">
                <p className="mb-0">{date.toLocaleDateString()}</p>
                <p className="mb-0">{`${data.count} task${data.count !== 1 ? 's' : ''}`}</p>
            </div>
        );
    }
    return null;
};

const dayTickFormatter = (tick) => {
    return new Date(tick).getDate();
};

const renderMonthTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value } = payload;
    const date = new Date(value);
    const day = date.getDate();

    if (day === 1) {
        const monthName = date.toLocaleString('default', { month: 'long' });
        return (
            <g>
                <text
                    x={x}
                    y={y - 4}
                    textAnchor="start"
                    fill="#666"
                    fontSize={12}
                >
                    {monthName}
                </text>
                <path d={`M${x},${y - 4}v${-35}`} stroke="#ff0000" />
            </g>
        );
    }
    return null;
};

const CompletedTasksChart = ({ data, onBarClick }) => {
    return (
        <div style={{ height: '300px' }}>
            <h6 className="mb-3">Task Completion Overview</h6>
            <div style={{ height: 'calc(100% - 30px)' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={data}
                        onClick={onBarClick}
                        margin={{ top: 40, right: 5, left: 5, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            tickFormatter={dayTickFormatter}
                            stroke="#666"
                        />
                        <XAxis
                            dataKey="date"
                            axisLine={false}
                            tickLine={false}
                            interval={0}
                            tick={renderMonthTick}
                            xAxisId="month"
                            height={50}
                        />
                        <YAxis
                            allowDecimals={false}
                            stroke="#666"
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar
                            dataKey="count"
                            fill="#0d6efd"
                            radius={[2, 2, 0, 0]}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default CompletedTasksChart;