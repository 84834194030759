// PromptNavigation.jsx
import React from 'react';

const PromptNavigation = ({ message }) => {
    if (message.role !== 'user') return null;

    // Calculate the edit count: 1 for original + number of edits
    const editCount = message.editHistory ? message.editHistory.length + 1 : 1;

    return (
        <small className="text-muted">
            Version {editCount}
        </small>
    );
};

export default PromptNavigation;