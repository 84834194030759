import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import { Search, Sparkles } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const ModelSelector = forwardRef(({
    isLoading,
    selectedModel,
    availableModels,
    onModelChange,
    onNewChat
}, ref) => {
    const [showModelList, setShowModelList] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { user, isSubscriptionActive } = useAuth();

    // Check if user is on trial plan using both legacy and new subscription data
    const isTrialPlan = user?.planName === 'Free Trial' ||
        user?.main_subscription?.is_trial === true;

    useImperativeHandle(ref, () => ({
        openModal: () => setShowModelList(true)
    }));

    const cleanModelName = (name) => {
        return name
            .replace(/^OR\s*-\s*[\w-]+\//, '')
            .replace(/:free$/, '');
    };

    const getSelectedModelName = () => {
        if (!selectedModel) return 'Select Model';
        const model = availableModels.find(m => m.id === selectedModel);
        return model ? cleanModelName(model.name) : 'Select Model';
    };

    const handleModelChange = (modelId) => {
        onModelChange(modelId);
        // onNewChat(); // Create a new chat when model changes
        setShowModelList(false);
    };

    const filteredModels = availableModels.filter(model =>
        cleanModelName(model.name).toLowerCase().includes(searchTerm.toLowerCase()) ||
        (model.description && model.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        <div className="flex-grow-1 me-2">
            <Modal
                show={showModelList}
                onHide={() => setShowModelList(false)}
                fullscreen
                className="model-list-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Model</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="sticky-top bg-white border-bottom">
                        {isTrialPlan && (
                            <div className="m-3">
                                <Alert variant="light" className="border mb-0 bg-gradient">
                                    <div className="d-flex align-items-center gap-2">
                                        <Sparkles className="text-purple" size={20} />
                                        <div className="flex-grow-1">
                                            <strong className="text-purple">Unlock 200+ Premium Models</strong>
                                            {" "}– Upgrade now to access advanced models like GPT-4, Claude 3, and Gemini Pro.
                                            Experience faster responses, enhanced capabilities, and exclusive features.
                                            <Button
                                                variant="link"
                                                className="text-decoration-none p-0 ms-1"
                                                onClick={() => window.open('/upgrade', '_blank')}
                                            >
                                                Learn more →
                                            </Button>
                                        </div>
                                    </div>
                                </Alert>
                            </div>
                        )}
                        <div className="p-3">
                            <div className="position-relative">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search models..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <Search className="position-absolute" style={{ right: '12px', top: '50%', transform: 'translateY(-50%)' }} size={20} />
                            </div>
                        </div>
                    </div>
                    <div className="p-3">
                        <div className="row g-3">
                            {filteredModels.map(model => (
                                <div key={model.id} className="col-12 col-md-6 col-lg-4">
                                    <div
                                        className={`card h-100 ${selectedModel === model.id ? 'border-primary' : ''}`}
                                        onClick={() => handleModelChange(model.id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div className="card-body">
                                            <h5 className="card-title mb-2">
                                                {cleanModelName(model.name)}
                                            </h5>
                                            <p className="card-text text-muted" style={{ fontSize: '0.9rem' }}>
                                                {model.description || 'No description available'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
});

export default ModelSelector;