// AIToolbarMenu.jsx
import React, { useState, useRef } from 'react';
import { useEditor } from '../EditorContext';
import { toast } from 'react-toastify';
import { Button, Form, Spinner, Dropdown } from 'react-bootstrap';
import aiApi from '../../../services/aiApiService';
import {
    Wand2,
    Files,
    Maximize,
    Minimize,
    MessageSquarePlus,
    Bot,
    Send,
    X,
    History,
    MessageSquare,
    CheckSquare
} from 'lucide-react';

const AIToolbarMenu = () => {
    const editor = useEditor();
    const [loading, setLoading] = useState(false);
    const [activeOperation, setActiveOperation] = useState(null);
    const [customPrompt, setCustomPrompt] = useState('');
    const [showPromptInput, setShowPromptInput] = useState(false);
    const textareaRef = useRef(null);
    const [promptHistory, setPromptHistory] = useState([]);
    const [promptHistoryIndex, setPromptHistoryIndex] = useState(-1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const actions = [
        { id: 'summarizeSelection', label: 'Summarize', icon: <Files size={16} /> },
        { id: 'simplifySelection', label: 'Simplify', icon: <Wand2 size={16} /> },
        { id: 'extractKeyPoints', label: 'Extract Key Points', icon: <Files size={16} /> },
        { id: 'restructureText', label: 'Restructure', icon: <Files size={16} /> },
        { id: 'excitedTone', label: 'Use Excited Tone', icon: <MessageSquarePlus size={16} /> },
        { id: 'extendText', label: 'Expand', icon: <Maximize size={16} /> },
        { id: 'reduceText', label: 'Reduce', icon: <Minimize size={16} /> },
        { id: 'addAIComment', label: 'Add Comment', icon: <MessageSquare size={16} /> },
        {
            id: 'createTasks',
            label: 'Create Tasks',
            icon: <CheckSquare size={16} />,
            description: 'Generate a checklist of tasks'
        },
    ];

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setCustomPrompt(newValue);

        requestAnimationFrame(() => {
            if (textareaRef.current) {
                textareaRef.current.selectionStart = newValue.length;
                textareaRef.current.selectionEnd = newValue.length;
            }
        });
    };

    const handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
            e.preventDefault();
            handleCustomPrompt();
            return;
        }

        if (e.key === 'Escape') {
            e.preventDefault();
            if (showHistory) {
                setShowHistory(false);
            } else {
                setShowPromptInput(false);
                setCustomPrompt('');
                setPromptHistoryIndex(-1);
                setDropdownOpen(false);
            }
            return;
        }

        if (e.key === 'ArrowUp' && e.altKey) {
            e.preventDefault();
            if (promptHistoryIndex < promptHistory.length - 1) {
                const newIndex = promptHistoryIndex + 1;
                setPromptHistoryIndex(newIndex);
                setCustomPrompt(promptHistory[newIndex]);
            }
        } else if (e.key === 'ArrowDown' && e.altKey) {
            e.preventDefault();
            if (promptHistoryIndex > -1) {
                const newIndex = promptHistoryIndex - 1;
                setPromptHistoryIndex(newIndex);
                setCustomPrompt(newIndex === -1 ? '' : promptHistory[newIndex]);
            }
        }
    };

    const addToHistory = (prompt) => {
        setPromptHistory(prev => {
            const newHistory = [prompt, ...prev.slice(0, 9)];
            return newHistory;
        });
    };

    const handleCustomPrompt = async () => {
        if (!customPrompt.trim()) {
            toast.warning('Please enter a prompt');
            return;
        }

        setIsSubmitting(true);

        try {
            addToHistory(customPrompt);
            await handleAction('customAIOperation', customPrompt);

            setCustomPrompt('');
            setPromptHistoryIndex(-1);
            setShowHistory(false);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleAction = async (action, promptOverride = null) => {
        if (!editor) {
            console.error('No editor instance');
            return;
        }

        console.log('Handler called for action:', action);
        setLoading(true);
        setActiveOperation(action);

        try {
            let result;
            if (action === 'createTasks') {
                const userPrompt = promptOverride || customPrompt;
                const finalPrompt = editor.state.selection.content().size
                    ? 'Convert this text into a checklist of specific, actionable tasks: '
                    : `Create a checklist of specific, actionable tasks for: ${userPrompt}`;

                result = await editor.commands.createTasks(finalPrompt);
            } else if (action === 'customAIOperation') {
                result = await editor.commands.summarizeSelection(promptOverride);
            } else if (action === 'addAIComment') {
                result = await editor.commands.generateAIComment();
            } else {
                result = await editor.commands[action]();
            }

            if (result) {
                toast.success(
                    action === 'createTasks'
                        ? 'Tasks created successfully'
                        : action === 'addAIComment'
                            ? 'Comment added'
                            : 'Text processed successfully'
                );

                if (action === 'createTasks') {
                    setCustomPrompt('');
                    setPromptHistoryIndex(-1);
                    setShowHistory(false);
                    setDropdownOpen(false);
                }
            } else {
                toast.warning('No changes were made');
            }
        } catch (error) {
            console.error('Error processing text:', error);
            toast.error(`Error: ${error.message}`);
        } finally {
            setLoading(false);
            setActiveOperation(null);
        }
    };

    const CustomPromptForm = () => (
        <Form
            className="px-3 py-2"
            style={{ minWidth: '300px' }}
            onSubmit={(e) => {
                e.preventDefault();
                handleCustomPrompt();
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <Form.Group>
                <div className="position-relative">
                    <Form.Control
                        ref={textareaRef}
                        as="textarea"
                        rows={2}
                        placeholder="Enter your prompt... (Ctrl+Enter to submit, Alt+↑↓ for history)"
                        value={customPrompt}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        className="mb-2 pe-4"
                        disabled={isSubmitting}
                        autoFocus
                    />
                    <div className="position-absolute" style={{ right: '0.5rem', top: '0.5rem' }}>
                        {customPrompt && (
                            <Button
                                variant="link"
                                className="p-0 me-2"
                                onClick={() => setCustomPrompt('')}
                            >
                                <X size={14} />
                            </Button>
                        )}
                        {promptHistory.length > 0 && (
                            <Button
                                variant="link"
                                className="p-0"
                                onClick={() => setShowHistory(!showHistory)}
                                title="Show prompt history"
                            >
                                <History size={14} />
                            </Button>
                        )}
                    </div>
                </div>

                {showHistory && promptHistory.length > 0 && (
                    <div className="mb-2 border rounded p-2" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                        {promptHistory.map((prompt, index) => (
                            <div
                                key={index}
                                className="cursor-pointer p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                                onClick={() => {
                                    setCustomPrompt(prompt);
                                    setShowHistory(false);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {prompt.length > 50 ? prompt.substring(0, 47) + '...' : prompt}
                            </div>
                        ))}
                    </div>
                )}

                <div className="d-flex gap-2">
                    <Button
                        variant="success"
                        size="sm"
                        onClick={handleCustomPrompt}
                        disabled={isSubmitting || !customPrompt.trim()}
                        className="flex-grow-1 d-flex align-items-center justify-content-center gap-2"
                    >
                        {isSubmitting ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span>Processing...</span>
                            </>
                        ) : (
                            <>
                                <Send size={14} />
                                <span>Send (Ctrl+Enter)</span>
                            </>
                        )}
                    </Button>
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                            setShowPromptInput(false);
                            setCustomPrompt('');
                            setPromptHistoryIndex(-1);
                            setShowHistory(false);
                            setDropdownOpen(false);
                        }}
                        disabled={isSubmitting}
                    >
                        Cancel (Esc)
                    </Button>
                </div>
            </Form.Group>
        </Form>
    );

    return (
        <Dropdown
            show={dropdownOpen}
            onToggle={(isOpen) => {
                if (isOpen) {
                    setDropdownOpen(true);
                } else if (!showPromptInput) {
                    setDropdownOpen(false);
                }
            }}
        >
            <Dropdown.Toggle
                variant="outline-secondary"
                size="sm"
                id="ai-actions-dropdown"
                className="d-flex align-items-center gap-1"
                style={{ border: "none", boxShadow: "none" }}
            >
                <Bot size={16} />
                <span>AI</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-2" style={{
                zIndex: 60,
                maxHeight: showPromptInput ? '600px' : '400px',
                overflowY: 'auto',
                transition: 'max-height 0.2s ease-in-out'
            }}>
                {actions.map(({ id, label, icon }) => (
                    <Dropdown.Item
                        key={id}
                        onClick={() => {
                            handleAction(id);
                            setDropdownOpen(false);
                        }}
                        disabled={loading}
                        className="d-flex align-items-center justify-between px-3 py-2"
                    >
                        <span className="d-flex align-items-center">
                            {icon}
                            <span className="ms-2">{label}</span>
                        </span>
                        {loading && activeOperation === id && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </Dropdown.Item>
                ))}

                <Dropdown.Divider />

                {showPromptInput ? (
                    <CustomPromptForm />
                ) : (
                    <Dropdown.Item
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowPromptInput(true);
                        }}
                        className="d-flex align-items-center px-3 py-2"
                    >
                        <MessageSquarePlus size={16} />
                        <span className="ms-2">Custom Prompt</span>
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default AIToolbarMenu;