// utils/dateUtils.js

// Format date to YYYY-MM-DD
export const formatDate = (date) => {
    return date.toISOString().split('T')[0];
};

// Generate all dates in range
export const generateDateRange = (start, end) => {
    const dates = [];
    const current = new Date(start);
    const endDate = new Date(end);

    while (current <= endDate) {
        dates.push(new Date(current));
        current.setDate(current.getDate() + 1);
    }
    return dates;
};

// Transform tasks data for chart
export const transformDataForChart = (tasks, start, end) => {
    const dateRange = generateDateRange(start, end);
    return dateRange.map(date => {
        const dateStr = date.toISOString().split('T')[0];
        return {
            date: dateStr,
            day: date.getDate(),
            monthIndex: date.getMonth(),
            count: tasks.filter(task => task.date === dateStr).length
        };
    });
};