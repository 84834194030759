import React, { useState } from 'react';
import { Modal, Button, Form, Spinner, Alert, Card } from 'react-bootstrap';
import { CheckCircle, Code } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const ModelRecommendationModal = ({
    show,
    onHide,
    userDescription,
    setUserDescription,
    handleGetRecommendation,
    isGettingRecommendation,
    error,
    selectedModel,
    recommendations,
    onModelSelect
}) => {
    const [selectedRecommendation, setSelectedRecommendation] = useState(null);
    const { user, isSubscriptionActive } = useAuth();

    // Check if user is on trial plan using both legacy and new subscription data
    const isTrialPlan = user?.planName === 'Free Trial' ||
        user?.main_subscription?.is_trial === true;

    const handleModelSelect = () => {
        if (selectedRecommendation) {
            onModelSelect(selectedRecommendation);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Model Recommendation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger" className="mb-3">
                        {error}
                    </Alert>
                )}

                {!recommendations ? (
                    <Form>
                        <Form.Group>
                            <Form.Label>What would you like to do?</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={userDescription}
                                onChange={(e) => setUserDescription(e.target.value)}
                                placeholder="E.g., I need to analyze scientific papers and summarize their findings..."
                                isInvalid={!!error}
                            />
                            <Form.Text className="text-muted">
                                Be specific about your task to get the most suitable model recommendation.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                ) : (
                    <div className="recommendations-container">
                        <p className="mb-3">Select a model that best fits your needs:</p>
                        <div className="d-flex flex-column gap-3">
                            {recommendations.recommendations.map((rec, index) => (
                                <Card
                                    key={index}
                                    className={`cursor-pointer ${selectedRecommendation?.model === rec.model ? 'border-primary' : ''}`}
                                    onClick={() => setSelectedRecommendation(rec)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Card.Body>
                                        <div className="d-flex align-items-start gap-3">
                                            <div className="flex-shrink-0">
                                                <Code size={24} className="text-primary" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <Card.Title className="h6 mb-2">
                                                    {rec.model}
                                                </Card.Title>
                                                <Card.Text className="text-muted">
                                                    {rec.reasoning}
                                                </Card.Text>
                                            </div>
                                            {selectedRecommendation?.model === rec.model && (
                                                <div className="flex-shrink-0">
                                                    <CheckCircle size={24} className="text-success" />
                                                </div>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                {!recommendations ? (
                    <>
                        <Button variant="secondary" onClick={onHide}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleGetRecommendation}
                            disabled={!userDescription.trim() || isGettingRecommendation}
                        >
                            {isGettingRecommendation ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                    />
                                    Getting Recommendations...
                                </>
                            ) : (
                                'Get Recommendations'
                            )}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button variant="secondary" onClick={() => window.location.reload()}>
                            Start Over
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleModelSelect}
                            disabled={!selectedRecommendation}
                        >
                            Use Selected Model
                        </Button>
                        {isTrialPlan && (
                            <p className="text-muted" ><small>Upgrade to gain access to ~200 additional models, including premium models.</small></p>
                        )}
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default ModelRecommendationModal;