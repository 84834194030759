// extensions/MermaidExtension.js
import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { MermaidNode } from './MermaidNode'
import { MermaidSuggestion } from './MermaidSuggestion'

export const MermaidExtension = Node.create({
    name: 'mermaid',

    group: 'block',

    content: 'text*',

    marks: '',

    parseHTML() {
        return [
            {
                tag: 'div[data-type="mermaid"]',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', { ...HTMLAttributes, 'data-type': 'mermaid' }, 0]
    },

    addAttributes() {
        return {
            content: {
                default: '',
            },
            diagramType: {
                default: 'flowchart',
            },
            theme: {
                default: 'default',
            },
            prompt: {
                default: '',
            },
            lastUpdated: {
                default: null,
            }
        }
    },

    addNodeView() {
        return ReactNodeViewRenderer(MermaidNode)
    },

    addCommands() {
        return {
            insertMermaid: attributes => ({ chain }) => {
                return chain()
                    .insertContent({
                        type: this.name,
                        attrs: attributes
                    })
                    .run()
            },
        }
    },
})

export { MermaidSuggestion }
export default MermaidExtension