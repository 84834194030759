// src/services/LoginApiService.js
import adminApiConfig from '../config/adminApiConfig';

const LoginApiService = {

  login: async (email, password) => {
    const formData = new FormData();
    formData.append('username', email);
    formData.append('password', password);

    try {
      const response = await adminApiConfig.post('/api/auth/login', formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });

      return response.data;
    } catch (error) {
      console.error('Login error:', error);

      if (error.response) {
        // For rate limiting (429)
        if (error.response.status === 429) {
          throw new Error('Rate limit exceeded. Please try again later.');
        }

        // For subscription errors (403), enhance the error object with additional info
        if (error.response.status === 403) {
          const detail = error.response.data.detail;
          error.subscriptionStatus = {
            isSubscriptionError: detail.includes('subscription') || detail.includes('trial'),
            requiresRenewal: detail.includes('expired') || detail.includes('renew'),
            requiresPayment: detail.includes('payment') || detail.includes('pending'),
            isCancelled: detail.includes('cancelled'),
            isTrialEnded: detail.includes('trial') && detail.includes('ended')
          };
        }
        throw error;
      } else if (error.request) {
        throw new Error('No response received from server');
      } else {
        throw new Error('Error setting up the request');
      }
    }
  },
  logout: async () => {
    try {
      const response = await adminApiConfig.post('/api/auth/logout');
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getCurrentUser: async () => {
    try {
      const response = await adminApiConfig.get('/api/auth/current_user');
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        return null;
      }
      throw error;
    }
  },
  refreshToken: async () => {
    try {
      // We don't need to send the refresh token, it's in the HttpOnly cookie
      const response = await adminApiConfig.post('/api/auth/refresh');
      // console.log("refreshToken: ", response);
      const { access_token } = response.data;
      adminApiConfig.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return access_token;
    } catch (error) {
      throw error;
    }
  },
  getAllUsers: async (search = '', skip = 0, limit = 10) => {
    try {
      const params = { skip, limit };
      if (search) {
        params.search = search;
      }

      const response = await adminApiConfig.get('/api/admin/users', { params });
      return response.data; // Assuming this is the array of users
    } catch (err) {
      console.error('Failed to fetch users:', err);
      throw new Error('Failed to fetch users. Please try again later.');
    }
  },
  /**
 * Delete a user's account by ID. Only administrators can perform this action.
 * @param {number} userId - The ID of the user to delete.
 * @returns {Promise<Object>} Deletion confirmation.
 * @property {string} detail - A message confirming the deletion.
 * @throws {Error} If the user is not found, if trying to delete own account, or if there's an issue deleting the user.
 */
  deleteUser: async (userId) => {
    try {
      const response = await adminApiConfig.delete(`/api/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  },
  /**
   * Register new user
   * @param {string} email - User's email
   * @param {string} username - User's username
   * @param {string} password - User's password
   * @returns {Promise<Object>} - Response data containing registration confirmation
   * @throws {Error} - If registration fails
   */
  register: async (email, username, password) => {
    try {
      const response = await adminApiConfig.post('/api/auth/register', {
        email,
        username,
        password,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createVerification: async (email, TaskPlexPlanId) => {
    try {
      const response = await adminApiConfig.post(`/api/auth/create-verification`, {
        email,
        TaskPlexPlanId
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  verifyEmailToken: async (token) => {
    try {
      const response = await adminApiConfig.post('/api/auth/verify-email-verification-token', {
        token: token
      });
      return response.data; // Contains email and planId
    } catch (error) {
      throw error;
    }
  },
  createUserTrialUser: async (userData) => {
    try {
      const response = await adminApiConfig.post('/api/auth/create-trial-user', userData, {
        withCredentials: true // This ensures cookies are sent with the request
      });
      return response.data;
    } catch (err) {
      console.error('Failed to create user:', err);
      if (err.response && err.response.data && err.response.data.detail) {
        throw new Error(err.response.data.detail);
      }
      throw new Error('Failed to create trial user. Please try again later.');
    }
  },
  createUser: async (userData) => {
    try {
      const response = await adminApiConfig.post('/api/auth/create-user', userData, {
        withCredentials: true // This ensures cookies are sent with the request
      });
      return response.data;
    } catch (err) {
      console.error('Failed to create user:', err);
      if (err.response && err.response.data && err.response.data.detail) {
        throw new Error(err.response.data.detail);
      }
      throw new Error('Failed to create user. Please try again later.');
    }
  },
  getUserAccountInfo: async () => {
    try {
      const response = await adminApiConfig.get('/api/users/account');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch user account info:', error);
      throw error;
    }
  },

  // ====================== Password reset =====================================

  requestPasswordReset: async (email) => {
    try {
      const formData = new URLSearchParams();
      formData.append('username', email);
      formData.append('password', 'placeholder');

      const response = await adminApiConfig.post('/api/auth/password-reset-request', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error requesting password reset:', error);
      throw error;
    }
  },

  resetPassword: async (token, newPassword) => {
    try {
      const response = await adminApiConfig.post('/api/auth/reset-password', {
        token,
        new_password: newPassword,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Clear any stored auth tokens or user data here
      localStorage.removeItem('user');
      localStorage.removeItem('token');

      return response.data;
    } catch (error) {
      console.error('Error resetting password:', error);
      throw error;
    }
  },

  // ========================= User agreements =============================

  // Check agreement status
  checkStatus: async (terms_of_service) => {
    try {
      const response = await fetch(`/api/agreements/status/${terms_of_service}`);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;

    } catch (error) {
      console.error('Error in checkStatus:', error);
      throw error;
    }
  },

  acceptAgreement: async (agreementType, version) => {
    try {
      const response = await fetch('/api/agreements/accept', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          agreement_type: agreementType,
          version: version
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;

    } catch (error) {
      console.error('Error in acceptAgreement:', error);
      throw error;
    }
  },
  getActiveAgreements: async () => {
    try {
      const response = await fetch('/api/agreements/active')

      return response.data;

    } catch (error) {
      console.error('Error requesting getActiveAgreements:', error);
      throw error;
    }
  },


  // ================================= PayPal =====================================

  createPayPalSubscription: async ({ TaskPlexPlanId, PayPalPlanId, Upgrade_from_trial }) => {
    // console.log("Creating PayPal subscription with parameters:", {
    //   TaskPlexPlanId,
    //   PayPalPlanId,
    //   Upgrade_from_trial
    // });

    try {
      const response = await fetch('/api/paypal/subscription/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          TaskPlexPlanId,
          PayPalPlanId,
          Upgrade_from_trial
        })
      });

      if (!response.ok) {
        // If the response is not OK, parse the error message
        const errorData = await response.json();
        console.error(`Failed to create subscription. Status: ${response.status}, Error: ${errorData.detail}`);
        throw new Error(`Subscription creation failed: ${errorData.detail}`);
      }

      // Parse and return the JSON response (which includes subscriptionID)
      const data = await response.json();
      // console.log("Subscription created successfully. Subscription ID:", data.subscriptionID);
      return data;

    } catch (error) {
      console.error("Error creating PayPal subscription:", error);
      throw error;
    }
  },
  getSubscriptionStatus: async (subscriptionId) => {
    try {
      const response = await adminApiConfig.get(`/api/paypal/subscriptions/${subscriptionId}/status`);
      return response.data;
    } catch (error) {
      console.error('Error checking subscription status:', error);
      throw error;
    }
  },
  checkSubscriptionValidity: async () => {
    try {
      const response = await fetch('/api/paypal/subscription/check', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error(`Failed to check subscription validity. Status: ${response.status}, Error: ${errorData.detail}`);
        throw new Error(`Subscription check failed: ${errorData.detail}`);
      }

      const data = await response.json();
      // console.log("CHECKSUBSCRIPTIONVALIDITY, data=", data);
      return {
        isValid: data.is_valid,
        userEmail: data.user_email
      };

    } catch (error) {
      console.error("Error checking subscription validity:", error);
      throw error;
    }
  }

};

export default LoginApiService;