// File: src/components/AiChat/SearchInput.jsx
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Search, X } from 'lucide-react';

const SearchInput = ({ value, onChange, onClear }) => {
    return (
        <InputGroup className="mb-3">
            <InputGroup.Text className="bg-white border-end-0">
                <Search size={16} />
            </InputGroup.Text>
            <Form.Control
                placeholder="Search conversations..."
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="border-start-0"
            />
            {value && (
                <InputGroup.Text
                    className="bg-white border-start-0 cursor-pointer"
                    onClick={onClear}
                    style={{ cursor: 'pointer' }}
                >
                    <X size={16} />
                </InputGroup.Text>
            )}
        </InputGroup>
    );
};

export default SearchInput;