// ProjectSharing.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup, Badge } from 'react-bootstrap';
import { UserPlus, Eye, Edit2, Trash2, Mail } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import ProjectApiService from '../../services/projectApiService';
import InviteApiService from '../../services/InviteApiService';

/**
 * ProjectSharingList displays and manages the list of users a project is shared with.
 * It handles both displaying the current sharing status and managing unshare operations.
 */
export const ProjectSharingList = ({ project, sharingInfo, onUpdateSharing }) => {
    // Track loading state during unshare operations
    const [isLoadingUnshare, setIsLoadingUnshare] = useState(false);
    // Single state for operation feedback (success/error messages)
    const [operationStatus, setOperationStatus] = useState(null);
    const { user } = useAuth();

    // Handle removing a user's access to the project
    const handleUnshare = async (sharedUsername) => {
        // Clear any existing status messages and set loading state
        setOperationStatus(null);
        setIsLoadingUnshare(true);

        try {
            // Call API to unshare the project
            await ProjectApiService.unshareProject(
                user.username,
                project.name,
                sharedUsername
            );

            // Show success message
            setOperationStatus({
                type: 'success',
                message: `Project is no longer shared with ${sharedUsername}`
            });

            // Notify parent component to refresh project data if needed
            if (onUpdateSharing) {
                await onUpdateSharing();
            }
        } catch (error) {
            console.error('Failed to unshare project:', error);
            setOperationStatus({
                type: 'error',
                message: 'Unable to remove access. Please try again.'
            });
        } finally {
            setIsLoadingUnshare(false);
        }
    };

    return (
        <div>
            {/* Status message section - shows success or error messages */}
            {operationStatus && (
                <div
                    className={`alert alert-${operationStatus.type === 'success' ? 'success' : 'danger'} mb-3`}
                    role="alert"
                >
                    {operationStatus.message}
                </div>
            )}

            {/* List of users the project is shared with */}
            <ListGroup>
                {sharingInfo.map((share) => (
                    <ListGroup.Item
                        key={share.shared_with_username}
                        className="d-flex justify-content-between align-items-center"
                    >
                        {/* User information and permission badge */}
                        <div>
                            <span className="me-2">{share.shared_with_username}</span>
                            <Badge bg={share.permissions === 'write' ? 'success' : 'info'}>
                                {share.permissions === 'write' ? (
                                    <><Edit2 size={14} /> Editor</>
                                ) : (
                                    <><Eye size={14} /> Viewer</>
                                )}
                            </Badge>
                        </div>

                        {/* Unshare button with loading state */}
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleUnshare(share.shared_with_username)}
                            disabled={isLoadingUnshare}
                            aria-label={`Remove access for ${share.shared_with_username}`}
                        >
                            {isLoadingUnshare ? '...' : <Trash2 size={14} />}
                        </Button>
                    </ListGroup.Item>
                ))}

                {/* Empty state message - only shown when there are no shares and no status message */}
                {sharingInfo.length === 0 && !operationStatus && (
                    <ListGroup.Item className="text-muted text-center">
                        This project isn't shared with anyone yet
                    </ListGroup.Item>
                )}
            </ListGroup>
        </div>
    );
};

/**
 * ShareProjectModal provides the interface for sharing a project with other users.
 * It includes a form for entering the username and selecting permissions,
 * and displays the current list of users the project is shared with.
 */
export const ShareProjectModal = ({
    show,
    onHide,
    project,
    sharingInfo,
    onShareUpdate
}) => {
    console.log('ShareProjectModal render with:', { show, project, sharingInfo });
    const [username, setUsername] = useState('');
    const [permission, setPermission] = useState('read');
    const [isLoadingShare, setIsLoadingShare] = useState(false);
    const [shareError, setShareError] = useState(null);
    const [shareSuccess, setShareSuccess] = useState(false);

    // State for invitation
    const [isLoadingInvite, setIsLoadingInvite] = useState(false);
    const [inviteError, setInviteError] = useState(null);
    const [inviteSuccess, setInviteSuccess] = useState(false);

    const { user } = useAuth();

    // Clear messages when modal opens/closes
    useEffect(() => {
        console.log('ShareProjectModal show effect triggered:', show);
        setShareError(null);
        setShareSuccess(false);
        setInviteError(null);
        setInviteSuccess(false);
    }, [show]);

    const handleShare = async () => {
        if (!username.trim()) return;

        setIsLoadingShare(true);
        setShareError(null);
        setShareSuccess(false);

        try {
            await ProjectApiService.shareProject(
                user.username,
                project.name,
                username,
                permission
            );

            // Reset form and show success message
            setUsername('');
            setShareSuccess(true);

            // Notify parent component about the update
            if (onShareUpdate) {
                await onShareUpdate();
            }

        } catch (error) {
            console.error('Failed to share project:', error);
            setShareError('Failed to share project. Please try again.');
        } finally {
            setIsLoadingShare(false);
        }
    };

    const handleInvite = async () => {
        if (!username.trim()) return;

        try {
            // First, share the project using the existing handler
            await handleShare();

            // If sharing succeeded, proceed with sending the invitation
            setIsLoadingInvite(true);
            setInviteError(null);
            setInviteSuccess(false);

            await InviteApiService.inviteUser(username, project.name);
            setInviteSuccess(true);

        } catch (error) {
            console.error('Failed to send invitation:', error);
            setInviteError(error.message || 'Failed to send invitation. Please try again.');
        } finally {
            setIsLoadingInvite(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} mountOnEnter unmountOnExit>
            <Modal.Header closeButton>
                <Modal.Title>Share Project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Status messages - now handling both share and invite statuses */}
                    {shareError && (
                        <div className="alert alert-danger mb-3">{shareError}</div>
                    )}
                    {shareSuccess && (
                        <div className="alert alert-success mb-3">Project shared successfully!</div>
                    )}
                    {inviteError && (
                        <div className="alert alert-danger mb-3">{inviteError}</div>
                    )}
                    {inviteSuccess && (
                        <div className="alert alert-success mb-3">Invitation sent successfully!</div>
                    )}

                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value);
                                // Clear all status messages when input changes
                                setShareError(null);
                                setShareSuccess(false);
                                setInviteError(null);
                                setInviteSuccess(false);
                            }}
                            placeholder="Enter username"
                            disabled={isLoadingShare || isLoadingInvite}
                        />
                    </Form.Group>

                    {/* Share/Invite buttons group */}
                    <div className="d-flex gap-2 mb-3">
                        <Button
                            variant="outline-primary"
                            onClick={handleInvite}
                            disabled={!username.trim() || isLoadingShare || isLoadingInvite}
                        >
                            {(isLoadingShare || isLoadingInvite) ? 'Processing...' : (
                                <>
                                    <UserPlus size={16} className="me-1" />
                                    Share and Invite
                                </>
                            )}
                        </Button>
                        <Button
                            variant="outline-secondary"
                            onClick={handleShare}
                            disabled={!username.trim() || isLoadingShare || isLoadingInvite}
                        >
                            Share Only
                        </Button>
                    </div>

                    {/* Permission selector - only shown when sharing directly */}
                    {!inviteSuccess && (
                        <Form.Group>
                            <Form.Label>Permission Level</Form.Label>
                            <Form.Select
                                value={permission}
                                onChange={(e) => setPermission(e.target.value)}
                                disabled={isLoadingShare || isLoadingInvite}
                            >
                                <option value="read">Can view</option>
                                <option value="write">Can edit</option>
                            </Form.Select>
                        </Form.Group>
                    )}
                </Form>

                {/* Existing sharing list component */}
                <div className="mt-3">
                    <h6>Currently shared with:</h6>
                    <ProjectSharingList
                        project={project}
                        sharingInfo={sharingInfo}
                        onUpdateSharing={onShareUpdate}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};