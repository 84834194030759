// AIExtension.jsx
import { Extension } from '@tiptap/core';
import aiApi from '../../../services/aiApiService';

const AIExtension = Extension.create({
    name: 'aiAssistant',

    addOptions() {
        return {
            modelId: 1,
            temperature: 0.1,
            onLoadingChange: () => { },
        };
    },

    addCommands() {
        const handleAIOperation = async (text, prompt, editor, isTaskOperation = false) => {

            try {
                this.options.onLoadingChange(true);

                const messages = [
                    aiApi.createChatMessage("system", "You are a helpful writing assistant. Provide only the modified text without any explanations or additional context."),
                    aiApi.createChatMessage("user", prompt + text)
                ];

                const chatRequest = aiApi.createChatRequest(
                    messages,
                    this.options.modelId,
                    this.options.temperature
                );

                const response = await aiApi.generateChatResponse(chatRequest);

                if (!response?.message?.content) {
                    console.log('Invalid response format');
                    return false;
                }

                if (isTaskOperation) {
                    // Split response into tasks
                    const tasks = response.message.content
                        .split('\n')
                        .map(line => line.trim())
                        .filter(line => line)
                        .map(line => line
                            .replace(/^[-*•]\s*/, '')              // Remove bullet points
                            .replace(/^\[[\sx]?\]\s*/, '')         // Remove checkbox notations
                            .replace(/^\(\s*\)\s*/, '')            // Remove parentheses checkboxes
                            .replace(/^☐\s*/, '')                  // Remove box characters
                            .trim()
                        );

                    if (!tasks.length) {
                        console.log('No tasks found in response');
                        return false;
                    }


                    // Get the current selection end position
                    const insertPos = editor.state.selection.to;
                    const prevChar = insertPos > 0 ? editor.state.doc.textBetween(insertPos - 1, insertPos) : '\n';
                    const needsLeadingNewline = prevChar !== '\n';

                    // Create task list node
                    const taskListNode = {
                        type: 'taskList',
                        content: tasks.map(task => ({
                            type: 'taskItem',
                            attrs: { checked: false },
                            content: [{
                                type: 'paragraph',
                                content: [{ type: 'text', text: task }]
                            }]
                        }))
                    };

                    // Insert content
                    const success = editor
                        .chain()
                        .focus()
                        // .insertContent(needsLeadingNewline ? '\n' : '')
                        .insertContent(taskListNode)
                        // .insertContent('\n')
                        .run();

                    return success;
                } else {
                    // Regular text replacement
                    const { from, to } = editor.state.selection;
                    return editor
                        .chain()
                        .focus()
                        .deleteRange({ from, to })
                        .insertContent(response.message.content)
                        .run();
                }
            } catch (error) {
                console.error('AI operation failed:', error);
                return false;
            } finally {
                this.options.onLoadingChange(false);
            }
        };

        return {
            createTasks: (customPrompt) => ({ editor }) => {
                const { from, to } = editor.state.selection;
                const text = editor.state.doc.textBetween(from, to, ' ');

                if (!editor.schema.nodes.taskList || !editor.schema.nodes.taskItem) {
                    console.error('Task nodes not available in schema');
                    return false;
                }

                return handleAIOperation(text, customPrompt, editor, true);
            },

            summarizeSelection: (customPrompt) => ({ editor }) => {
                const { from, to } = editor.state.selection;
                const text = editor.state.doc.textBetween(from, to, ' ');
                if (!text) return false;

                const prompt = customPrompt || 'Summarize this text: ';
                return handleAIOperation(text, prompt, editor, false);
            },

            simplifySelection: () => ({ editor }) => {
                const { from, to } = editor.state.selection;
                const text = editor.state.doc.textBetween(from, to, ' ');
                if (!text) return false;
                return handleAIOperation(text, 'Simplify this text while keeping the key points: ', editor);
            },

            extendText: () => ({ editor }) => {
                const { from, to } = editor.state.selection;
                const text = editor.state.doc.textBetween(from, to, ' ');
                if (!text) return false;
                return handleAIOperation(text, 'Expand this text with more details and examples: ', editor);
            },

            reduceText: () => ({ editor }) => {
                const { from, to } = editor.state.selection;
                const text = editor.state.doc.textBetween(from, to, ' ');
                if (!text) return false;
                return handleAIOperation(text, 'Make this text more concise while keeping the main points: ', editor);
            },
            extractKeyPoints: () => ({ editor }) => {
                const { from, to } = editor.state.selection;
                const text = editor.state.doc.textBetween(from, to, ' ');
                if (!text) return false;
                return handleAIOperation(text, 'Extract and list the key points from this text in a clear, organized format: ', editor);
            },
            restructureText: () => ({ editor }) => {
                const { from, to } = editor.state.selection;
                const text = editor.state.doc.textBetween(from, to, ' ');
                if (!text) return false;
                return handleAIOperation(text, 'Restructure and reorganize this text to improve its flow and clarity while maintaining all key information: ', editor);
            },
            excitedTone: () => ({ editor }) => {
                const { from, to } = editor.state.selection;
                const text = editor.state.doc.textBetween(from, to, ' ');
                if (!text) return false;
                return handleAIOperation(text, 'Rewrite this text with an enthusiastic and excited tone, adding energy and positivity while maintaining the core message: ', editor);
            }
        };
    }
});

export default AIExtension;
