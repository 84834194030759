import React, { useRef, useState, useMemo, useEffect, memo, useCallback } from 'react';
import { Row, Col, Alert, Spinner, Form, Button } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Upload, Send, X, Copy, Info } from 'lucide-react';
import ChatNavbar from './ChatNavbar';
import LeftSidebar from './LeftSidebar';
import SettingsModal from './SettingsModal';
import MessageDisplay from './MessageDisplay';
import aiModelApiService from '../../services/aiModelApiService';
import aiApi from '../../services/aiApiService';
import useChatLogic from './useChatLogic';
import EnhancedChatInput from './EnhancedChatInput';
import ModelRecommendationModal from './ModelRecommendationModal';
import CodeCanvas from './CodeCanvas';
import WelcomeScreen from './WelcomeScreen';


// Memoized component for displaying messages
const MessageHistory = memo(({
    conversation,
    onEdit,
    onCopy,
    onShowCanvas,  // New prop
    isCanvasVisible // New prop
}) => {
    const userMessages = conversation?.messages.filter(msg => msg.role === 'user') || [];
    const totalUserPrompts = userMessages.length;

    if (!conversation || !conversation.messages.length) {
        return null;
    }

    return (
        <div key={`chat-${conversation.id}`}>
            {conversation.messages.map(msg => {
                return (
                    <MessageDisplay
                        key={`${conversation.id}-${msg.id}`}
                        message={msg}
                        chatId={conversation.id}
                        onEdit={onEdit}
                        onCopy={onCopy}
                        onShowCanvas={onShowCanvas}    // Pass it here
                        isCanvasVisible={isCanvasVisible}
                    />
                );
            })}
        </div>
    );
});

MessageHistory.displayName = 'MessageHistory';


const ChatInterface = () => {
    const messagesEndRef = useRef(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [showSettings, setShowSettings] = useState(false);
    const [tags] = useState(['Work', 'Personal', 'Research', 'Creative']);
    const [providers, setProviders] = useState([]);
    const [availableModels, setAvailableModels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadError, setLoadError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [submitError, setSubmitError] = useState(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const [isCanvasVisible, setIsCanvasVisible] = useState(false);
    const [currentPreContent, setCurrentPreContent] = useState(null);
    const [copyFeedback, setCopyFeedback] = useState(false);
    const [showRecommendationModal, setShowRecommendationModal] = useState(false);
    const [userDescription, setUserDescription] = useState('');
    const [isGettingRecommendation, setIsGettingRecommendation] = useState(false);
    const [recommendations, setRecommendations] = useState(null);
    const [isShowingError, setIsShowingError] = useState(false);

    const ErrorAlert = ({ error, onClose }) => {
        const errorInfo = typeof error === 'string' ? getErrorMessage() : error;

        return (
            <Alert
                variant="danger"
                onClose={onClose}
                dismissible
                className="m-2 shadow-sm"
            >
                <Alert.Heading>{errorInfo.message}</Alert.Heading>
                <p className="mb-0">{errorInfo.action}</p>
            </Alert>
        );
    };

    // Initialize chat logic
    const chat = useChatLogic({
        initialModel: '',
        initialTemperature: 0.1
    });

    // Memoized filtered conversations
    const filteredConversations = useMemo(() => {
        if (!chat.searchConversations) return chat.conversations;
        return chat.searchConversations(searchQuery);
    }, [chat.searchConversations, searchQuery, chat.conversations]);

    // Current conversation memo
    const currentConversation = useMemo(() => {
        return chat.conversations.find(conv => conv.id === chat.currentChat);
    }, [chat.conversations, chat.currentChat]);

    const getErrorMessage = (error) => {
        return {
            message: "The AI model is temporarily unavailable or at capacity.",
            action: "You can either wait a few minutes and try again, or select a different model from the dropdown menu above."
        };
    };

    useEffect(() => {
        const fetchProvidersAndModels = async () => {
            try {
                setIsLoading(true);
                const providersData = await aiModelApiService.listProviders({
                    activeOnly: true,
                    includeModels: true,
                });


                const updatedProvidersData = providersData.map(provider => ({
                    ...provider,
                    name: provider.name === "OpenRouter" ? "OR" : provider.name,
                }));

                setProviders(updatedProvidersData);
                const models = updatedProvidersData.flatMap(provider =>
                    provider.models.map(model => ({
                        id: model.id,
                        description: model.description,
                        name: `${provider.name} - ${model.name}`,
                        providerId: provider.id,
                    }))
                );


                setAvailableModels(models);
                setLoadError(null);
            } catch (error) {
                console.error('Error fetching providers and models:', error);
                setLoadError('Failed to load available AI models. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProvidersAndModels();
    }, []);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if (currentConversation?.messages?.length > 0) {
            scrollToBottom();
        }
    }, [currentConversation?.messages]);

    const handleSendMessage = async (message, attachmentData) => {
        if (!chat.selectedModel) {
            setSubmitError({
                message: "No AI model selected",
                action: "Please select a model from the dropdown menu at the top to start chatting."
            });
            setIsShowingError(true);
            return;
        }
        setSubmitError(null);
        setIsShowingError(false);

        if (!message.trim() && !attachmentData) return;

        try {
            if (chat.selectedModel && !chat.conversations.length) {
                await chat.createNewChat();
                setInitialLoad(false);
            }

            await chat.sendMessage(message, attachmentData);
            scrollToBottom();
        } catch (error) {
            setSubmitError(getErrorMessage());
            setIsShowingError(true);
            // Clean up empty chat if exists
            if (currentConversation && currentConversation.messages.length === 0) {
                handleDeleteConversation(currentConversation.id);
            }
        }
    };

    const handleGetRecommendation = async () => {
        try {
            setIsGettingRecommendation(true);
            setSubmitError(null); // Clear any existing errors

            const systemMessage = aiApi.createChatMessage(
                "system",
                "You are a model recommendation assistant. When recommending models, you must use the EXACT model names including the 'OR - ' prefix and ':free' suffix."
            );

            const modelsList = availableModels
                .map(model => `Model: ${model.name}\nDescription: ${model.description || 'No description available'}`)
                .join('\n\n');

            const userPrompt = `Based on the following user need: "${userDescription}"

Analyze these available models and recommend the best options:

${modelsList}

Return your response in this exact JSON format without any markdown formatting or code blocks:
{
  "recommendations": [
    {
      "model": "EXACT_MODEL_NAME",
      "reasoning": "explanation here"
    }
  ]
}

IMPORTANT: 
- Use EXACT model names from the list, including 'OR - ' prefix and ':free' suffix
- Return raw JSON only, no markdown formatting or code blocks
- Ensure valid JSON syntax
- Provide 3-5 recommendations ordered by best fit`;

            const allMessages = [
                systemMessage,
                {
                    role: 'user',
                    content: userPrompt
                }
            ];

            const chatRequest = aiApi.createChatRequest(
                allMessages,
                88,
                {
                    temperature: 0.7,
                    streaming: false
                }
            );

            const response = await aiApi.generateChatResponse(chatRequest, (chunk) => {
                aiMessage.content = chunk;
                // Update the message in the conversation
                if (conversation?.messages?.length > 0) {
                    const lastMessage = conversation.messages[conversation.messages.length - 1];
                    if (lastMessage && lastMessage.role === 'assistant') {
                        lastMessage.content = chunk;
                    }
                }
            });

            if (!response?.message?.content) {
                throw new Error('Invalid response format from the server');
            }

            // Clean and parse the JSON response
            const cleanContent = response.message.content.replace(/```json\n?|\n?```/g, '').trim();

            const parsedRecommendations = JSON.parse(cleanContent);

            if (!parsedRecommendations?.recommendations?.length) {
                throw new Error('No recommendations found in response');
            }

            setRecommendations(parsedRecommendations);

        } catch (error) {
            console.error('Error in handleGetRecommendation:', error);
            setSubmitError(getErrorMessage());
            setRecommendations(null);
        } finally {
            setIsGettingRecommendation(false);
        }
    };

    const handleModelSelect = (recommendation) => {
        try {
            // Clean up empty chat if exists
            if (currentConversation && currentConversation.messages.length === 0) {
                handleDeleteConversation(currentConversation.id);
            }

            // Find the matching model
            const model = availableModels.find(m => {
                const recommendedModelWithPrefix = recommendation.model.startsWith('OR - ') ?
                    recommendation.model :
                    'OR - ' + recommendation.model;
                return m.name.toLowerCase() === recommendedModelWithPrefix.toLowerCase();
            });

            if (!model) {
                throw new Error('Selected model not found in available models');
            }

            chat.setSelectedModel(model.id.toString());
            setShowRecommendationModal(false);
        } catch (error) {
            console.error('Error selecting model:', error);
            setSubmitError(getErrorMessage());
        }
    };


    const handleCopy = async (content) => {
        try {
            await navigator.clipboard.writeText(content);
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };

    const handleDeleteConversation = async (conversationId) => {
        try {
            await chat.deleteConversation(conversationId);
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
    };

    const handleRenameConversation = (conversationId, newTitle) => {
        chat.updateConversationTitle(conversationId, newTitle);
    };

    const handleNewChat = async () => {
        setInitialLoad(true);
        setSubmitError(null);
        setIsShowingError(false);
        chat.setError(null);
        setIsCanvasVisible(false);
        setCurrentPreContent(null);

        // Clear current chat and selected model
        chat.setCurrentChat(null);
        chat.setSelectedModel(null);
    };

    const handleShowCanvas = useCallback((content) => {
        if (typeof content === 'object') {
            setCurrentPreContent({
                code: content.code,
                context: content.context
            });
        } else {
            setCurrentPreContent({
                code: content,
                context: null
            });
        }
        setIsCanvasVisible(true);
        setIsSidebarOpen(false);
    }, []);

    const handleCloseCanvas = useCallback(() => {
        setIsCanvasVisible(false);
        // Optionally reopen sidebar when closing canvas
        setIsSidebarOpen(true);
    }, []);

    const handleSidebarToggle = useCallback(() => {
        if (isCanvasVisible) {
            setIsCanvasVisible(false);
        }
        setIsSidebarOpen(!isSidebarOpen);
    }, [isCanvasVisible, isSidebarOpen]);

    const cleanCodeForCopy = useCallback((content) => {
        if (typeof content !== 'string') return content;
        return content
            .replace(/^```[\w]*\n/, '')
            .replace(/```$/, '')
            .trim();
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <p className="mt-2 text-muted">Loading chat interface...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="vh-100 d-flex flex-column overflow-hidden">
            <ChatNavbar
                isLoading={isLoading}
                loadError={loadError}
                selectedModel={chat.selectedModel}
                availableModels={availableModels}
                useInternet={chat.useInternet}
                onModelChange={(newModel) => {
                    // Clean up empty chat if exists
                    // if (currentConversation && currentConversation.messages.length === 0) {
                    //     handleDeleteConversation(currentConversation.id);
                    // }
                    chat.setSelectedModel(newModel);
                }}
                onInternetToggle={() => chat.setUseInternet(!chat.useInternet)}
                onSettingsClick={() => setShowSettings(true)}
                onSidebarToggle={handleSidebarToggle}
                isSidebarOpen={isSidebarOpen}
                onNewChat={handleNewChat}
            />

            <Row className="flex-grow-1 g-0 overflow-hidden">
                {isSidebarOpen && !isCanvasVisible && (
                    <Col md={3} className="border-end h-100 overflow-hidden">
                        <LeftSidebar
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            tags={tags}
                            filteredConversations={filteredConversations}
                            chat={chat}
                            handleDeleteConversation={handleDeleteConversation}
                            handleRenameConversation={handleRenameConversation}
                        />
                    </Col>
                )}

                <Col
                    md={isCanvasVisible ? 6 : (isSidebarOpen ? 9 : 12)}
                    className="d-flex flex-column h-100 overflow-hidden"
                >
                    {(chat.error || submitError) && (
                        <ErrorAlert
                            error={chat.error || submitError}
                            onClose={() => {
                                chat.setError(null);
                                setSubmitError(null);
                            }}
                        />
                    )}

                    <div className="flex-grow-1 overflow-y-auto px-2 px-sm-3 py-2 d-flex flex-column">
                        {!chat.currentChat || !currentConversation && !isShowingError ? (
                            <WelcomeScreen
                                initialLoad={initialLoad}
                                onShowRecommendation={() => setShowRecommendationModal(true)}
                            />
                        ) : (
                            <MessageHistory
                                conversation={currentConversation}
                                onEdit={chat.updateMessage}
                                onCopy={handleCopy}
                                onShowCanvas={handleShowCanvas}
                                isCanvasVisible={isCanvasVisible}
                            />
                        )}
                        {chat.loading && (
                            <div className="text-center py-3">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>

                    <EnhancedChatInput
                        onSendMessage={handleSendMessage}
                        loading={chat.loading}
                        selectedModel={chat.selectedModel}
                    />
                </Col>

                <CodeCanvas
                    isVisible={isCanvasVisible}
                    content={currentPreContent}
                    onClose={() => setIsCanvasVisible(false)}
                    onSidebarOpen={() => setIsSidebarOpen(true)}
                />
            </Row>

            <SettingsModal
                show={showSettings}
                onHide={() => setShowSettings(false)}
                temperature={chat.temperature}
                systemPrompt={chat.systemPrompt}
                useInternet={chat.useInternet}
                onTemperatureChange={chat.setTemperature}
                onSystemPromptChange={chat.setSystemPrompt}
                onInternetToggle={() => chat.setUseInternet(!chat.useInternet)}
            />
            <ModelRecommendationModal
                show={showRecommendationModal}
                onHide={() => {
                    setShowRecommendationModal(false);
                    setRecommendations(null);  // Clear recommendations when closing
                    setSubmitError(null);      // Clear any errors
                }}
                userDescription={userDescription}
                setUserDescription={setUserDescription}
                handleGetRecommendation={handleGetRecommendation}
                isGettingRecommendation={isGettingRecommendation}
                error={submitError}
                selectedModel={chat.selectedModel}
                recommendations={recommendations}
                onModelSelect={handleModelSelect}
            />
        </div>
    );
};

export default ChatInterface;