import React, { useState, useCallback, useEffect } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { Edit2, Copy, History } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import PromptNavigation from './PromptNavigation';
import VersionHistoryModal from './VersionHistoryModal';
import { useAuth } from '../../contexts/AuthContext';

const MessageDisplay = ({
    message,
    chatId,
    onEdit,
    onCopy,
    onShowCanvas, // New prop for Canvas control
    isCanvasVisible // New prop to track Canvas state
}) => {
    const [editContent, setEditContent] = useState(message.content);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showVersionHistory, setShowVersionHistory] = useState(false);
    const [currentPreContent, setCurrentPreContent] = useState(null);
    const [codeBlocks, setCodeBlocks] = useState([]);
    const [showCopyFeedback, setShowCopyFeedback] = useState(false);
    const { user } = useAuth();

    // Check if message contains pre-formatted content
    const hasPreContent = message.content.includes('<pre>');

    // Check if message contains code blocks (either pre tags or markdown)
    const hasCodeBlock = useCallback(() => {

        // Test the regex directly
        const codeBlockRegex = /```[\s\S]*?```/g;
        const matches = message.content.match(codeBlockRegex);

        const hasBlock = message.content.includes('```');
        return hasBlock;
    }, [message.content]);

    // Extract code content
    const getCodeContent = useCallback(() => {
        // Check for markdown code blocks
        const codeBlockRegex = /```[\s\S]*?```/g;
        const codeMatches = message.content.match(codeBlockRegex);

        if (codeMatches) {
            return codeMatches[0];
        }

        return null;
    }, [message.content]);

    useEffect(() => {
        // Force isStreaming to false for messages from history
        if (!message.timestamp) {
            return;
        }

        const codeBlockRegex = /```[\s\S]*?```/g;
        const matches = message.content.match(codeBlockRegex) || [];

        const blocks = matches.map((match, index) => {
            // Extract language if present
            const languageMatch = match.match(/```(\w+)?/);
            const language = languageMatch?.[1] || '';

            return {
                id: `code-${message.id}-${index}`,
                content: match,
                language: language,
                index: message.content.indexOf(match)
            };
        });

        setCodeBlocks(blocks);
    }, [message.content, message.id])


    const handleShowCanvas = useCallback((codeBlock) => {
        onShowCanvas(codeBlock);
    }, [onShowCanvas]);

    const handleCloseCanvas = useCallback(() => {
        setIsCanvasVisible(false);
        setIsSidebarOpen(true);
    }, []);

    const handleRestoreVersion = useCallback((content) => {
        onEdit(chatId, message.id, content);
    }, [chatId, message.id, onEdit]);

    // Original handlers...
    const handleStartEdit = useCallback(() => {
        setEditContent(message.content);
        setIsEditing(true);
    }, [message.content]);

    const handleCancelEdit = useCallback(() => {
        setEditContent(message.content);
        setIsEditing(false);
    }, [message.content]);

    const handleSaveEdit = useCallback(async (e) => {
        e?.preventDefault();
        if (isSaving) return;

        try {
            setIsSaving(true);
            await onEdit(chatId, message.id, editContent);
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving edit:', error);
        } finally {
            setIsSaving(false);
        }
    }, [chatId, message.id, editContent, onEdit, isSaving]);


    return (
        <>
            <Card
                className={`mb-3 ${message.role === 'user' ? 'ms-auto' : 'me-auto'}`}
                style={{ maxWidth: '85%' }}
            >
                <Card.Body
                    className={message.role === 'user' ? 'bg-primary text-white' : 'bg-light'}
                    style={{ borderRadius: '0.375rem' }}
                >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="d-flex align-items-center gap-2">
                            <small className="opacity-75">
                                {message.role === 'user' ? user.username : 'AI'}
                                {message.edited && ' (edited)'}
                            </small>
                            {message.role === 'user' && (
                                <PromptNavigation message={message} />
                            )}
                        </div>
                        <div className="d-flex align-items-center">
                            {message.role === 'user' && (
                                <>
                                    {!isEditing && (
                                        <>
                                            <Button
                                                variant="link"
                                                size="sm"
                                                className="text-white p-0 me-2"
                                                onClick={handleStartEdit}
                                                title={`Version ${message.editHistory ? message.editHistory.length + 1 : 1}`}
                                            >
                                                <Edit2 size={14} />
                                            </Button>
                                            {message.editHistory && message.editHistory.length > 0 && (
                                                <Button
                                                    variant="link"
                                                    size="sm"
                                                    className="text-white p-0 me-2"
                                                    onClick={() => setShowVersionHistory(true)}
                                                >
                                                    <History size={14} />
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            <Button
                                variant="link"
                                size="sm"
                                className={`${message.role === 'user' ? 'text-white' : 'text-dark'} p-0 d-flex align-items-center`}
                                onClick={async () => {
                                    await onCopy(message.content);
                                    setShowCopyFeedback(true);
                                    setTimeout(() => {
                                        setShowCopyFeedback(false);
                                    }, 2000);
                                }}
                            >
                                <Copy size={14} />
                                {showCopyFeedback && (
                                    <small className="ms-1">Copied!</small>
                                )}
                            </Button>
                        </div>

                    </div>

                    {isEditing ? (
                        <Form onSubmit={handleSaveEdit}>
                            <Form.Control
                                as="textarea"
                                value={editContent}
                                onChange={(e) => setEditContent(e.target.value)}
                                rows={3}
                                className="mb-2"
                                autoFocus
                                disabled={isSaving}
                            />
                            <div className="d-flex justify-content-end gap-2">
                                <Button
                                    variant="outline-light"
                                    size="sm"
                                    onClick={handleCancelEdit}
                                    type="button"
                                    disabled={isSaving}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="light"
                                    size="sm"
                                    type="submit"
                                    disabled={isSaving}
                                >
                                    {isSaving ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </Form>
                    ) : (
                        <ReactMarkdown
                            components={{
                                code({ node, inline, className, children, ...props }) {
                                    const match = /language-(\w+)/.exec(className || '');

                                    // Handle inline code
                                    if (inline) {
                                        return (
                                            <code className={className} {...props}>
                                                {children}
                                            </code>
                                        );
                                    }

                                    // Handle code blocks
                                    if (match) {
                                        const blockContent = String(children).replace(/\n$/, '').trim();
                                        const currentBlock = codeBlocks.find(block => {
                                            const cleanBlockContent = block.content
                                                .replace(new RegExp(`^(\`\`\`${match[1]})?\n`), '')
                                                .replace(/\n\`\`\`$/, '')
                                                .replace(/\`\`\`$/, '')
                                                .trim();
                                            return cleanBlockContent === blockContent;
                                        });

                                        // For completed code blocks
                                        if (currentBlock) {
                                            // Get the text content before this code block
                                            const messageContentBeforeBlock = message.content.slice(0, currentBlock.index).split('\n');

                                            // Find the last non-empty line before the code block and clean it
                                            const lastNonEmptyLine = messageContentBeforeBlock
                                                .reverse()  // Reverse array to search from bottom up
                                                .find(line => {
                                                    const trimmed = line.trim()
                                                        .replace(/\*\*/g, '')  // Remove bold markers
                                                        .replace(/\*/g, '')     // Remove italic markers
                                                        .trim();               // Trim again after removing markers
                                                    return trimmed && !trimmed.startsWith('---') && !trimmed.startsWith('=');
                                                });

                                            // Clean up the found line (if any)
                                            const cleanContext = lastNonEmptyLine ?
                                                lastNonEmptyLine
                                                    .replace(/\*\*/g, '')  // Remove bold markers
                                                    .replace(/\*/g, '')     // Remove italic markers
                                                    .trim() : '';

                                            return (
                                                <div className="my-3">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => handleShowCanvas({
                                                            code: currentBlock.content,
                                                            context: cleanContext
                                                        })}
                                                        className="px-4"
                                                    >
                                                        View Code in Canvas
                                                    </Button>
                                                </div>
                                            );
                                        }

                                        // For streaming code blocks
                                        if (message.isStreaming) {
                                            return (
                                                <div className="my-3">
                                                    <Button
                                                        variant="outline-primary"
                                                        disabled
                                                        className="px-4"
                                                    >
                                                        Code block is being generated...
                                                    </Button>
                                                </div>
                                            );
                                        }
                                    }

                                    return (
                                        <code className={className} {...props}>
                                            {children}
                                        </code>
                                    );
                                }
                            }}
                            className={message.role === 'user' ? 'text-white' : 'text-dark'}
                        >
                            {message.content}
                        </ReactMarkdown>
                    )}

                    {message.attachment && (
                        <img
                            src={message.attachment}
                            alt="Uploaded content"
                            className="img-fluid rounded mt-2"
                            style={{ maxHeight: '300px' }}
                        />
                    )}
                </Card.Body>
                <Card.Footer className="text-muted py-1">
                    <small>{new Date(message.timestamp).toLocaleTimeString()}</small>
                </Card.Footer>
            </Card>

            {message.role === 'user' && (
                <VersionHistoryModal
                    show={showVersionHistory}
                    onHide={() => setShowVersionHistory(false)}
                    message={message}
                    onRestoreVersion={handleRestoreVersion}
                />
            )}
        </>
    );
};

export default MessageDisplay;