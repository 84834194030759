// src/services/aiModelApiService.js
import adminApiConfig from '../config/adminApiConfig';

// Define base path constant
const BASE_PATH = '/ai/api/v1/models';

/**
 * Service for interacting with AI models and providers endpoints.
 * Handles CRUD operations for models and provider information retrieval.
 */
const aiModelApiService = {

    normalizeModelData: (modelData) => ({
        ...modelData,
        id: String(modelData.id),
        provider_id: String(modelData.provider_id)
    }),


    /**
     * Lists all providers and their associated models.
     * 
     * @param {Object} options - Query options
     * @param {boolean} [options.activeOnly=true] - If true, returns only active providers
     * @param {boolean} [options.includeModels=false] - If true, includes models in the response
     * 
     * @returns {Promise<Array<Object>>} Array of provider objects containing:
     *   - id {number} - Provider ID
     *   - name {string} - Provider name
     *   - is_active {boolean} - Provider active status
     *   - total_usage_count {number} - Total usage count
     *   - avg_response_time_ms {number} - Average response time
     *   - error_rate {number} - Error rate
     *   - models {Array<Object>} - Array of model objects (if includeModels=true)
     * 
     * @throws {Error} With formatted message when the API request fails
     */
    listProviders: async ({ activeOnly = true, includeModels = false } = {}) => {
        try {
            const response = await adminApiConfig.get(`${BASE_PATH}/providers`, {
                params: {
                    active_only: activeOnly,
                    include_models: includeModels
                }
            });

            // Return data as-is, maintaining the number types
            return response.data;
        } catch (error) {
            console.error('Error fetching providers:', error);
            throw this.handleApiError(error);
        }
    },

    /**
     * Retrieves all models for a specific provider.
     * 
     * @param {number} providerId - ID of the provider
     * @param {boolean} [activeOnly=true] - If true, returns only active models
     * 
     * @returns {Promise<Array<Object>>} Array of model objects containing:
     *   - id {number} - Model ID
     *   - name {string} - Model name
     *   - provider_name {string} - Name of the provider
     *   - is_active {boolean} - Model active status
     *   - input_price_per_1k_tokens_usd {number} - Input token price
     *   - output_price_per_1k_tokens_usd {number} - Output token price
     * 
     * @throws {Error} With formatted message when the API request fails
     */
    getProviderModels: async (providerId, activeOnly = true) => {
        try {
            const response = await adminApiConfig.get(
                `${BASE_PATH}/providers/${providerId}/models`,
                {
                    params: { active_only: activeOnly }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching provider models:', error);
            throw this.handleApiError(error);
        }
    },

    /**
     * Creates a new AI model.
     * 
     * @param {Object} modelData - The model data
     * @param {string} modelData.name - Model name
     * @param {string} modelData.provider - Provider name
     * @param {number} modelData.input_price_per_1k_tokens_usd - Input token price
     * @param {number} modelData.output_price_per_1k_tokens_usd - Output token price
     * 
     * @returns {Promise<Object>} Created model object
     * 
     * @throws {Error} With formatted message when the API request fails
     */
    createModel: async (modelData) => {
        try {
            const response = await adminApiConfig.post(BASE_PATH, modelData);
            return response.data;
        } catch (error) {
            console.error('Error creating model:', error);
            throw this.handleApiError(error);
        }
    },

    /**
     * Updates an existing AI model.
     * 
     * @param {number} modelId - ID of the model to update
     * @param {Object} updateData - The update data
     * @param {string} [updateData.name] - New model name
     * @param {boolean} [updateData.is_active] - New active status
     * @param {number} [updateData.input_price_per_1k_tokens_usd] - New input token price
     * @param {number} [updateData.output_price_per_1k_tokens_usd] - New output token price
     * 
     * @returns {Promise<Object>} Updated model object
     * 
     * @throws {Error} With formatted message when the API request fails
     */
    updateModel: async (modelId, updateData) => {
        try {
            const response = await adminApiConfig.patch(
                `${BASE_PATH}/${modelId}`,
                updateData
            );
            return response.data;
        } catch (error) {
            console.error('Error updating model:', error);
            throw this.handleApiError(error);
        }
    },

    /**
     * Retrieves a specific model by ID.
     * 
     * @param {number} modelId - ID of the model to retrieve
     * 
     * @returns {Promise<Object>} Model object
     * 
     * @throws {Error} With formatted message when the API request fails
     */
    getModel: async (modelId) => {
        try {
            const response = await adminApiConfig.get(`${BASE_PATH}/${modelId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching model:', error);
            throw this.handleApiError(error);
        }
    },

    /**
     * Lists all models with optional filtering.
     * 
     * @param {Object} options - Filter options
     * @param {boolean} [options.activeOnly=true] - If true, returns only active models
     * @param {string} [options.provider] - Filter by provider name
     * 
     * @returns {Promise<Array<Object>>} Array of model objects
     * 
     * @throws {Error} With formatted message when the API request fails
     */
    listModels: async ({ activeOnly = true, provider = null } = {}) => {
        try {
            const response = await adminApiConfig.get(BASE_PATH, {
                params: {
                    active_only: activeOnly,
                    provider: provider
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching models:', error);
            throw this.handleApiError(error);
        }
    },

    /**
     * Handles API errors and returns formatted error messages.
     * 
     * @private
     * @param {Error} error - The error object from axios
     * @returns {Error} Formatted error with appropriate message
     */
    handleApiError: (error) => {
        if (error.response) {
            const status = error.response.status;
            const errorMessage = error.response.data?.detail;

            switch (status) {
                case 403:
                    return new Error(errorMessage || 'Access denied: Please check your permissions.');
                case 404:
                    return new Error(errorMessage || 'Resource not found');
                case 400:
                    return new Error(errorMessage || 'Invalid request parameters');
                case 500:
                    return new Error(errorMessage || 'Server error occurred');
                default:
                    return new Error(errorMessage || 'An unexpected error occurred');
            }
        }
        return new Error('Failed to communicate with the server. Please try again later.');
    },
    /**
     * Synchronize models from OpenRouter
     * @returns {Promise} The response from the sync operation
     */
    syncOpenRouterModels: async () => {
        try {
            const response = await adminApiConfig.post(`${BASE_PATH}/sync-openrouter-models`);
            return response.data;
        } catch (error) {
            throw error.response?.data || error;
        }
    },
};

export default aiModelApiService;