import React, { useState, useRef } from 'react';
import { Navbar, Container, Button, Modal } from 'react-bootstrap';
import { Settings, ArrowLeft, PanelLeftClose, PanelRightOpen, MessageSquarePlus, Layout } from 'lucide-react';
import { Link } from 'react-router-dom';
import ModelSelector from './ModelSelector';

const ChatNavbar = ({
    isLoading,
    loadError,
    selectedModel,
    availableModels,
    useInternet,
    onModelChange,
    onInternetToggle,
    onSettingsClick,
    onSidebarToggle,
    isSidebarOpen,
    onNewChat
}) => {
    const [showSettings, setShowSettings] = useState(false);
    const modelSelectorRef = useRef();

    const handleSettingsClick = () => {
        setShowSettings(true);
        if (onSettingsClick) onSettingsClick();
    };

    const cleanModelName = (name) => {
        return name
            .replace(/^OR\s*-\s*[\w-]+\//, '')
            .replace(/:free$/, '');
    };

    const getSelectedModelName = () => {
        if (!selectedModel) return 'Select Model';
        const model = availableModels.find(m => m.id === selectedModel);
        return model ? cleanModelName(model.name) : 'Select Model';
    };

    const handleModelButtonClick = () => {
        modelSelectorRef.current?.openModal();
    };

    return (
        <>
            <Navbar bg="light" expand={false} className="border-bottom py-2">
                <Container fluid className="px-2">
                    <div className="d-flex align-items-center gap-2 w-100">
                        <Link to="/journal" className="p-1">
                            <ArrowLeft size={20} />
                        </Link>
                        <Button
                            variant="link"
                            className="p-1"
                            onClick={onSidebarToggle}
                        >
                            {isSidebarOpen ? (
                                <PanelLeftClose size={20} />
                            ) : (
                                <PanelRightOpen size={20} />
                            )}
                        </Button>

                        <Button
                            variant="outline-primary border-0"
                            className="me-2 d-flex align-items-center gap-2"
                            onClick={onNewChat}
                        >
                            <MessageSquarePlus size={20} />
                            {/* New Chat */}
                        </Button>

                        {loadError ? (
                            <span className="text-danger">{loadError}</span>
                        ) : (
                            <div className="d-flex align-items-center">
                                <Button
                                    onClick={handleModelButtonClick}
                                    variant="outline-secondary"
                                    className="d-flex align-items-center gap-2"
                                    style={{ maxWidth: '300px' }}
                                >
                                    <Layout size={16} />
                                    <span className="text-truncate">{getSelectedModelName()}</span>
                                </Button>
                                <ModelSelector
                                    ref={modelSelectorRef}
                                    isLoading={isLoading}
                                    selectedModel={selectedModel}
                                    availableModels={availableModels}
                                    onModelChange={onModelChange}
                                    onNewChat={onNewChat}  // Pass onNewChat to ModelSelector
                                />
                            </div>
                        )}

                        <div className="d-flex align-items-center gap-2 ms-auto">
                            <Button
                                variant="link"
                                className="p-1"
                                onClick={handleSettingsClick}
                            >
                                <Settings size={20} />
                            </Button>
                        </div>
                    </div>
                </Container>
            </Navbar>

            <Modal show={showSettings} onHide={() => setShowSettings(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Settings content */}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ChatNavbar;