// src/services/aiApiService.js
import adminApiConfig from '../config/adminApiConfig';

// Define base path constant
const BASE_PATH = '/ai/api/v1/usage';

/**
 * Service for interacting with AI-related endpoints.
 * Handles text completion and chat-based interactions with AI models.
 */
const aiApi = {
    /**
     * Generates a text completion using the specified model.
     * 
     * @param {Object} completionRequest - The request object for text completion
     * @param {string} completionRequest.model_id - ID of the AI model to use
     * @param {string} completionRequest.prompt - The input prompt for completion
     * 
     * @returns {Promise<Object>} Response object containing:
     *   - completion {string} - The generated text completion
     *   - input_tokens {number} - Number of tokens in the input
     *   - output_tokens {number} - Number of tokens in the output
     * 
     * @throws {Error} With formatted message when the API request fails or quota is exceeded
     */
    generateCompletion: async (completionRequest) => {
        try {
            const response = await adminApiConfig.post(`${BASE_PATH}/complete`, completionRequest);
            return response.data;
        } catch (error) {
            console.error('Full error object:', error);
            console.error('Request URL:', error.config?.url);
            console.error('Request method:', error.config?.method);
            console.error('Status:', error.response?.status);

            if (error.response) {
                const status = error.response.status;
                const errorMessage = error.response.data?.detail;

                if (status === 403) {
                    throw new Error(errorMessage || 'Access denied: Please check your subscription status.');
                }

                if (status === 404) {
                    throw new Error(errorMessage || 'Model not found');
                }

                if (status === 400) {
                    throw new Error(errorMessage || 'Invalid request parameters');
                }

                if (status === 500) {
                    throw new Error(errorMessage || 'Server error occurred');
                }
            }

            throw new Error('Failed to communicate with the server. Please try again later.');
        }
    },

    /**
 * Generates a chat response using the specified model.
 * 
 * @param {Object} chatRequest - The request object for chat completion
 * @param {Array<Object>} chatRequest.messages - Array of chat messages
 * @param {string} chatRequest.model_id - ID of the AI model to use
 * @param {number} [chatRequest.temperature=0.7] - Sampling temperature (0.0 to 2.0)
 * @param {boolean} [chatRequest.streaming=false] - Whether to use streaming mode
 * @param {number} [chatRequest.max_tokens] - Maximum tokens to generate
 * @param {boolean} [chatRequest.force_free_model=false] - Force usage of free model regardless of user status
 * @param {Function} [onChunk] - Callback for handling streaming chunks. Receives objects with type ('content'|'model') and data
 * 
 * @returns {Promise<Object>} Response object containing:
 *   - message {Object} - The generated response message
 *   - input_tokens {number} - Number of tokens in the input
 *   - output_tokens {number} - Number of tokens in the output
 *   - model {Object} - Information about the model used (id, name, provider)
 */
    generateChatResponse: async (chatRequest, onChunk) => {
        try {
            // Ensure default values are set if not provided
            const normalizedRequest = {
                temperature: 0.7,
                streaming: false,
                ...chatRequest,
                force_free_model: chatRequest.force_free_model ?? false
            };

            if (normalizedRequest.streaming && !onChunk) {
                throw new Error('Streaming mode requires an onChunk callback function');
            }

            if (normalizedRequest.streaming) {
                // Handle streaming response
                const response = await fetch(`${BASE_PATH}/chat`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        ...adminApiConfig.headers
                    },
                    body: JSON.stringify(normalizedRequest)
                });

                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.detail || 'Stream request failed');
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let buffer = '';
                let modelInfo = null;
                let totalContent = '';

                try {
                    while (true) {
                        const { value, done } = await reader.read();
                        if (done) break;

                        // Decode and handle buffer
                        buffer += decoder.decode(value, { stream: true });
                        const lines = buffer.split('\n');

                        // Keep the last incomplete line in buffer
                        buffer = lines.pop() || '';

                        for (const line of lines) {
                            if (line.startsWith('data: ')) {
                                const data = line.slice(5).trim();
                                if (data === '[DONE]') continue;

                                try {
                                    const parsed = JSON.parse(data);

                                    // Handle model info (should be first chunk)
                                    if (parsed.model && !modelInfo) {
                                        modelInfo = parsed.model;
                                        if (onChunk) {
                                            onChunk({
                                                type: 'model',
                                                data: modelInfo
                                            });
                                        }
                                    }

                                    // Handle content chunks
                                    if (parsed.content) {
                                        totalContent += parsed.content;
                                        if (onChunk) {
                                            onChunk({
                                                type: 'content',
                                                data: parsed.content
                                            });
                                        }
                                    }

                                    // Handle errors
                                    if (parsed.error) {
                                        throw new Error(parsed.error);
                                    }
                                } catch (e) {
                                    if (data !== '[DONE]') {
                                        console.warn('Failed to parse streaming data:', e);
                                    }
                                }
                            }
                        }
                    }
                } finally {
                    reader.releaseLock();
                }

                // Return final response for streaming mode
                return {
                    message: {
                        role: 'assistant',
                        content: totalContent
                    },
                    input_tokens: 0,  // Accurate counts not available in streaming
                    output_tokens: 0,
                    model: modelInfo
                };
            } else {
                // Handle regular non-streaming response
                const response = await adminApiConfig.post(`${BASE_PATH}/chat`, normalizedRequest);
                return response.data;  // Server will include model info automatically
            }
        } catch (error) {
            console.error('Error generating chat response:', error);

            if (error.response) {
                const status = error.response.status;
                const errorMessage = error.response.data?.detail;

                if (status === 403) {
                    throw new Error(errorMessage || 'Access denied: Please check your subscription status.');
                }

                if (status === 404) {
                    throw new Error(errorMessage || 'Model not found');
                }

                if (status === 400) {
                    throw new Error(errorMessage || 'Invalid request parameters');
                }

                if (status === 500) {
                    throw new Error(errorMessage || 'Server error occurred');
                }
            }

            throw new Error('Failed to communicate with the server. Please try again later.');
        }
    },

    /**
     * Creates a properly formatted chat message object.
     * 
     * @param {string} role - Role of the message sender.
     *                      Must be one of: "system", "user", "assistant"
     * @param {string} content - Content of the message
     * 
     * @returns {Object} Formatted chat message object
     * 
     * @example
     * const systemMessage = aiApi.createChatMessage("system", "You are a helpful assistant");
     * const userMessage = aiApi.createChatMessage("user", "Hello!");
     */
    createChatMessage: (role, content) => {

        return {
            role,
            content
        };
    },

    /**
    * Creates a properly formatted chat request object.
    * 
    * @param {Array<Object>} messages - Array of chat messages created using createChatMessage()
    * @param {number} modelId - ID of the AI model to use
    * @param {Object} options - Optional parameters
    * @param {number} [options.temperature=0.1] - Sampling temperature (0.0 to 2.0)
    * @param {number} [options.maxTokens=null] - Maximum tokens to generate
    * @param {boolean} [options.streaming=false] - Whether to use streaming mode
    * @param {boolean} [options.force_free_model=false] - Force usage of free model
    */
    createChatRequest: (messages, modelId, options = {}) => {
        if (typeof modelId !== 'number' || isNaN(modelId)) {
            throw new Error(`Invalid model ID: ${modelId}`);
        }

        const {
            temperature = 0.1,
            maxTokens = null,
            streaming = false,
            force_free_model = false
        } = options;

        return {
            messages,
            model_id: modelId,
            temperature,
            max_tokens: maxTokens,
            streaming,
            force_free_model
        };
    },

};

export default aiApi;
