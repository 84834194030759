import { Extension } from '@tiptap/core';
import { commentsDB } from '../../../services/commentsDB';
import { Plugin, PluginKey } from 'prosemirror-state';
import aiApi from '../../../services/aiApiService';

export const AICommentExtension = Extension.create({
    name: 'aiComment',

    addAttributes() {
        return {
            mark: this.options.markName,
            attributes: {
                'data-comment-id': {
                    default: null,
                    renderHTML: attributes => {
                        if (!attributes['data-comment-id']) return {};
                        return { 'data-comment-id': attributes['data-comment-id'].toString() };
                    },
                },
            },
        };
    },

    addOptions() {
        return {
            onLoadingChange: () => { }
        };
    },

    addStorage() {
        return {
            comments: [],
            hasOpenedSidebar: false
        };
    },

    onCreate() {
        commentsDB.getAllComments().then(comments => {
            // Filter comments by current editor date
            const editorDate = this.options.selectedDate;

            const validComments = comments?.filter(comment => {
                // Normalize dates to compare just the date part (ignoring time)
                const commentDate = new Date(comment.editorDate).toISOString().split('T')[0];
                const currentDate = editorDate.toISOString().split('T')[0];

                if (commentDate !== currentDate) {
                    return false;
                }

                // Validate positions and text what the F?
                try {
                    const currentText = this.editor.state.doc.textBetween(comment.from, comment.to, ' ');
                    const isValid = currentText === comment.commentedText;
                    return isValid;
                } catch (error) {
                    console.error('Error validating comment:', comment.id, error);
                    return false;
                }
            }) || [];

            this.storage.comments = validComments;

            // Reapply marks for valid comments
            validComments.forEach(comment => {
                try {
                    const mark = this.editor.schema.marks.highlight.create({
                        class: 'ai-comment-highlight',
                        'data-comment-id': comment.id.toString()
                    });

                    const tr = this.editor.state.tr.addMark(
                        comment.from,
                        comment.to,
                        mark
                    );
                    this.editor.view.dispatch(tr);
                } catch (error) {
                    console.error('Error reapplying mark for comment:', comment.id, error);
                }
            });
        }).catch(error => {
            console.error('Error loading comments:', error);
            this.storage.comments = [];
        });
    },

    addProseMirrorPlugins() {
        const extension = this;
        this.hasOpenedSidebar = false;
        return [
            new Plugin({
                key: new PluginKey('aiComments'),
                props: {
                    handleDOMEvents: {
                        click: (view, event) => {
                            let target = event.target;
                            if (target.tagName.toLowerCase() === 'mark') {
                                const pos = view.posAtDOM(target, 0);

                                const comments = this.storage.comments;

                                const comment = comments.find(c => pos >= c.from && pos <= c.to);

                                if (comment) {
                                    const editor = this.editor;

                                    if (editor) {
                                        // Update storage
                                        editor.storage.aiComment.activeCommentId = comment.id;

                                        // Update context through stored setter
                                        if (editor.storage.aiComment.setHighlightedCommentId) {
                                            editor.storage.aiComment.setHighlightedCommentId(comment.id);
                                        } else {
                                        }

                                        // Open sidebar on first comment click
                                        if (!this.hasOpenedSidebar) {
                                            const button = document.querySelector('.toolbar-comments-toggle');
                                            if (button) {
                                                button.click();
                                                this.hasOpenedSidebar = true;
                                            }
                                        }
                                    }
                                    return true;
                                }
                            }
                            return false;
                        }
                    }
                },
                appendTransaction: (transactions, oldState, newState) => {
                    if (!transactions.length) return null;

                    const deletedRanges = [];
                    transactions.forEach(tr => {
                        tr.steps.forEach(step => {
                            if (step.slice && step.to > step.from) {
                                deletedRanges.push({ from: step.from, to: step.to });
                            }
                        });
                    });

                    if (!deletedRanges.length) return null;

                    const commentsToRemove = extension.storage.comments.filter(comment => {
                        return deletedRanges.some(range => {
                            return (comment.from >= range.from && comment.from <= range.to) ||
                                (comment.to >= range.from && comment.to <= range.to) ||
                                (comment.from <= range.from && comment.to >= range.to);
                        });
                    });

                    if (!commentsToRemove.length) return null;

                    const tr = newState.tr;
                    commentsToRemove.forEach(comment => {
                        if (comment.from < newState.doc.content.size) {
                            const end = Math.min(comment.to, newState.doc.content.size);
                            tr.removeMark(
                                comment.from,
                                end,
                                newState.schema.marks.highlight
                            );
                        }

                        extension.storage.comments = extension.storage.comments.filter(
                            c => c.id !== comment.id
                        );

                        commentsDB.deleteComment(comment.id).catch(error => {
                            console.error('Error deleting comment:', error);
                        });
                    });

                    return tr;
                },
                view: () => ({
                    update: (view, prevState) => {
                        if (!extension.storage.comments) return;

                        extension.storage.comments.forEach(comment => {
                            const from = comment.from;
                            const to = comment.to;

                            if (from === undefined || to === undefined) return;
                            if (from >= view.state.doc.content.size || to > view.state.doc.content.size) return;

                            try {
                                const mark = view.state.schema.marks.highlight.create({
                                    class: 'ai-comment-highlight',
                                    'data-comment-id': comment.id.toString()
                                });

                                if (from < to && from >= 0 && to <= view.state.doc.content.size) {
                                    const marks = view.state.doc.rangeHasMark(from, to, view.state.schema.marks.highlight);
                                    if (!marks) {
                                        const tr = view.state.tr.addMark(from, to, mark);
                                        view.dispatch(tr);
                                    }
                                }
                            } catch (error) {
                                console.error('Error updating comment marks:', error);
                            }
                        });
                    }
                })
            })
        ];
    },

    addCommands() {
        return {
            setAIComment: (comment) => ({ editor, tr, dispatch }) => {
                try {
                    const { from, to } = editor.state.selection;
                    // const commentId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
                    const commentId = `${Date.now()}-${Math.random().toString(36).slice(2, 11)}`;
                    const commentedText = editor.state.doc.textBetween(from, to, ' ');

                    // Format the date correctly using the passed selectedDate
                    const editorDate = this.options.selectedDate.toISOString().split('T')[0]; // Get just YYYY-MM-DD

                    const newComment = {
                        id: commentId,
                        content: comment,
                        from,
                        to,
                        commentedText,
                        createdAt: new Date().toISOString(),
                        editorDate,  // Store just the date part
                    };

                    if (dispatch) {
                        tr.addMark(
                            from,
                            to,
                            editor.schema.marks.highlight.create({
                                class: 'ai-comment-highlight',
                                'data-comment-id': commentId.toString()
                            })
                        );
                    }

                    this.storage.comments = this.storage.comments || [];
                    this.storage.comments.push(newComment);
                    commentsDB.addComment(newComment).catch(error => {
                        console.error('Error saving comment:', error);
                    });

                    return true;
                } catch (error) {
                    console.error('Error in setAIComment:', error);
                    return false;
                }
            },
            removeAIComment: (commentId) => ({ editor, tr, dispatch }) => {
                try {
                    if (!this.storage.comments) return false;

                    const comment = this.storage.comments.find(c => c.id === commentId);
                    if (comment) {
                        if (dispatch) {
                            const from = comment.from;
                            const to = comment.to;
                            tr.removeMark(
                                from,
                                to,
                                editor.schema.marks.highlight
                            );
                        }

                        this.storage.comments = this.storage.comments.filter(
                            c => c.id !== commentId
                        );

                        commentsDB.deleteComment(commentId).catch(error => {
                            console.error('Error deleting comment:', error);
                        });

                        editor.view.updateState(editor.view.state);

                        return true;
                    }
                    return false;
                } catch (error) {
                    console.error('Error in removeAIComment:', error);
                    return false;
                }
            },

            scrollToComment: (commentId) => ({ editor }) => {
                if (!this.storage.comments) return false;

                const comment = this.storage.comments.find(c => c.id === commentId);
                if (comment) {
                    let foundMark = null;
                    editor.state.doc.descendants((node, pos) => {
                        if (node.marks) {
                            node.marks.forEach(mark => {
                                if (mark.type === editor.schema.marks.highlight && mark.attrs['data-comment-id'] === commentId.toString()) {
                                    foundMark = { mark, pos };
                                }
                            });
                        }
                    });

                    if (foundMark) {
                        const from = foundMark.pos;
                        const to = foundMark.pos + editor.state.doc.resolve(foundMark.pos).nodeAfter.nodeSize;

                        editor.commands.setTextSelection({ from, to });

                        const coords = editor.view.coordsAtPos(from);
                        const editorMain = editor.view.dom.closest('.editor-main');

                        const toolbar = document.querySelector('.toolbar-container.pinned');
                        const toolbarOffset = toolbar ? toolbar.offsetHeight : 0;

                        editorMain.scrollTo({
                            top: coords.top - toolbarOffset - 100,
                            behavior: 'smooth'
                        });
                        return true;
                    }
                }
                return false;
            },

            generateAIComment: () => async ({ editor }) => {
                try {
                    const { from, to } = editor.state.selection;
                    const selectedText = editor.state.doc.textBetween(from, to, ' ');

                    if (!selectedText) return false;

                    this.options.onLoadingChange(true);

                    const messages = [
                        aiApi.createChatMessage("system",
                            "You are a helpful writing assistant. Provide a brief, constructive suggestion for improving the selected text."
                        ),
                        aiApi.createChatMessage("user",
                            `Suggest improvements for this text: "${selectedText}"`
                        )
                    ];

                    const response = await aiApi.generateChatResponse(
                        aiApi.createChatRequest(
                            messages,
                            1,
                            0.7
                        )
                    );

                    if (!response?.message?.content) {
                        throw new Error('Invalid AI response');
                    }

                    return editor.commands.setAIComment(response.message.content);
                } catch (error) {
                    console.error('Error generating AI comment:', error);
                    return false;
                } finally {
                    this.options.onLoadingChange(false);
                }
            }
        };
    }
});